import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {
  transform(string: any): any {
    if (!string) {
      return null;
    }
    const date = new Date(string.toString().replace(/-/g, '/'));
    return this.adjustTimezone(date, this.timezone());
  }

  timezone() {
    let setting = null;
    try {
      setting = JSON.parse(window.localStorage.getItem('app_cache_auth.me'));
    } catch (e) {}

    if ( setting
      && setting.hasOwnProperty('value')
      && setting.value
      && setting.value.hasOwnProperty('timezone')
      && setting.value.timezone) {
        return setting.value.timezone;
    } else {
      return null;
    }
  }

  adjustTimezone(date, timezone) {
    if (!timezone) { return date; }
    switch (timezone) {
      case 'Eastern': return date;
      case 'Central': return date.setHours(date.getHours() + 1);
      case 'Mountain': return date.setHours(date.getHours() + 2);
      case 'Pacific': return date.setHours(date.getHours() + 3);
      default: return date;
    }
  }
}
