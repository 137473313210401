<div class="main-toolbar" fxLayout="row" fxLayoutAlign="start center">
  <button mat-icon-button (click)="onToggleSidenavClicked()">
    <mat-icon>menu</mat-icon>
  </button>

  <span fxFlex></span>

  <button mat-icon-button [fxHide]="true" [fxHide.gt-sm]="false" (click)="toggleFullscreen()">
    <mat-icon *ngIf="!isFullscreen">fullscreen</mat-icon>
    <mat-icon *ngIf="isFullscreen">fullscreen_exit</mat-icon>
  </button>

  <app-toolbar-ed></app-toolbar-ed>
  <app-toolbar-user></app-toolbar-user>
</div>

<!--<app-loading *ngIf="showLoading"></app-loading>-->
