import {Component, ElementRef, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthApiService} from '../../../core/auth-api.service';
import {User} from "../../../model/common";

@Component({
  selector: 'app-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  styleUrls: ['./toolbar-user.component.scss']
})
export class ToolbarUserComponent implements OnInit, OnDestroy {
  isOpen = false;
  currentUser = null;
  onUserChangeSub = null;

  @HostListener('document:click', ['$event', '$event.target'])
  onClick(event: MouseEvent, targetElement: HTMLElement) {
    if (!targetElement) {
      return;
    }

    const clickedInside = this._elementRef.nativeElement.contains(
      targetElement
    );
    if (!clickedInside) {
      this.isOpen = false;
    }
  }

  constructor(
    private _elementRef: ElementRef,
    private router: Router,
    private auth: AuthApiService
  ) {
    this.currentUser = this.auth.user;
  }

  ngOnInit() {
    this.onUserChangeSub = this.auth.onUserChange.subscribe(user => {
      this.currentUser = user;
    })
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  logout() {
    this.router.navigate(['/signin/'+this.auth.getEDPath()]);
  }

  ngOnDestroy(): void {
    this.onUserChangeSub.unsubscribe();
  }
}
