import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {MessageComponent} from "../../../../../component/message";
import {MatTableDataSource} from "@angular/material";
import {SurveyDefinitionService} from "../../../../../core/survey-definition.service";
import {SurveyDefinition} from "../../../../../model/common";
import {DialogService} from "../../../../../component/dialog";
import {Subscription} from "rxjs";
import {TakeSurveyComponent} from "../../../survey/take-survey/take-survey.component";
import {EdService} from "../../../../../core/ed.service";
import {take} from "rxjs/operators";


@Component({
  selector: 'app-step-select-survey-template',
  templateUrl: './step-select-survey-template.component.html',
  styleUrls: ['./step-select-survey-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepSelectSurveyTemplateComponent implements OnInit, OnDestroy {

  @Input() message: MessageComponent;
  @Input() selectedTemplate: SurveyDefinition;
  @Input() selectedTemplateID;
  @Output() onTemplateSelected = new EventEmitter();

  displayedColumns: string[] = ['select', 'title', 'updated_at', 'preview'];
  dataSource = new MatTableDataSource<SurveyDefinition>();

  no_prefill: boolean = true;

  _selectedRow: SurveyDefinition;
  get selectedRow(): SurveyDefinition {
    return this._selectedRow;
  }

  set selectedRow(row: SurveyDefinition) {
    this._selectedRow = row;
    this.onTemplateChanged();
  }

  selectionSub: Subscription;

  constructor(
    private surveyDefinitionService: SurveyDefinitionService,
    private dialog: DialogService,
    private edService: EdService,
    private changeDetector: ChangeDetectorRef,
    private zone: NgZone,
  ) { }

  ngOnInit() {
    this.edService.EDTemplates().subscribe(r => {
      this.dataSource.data = r['data'];
      this.setSelectedRow();
    }, e => {
      this.message.renderApiError(e);
    });
  }

  setSelectedRow() {
    const match = this.selectedTemplateID && this.dataSource.data.find((definition) => {
      return definition.survey && definition.survey.id === this.selectedTemplateID;
    });

    this.selectedRow = match || this.dataSource.data[0];
  }

  selectRow($event, row) {
    $event.preventDefault();
    $event.stopPropagation();

    if (this.selectedTemplate && this.selectedTemplate.edited) {
      this.dialog.confirm({
        title: 'Survey template change',
        message: 'The currently selected template is modified. Are you sure you want to change the template? This will revert all changes.',
        cancelButton: 'Cancel',
        acceptButton: 'Ok, switch the template'
      }).afterClosed().subscribe(r => {
        if (r !== true) {
          return;
        }
        this.selectedRow = row;
        if (!this.selectedRow.survey_id) {
          this.no_prefill = true;
        }
      });
    } else {
      this.selectedRow = row;
      if (!this.selectedRow.survey_id) {
        this.no_prefill = true;
      }
    }
  }

  onTemplateChanged() {
    let matchIndex = null;
    let match = this.dataSource.data.find((definition, index) => {
      matchIndex = index;
      return definition.id == this.selectedRow.id;
    });

    this.edService.getTemplateDefinition(match.id).subscribe(template => {
      this.dataSource.data[matchIndex] = template;
      const selectedTemplate = {...this.dataSource.data[matchIndex]};
      selectedTemplate.no_prefill = this.no_prefill;

      this.onTemplateSelected.emit(selectedTemplate);
    });
  }

  onPrefillChanged(event) {
    this.no_prefill = event.checked;
    this.onTemplateChanged();
  }

  isPrefillDisabled() {
    if (!this.selectedRow) {
      return true;
    }
    if (this.selectedRow && !this.selectedRow.survey_id) {
      return true;
    }
    return false;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  ngOnDestroy() {
    if (this.selectionSub) {
      this.selectionSub.unsubscribe();
    }
  }

  preview($event, surveyDefinition) {
    $event.stopImmediatePropagation();

    this.edService.getTemplateDefinition(surveyDefinition.id).subscribe(template => {
      const surveyDefinition = <SurveyDefinition>template;
      const dialogRef = this.dialog.open(TakeSurveyComponent, {
        panelClass: 'dialog-lg',
      });

      dialogRef.componentInstance.readOnly = true;
      dialogRef.componentInstance.isModal = true;

      dialogRef.componentInstance.initFromSurveyResponse({
        survey: {
          title: surveyDefinition.title || surveyDefinition.survey.title,
          survey_definition: surveyDefinition
        }
      });

      dialogRef.componentInstance.onClose.pipe(take(1)).subscribe(() => {
        dialogRef.close();
      });
    }, e => this.message.renderApiError(e));
  }


}
