import {finalize, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Router} from '@angular/router';

import {Observable, Subscription, timer} from 'rxjs';
import {AuthApiService} from './auth-api.service';
import {LoadingService} from "./loading.service";
import {DialogService} from "../component/dialog";


@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  activeRequestsCount = 0;

  logoutTimer: Subscription;

  initTimer() {
    if (this.logoutTimer) {
      this.logoutTimer.unsubscribe();
    }
    this.logoutTimer = timer(30 * 60 * 1000).subscribe(() => {
      this.dialog.closeAll();
      this.router.navigate(['/signin/'+this.authService.getEDPath()]);
    });
  }

  constructor(private authService: AuthApiService,
              private router: Router,
              private loadingService: LoadingService,
              private dialog: DialogService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.initTimer();

    this.activeRequestsCount++;

    if (this.activeRequestsCount == 1) {
      this.loadingService.isLoading = true;
    }

    const authReq = req.clone({
      withCredentials: true,
      headers: req.headers
        .set('Accept', 'application/json')
        .set('Access-Control-Allow-Credentials', 'true')
    });

    return next.handle(authReq).pipe(finalize(() => {
      this.activeRequestsCount--;
      if (!this.activeRequestsCount) {
        this.loadingService.isLoading = false;
      }
    })).pipe(tap((event: HttpEvent<any>) => {
    }, (error: any) => {
      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          return this.handleUnauthorizedError(error.error);
        } else if (error.status === 403) {
          this.router.navigate(['/forbidden', {'m': error.error.message}]);
        }
      }
    }));
  }

  handleUnauthorizedError(error) {
    // console.log(error);
    this.router.navigate(['/signin']);
  }
}
