import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MessageComponent} from "../../../../../component/message";
import {SurveyDefinition} from "../../../../../model/common";
import {CollectorComponent} from "../../../../../tripetto/collector/collector.component";
import {DialogService} from "../../../../../component/dialog";
import {SurveyEditorComponent} from "../../../../../components/survey-editor/survey-editor.component";
import {Subscription} from "rxjs";
import {UtilityService} from "../../../../../components/utility.service";
import {TakeSurveyComponent} from "../../../survey/take-survey/take-survey.component";

@Component({
  selector: 'app-step-preview-survey-questions',
  templateUrl: './step-preview-survey-questions.component.html',
  styleUrls: ['./step-preview-survey-questions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepPreviewSurveyQuestionsComponent implements OnInit, OnDestroy {

  @Input() message: MessageComponent;
  @Input() surveyTemplate: SurveyDefinition;

  @Output() onSurveyTemplateEdited = new EventEmitter<SurveyDefinition>();

  @ViewChild('collector') collector: CollectorComponent;
  @ViewChild('appTakeSurvey') appTakeSurvey: TakeSurveyComponent;

  editorSaveSub: Subscription;
  editorCloseSub: Subscription;

  public questionsApproved = false;

  constructor(
    private dialog: DialogService,
    public util: UtilityService
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.editorSaveSub) {
      this.editorSaveSub.unsubscribe();
    }
    if (this.editorCloseSub) {
      this.editorCloseSub.unsubscribe();
    }
  }

  edit() {
    const dialogRef = this.dialog.open(SurveyEditorComponent, {
      panelClass: 'dialog-lg',
      disableClose: true
    });

    dialogRef.componentInstance.surveyDefinition = {...this.surveyTemplate};

    this.editorSaveSub = dialogRef.componentInstance.saveClicked.subscribe((definition: SurveyDefinition) => {
      definition.original = {...this.surveyTemplate};
      definition.edited = true;

      this.surveyTemplate = definition;

      this.onSurveyTemplateEdited.emit(this.surveyTemplate);
      dialogRef.close();
    });

    this.editorCloseSub = dialogRef.componentInstance.closeClicked.subscribe(() => {
      dialogRef.close();
    });
  }

  revert() {
    this.dialog.confirm({
      title: 'Revert',
      message: 'Are you sure you want to revert all changes?',
      cancelButton: 'Cancel',
      acceptButton: 'Yes, revert'
    }).afterClosed().subscribe(r => {
      if (!r) return;

      if (!this.surveyTemplate.edited) return;
      this.surveyTemplate = {...this.surveyTemplate.original};
      this.onSurveyTemplateEdited.emit(this.surveyTemplate);
    });
  }

}
