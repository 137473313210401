import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {IDefinition} from "@tripetto/map/lib/interface";
import {CollectorComponent} from "../../../tripetto/collector/collector.component";
import {EditorComponent} from "../../../tripetto/editor/editor.component";

@Component({
  selector: 'app-survey-definition-editor',
  templateUrl: './survey-definition-editor.component.html',
  styleUrls: ['./survey-definition-editor.component.scss']
})
export class SurveyDefinitionEditorComponent implements OnInit, OnDestroy {

  private currentDefinition: IDefinition | undefined;

  previewMode: string | boolean = false;

  @ViewChild('collector') collector: CollectorComponent;
  @ViewChild('editor') editor: EditorComponent;

  @Output() saveClicked = new EventEmitter();
  @Output() closeClicked = new EventEmitter();

  @Input() set definition(definition: IDefinition | undefined) {
    this.editor.definition = definition;
  }

  constructor() {
  }

  previewModeChange() {
    this.collector.set('preview', this.previewMode === 'preview');
    this.collector.set("mode", "progressive");
    this.editor.resize();
  }


  onEditorChanged(definition) {
    this.currentDefinition = definition;
    this.collector.definition = this.currentDefinition;
  }

  onCollectorChanged() {
    // console.log(Export.fields(this.collector.collector.instance));
    // console.log(Import.fields(this.collector.instance, [{name: 'test', value: '{"r1":{"c1":"40-90","c2":"1-10"},"r2":{"c1":"20-30","c2":"40-90"}}'}]));
  }

  onCloseClicked() {
    this.closeClicked.emit();
  }

  onSaveClicked() {
    this.saveClicked.emit(this.currentDefinition);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  reset() {
  }
}
