<ng-container
  *ngIf="messageType==='alert'; then alertMessage; else appMessage">
</ng-container>
<ng-template #appMessage>
  <div class="app-message-wrapper mat-typography" *ngIf="opened">
    <mat-icon class="app-message-icon" *ngIf="icon">{{icon}}</mat-icon>
    <div class="app-message-labels">
      <div class="app-message-label" *ngIf="label">{{label}}</div>
      <div class="app-message-sublabel" *ngIf="sublabel" [innerHTML] = "sublabel"></div>
    </div>
    <ng-content select="[app-message-actions]"></ng-content>
  </div>
</ng-template>

<ng-template #alertMessage>
  <ng-container *ngIf="opened">
    <mat-icon class="app-message-icon" *ngIf="icon">{{icon}}</mat-icon>
    <div *ngIf="label" class="mat-h1">{{label}}</div>
    <div *ngIf="sublabel" class="mat-h2">{{sublabel}}</div>
    <ng-content></ng-content>
  </ng-container>
</ng-template>
