import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Survey, SurveyResponse, User} from '../../model/common';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormValidator} from '../../core/form-validator';
import {Observable, of, Subscription} from 'rxjs';
import {AuthApiService} from '../../core/auth-api.service';
import {UserService} from '../../core/user.service';
import {EdService} from '../../core/ed.service';
import {BusinessService} from '../../core/business.service';
import {DialogService} from '../../component/dialog';
import {MessageComponent} from '../../component/message';
import {MatTableDataSource} from "@angular/material";
import {catchError, finalize, map} from "rxjs/operators";
import {MatTabChangeEvent} from "@angular/material/typings/tabs";
import {SurveyService} from "../../core/survey.service";
import {ViewSurveyComponent} from "../../pages/ed/survey/view-survey/view-survey.component";

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit, OnDestroy {

  @ViewChild('message') messageBox: MessageComponent;

  userForm: FormGroup;
  passwordForm: FormGroup;
  fv: FormValidator;
  pfv: FormValidator;

  _user: User;
  get user() {
    return this._user;
  }

  @Input() set user(user: User) {
    this._user = user;
    this.init();
  };

  @Output() onSave = new EventEmitter();
  @Output() onClose = new EventEmitter();
  @Input() userRoles = null;


  subsCan = new Subscription();
  isLoading = false;

  validatorSub: Subscription;
  validatorSubPassword: Subscription;

  formErrors = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password_confirmation: '',
    old_password: '',
    role_id: '',
    timezone: '',
    ed: '',
    business: '',
  };

  formErrorsPassword = {
    password: '',
    password_confirmation: '',
    old_password: '',
  };

  validationMessagesPassword = {
    password: {
      required: 'Please enter password',
      pattern: 'Password must contain numbers and letters',
      minlength: 'Please enter more than 8 characters',
      maxlength: 'Please enter less than 32 characters'
    },
    old_password: {
      required: 'Please enter password',
      pattern: 'Password must contain numbers and letters',
      minlength: 'Please enter more than 8 characters',
      maxlength: 'Please enter less than 32 characters'
    },
    password_confirmation: {
      required: 'Please enter password',
      pattern: 'Password must contain numbers and letters',
      minlength: 'Please enter more than 8 characters',
      maxlength: 'Please enter less than 32 characters'
    },
  };

  validationMessages = {
    first_name: {
      required: 'Please enter first name'
    },
    last_name: {
      required: 'Please enter last name'
    },
    email: {
      required: 'Please enter email address',
      email: 'Please enter valid email address\n'
    },
    ed: {
      required: 'Please select',
    },
    business: {
      required: 'Please select',
    },
    password: {
      required: 'Please enter password',
      pattern: 'Password must contain numbers and letters',
      minlength: 'Please enter more than 6 characters',
      maxlength: 'Please enter less than 32 characters'
    }
  };


  EDs = [];
  businesses = [];

  displayedColumns: string[] = ['title', 'period', 'status', 'progress', 'updated_at'];
  userSurveysDataSource = new MatTableDataSource<SurveyResponse>();
  userSurveysDataSourceSub;

  timezones = ['Eastern', 'Central', 'Mountain', 'Pacific'];

  constructor(private fb: FormBuilder,
              private authApi: AuthApiService,
              private userService: UserService,
              private edService: EdService,
              private businessService: BusinessService,
              private dialog: DialogService,
  ) {

  }

  init() {
    if (this.authApi.user.ed) {
      this.EDs = [this.authApi.user.ed];
    }

    if (this.authApi.isSuperAdmin()) {
      this.edService.all().subscribe(r => {
        this.EDs = r['data'];
      }, e => this.messageBox.renderApiError(e));
    }

    this.userRoles = this.userRoles || this.authApi.userRoles;

    this.buildForm();
    // this.messageBox.renderApiError();


    if (this.user && this.user.id) {
      this.userSurveysDataSourceSub = this.userService.surveyResponses(this.user.id)
        .pipe(finalize(() => this.isLoading = false))
        .pipe(map((r) => {
          this.userSurveysDataSource.data = <SurveyResponse[]>r;
        }), catchError((err, caught: Observable<any>) => {
          this.messageBox.renderApiError(err);
          return of([]);
        }));
    }
  }

  ngOnDestroy(): void {
    if (this.subsCan) {
      this.subsCan.unsubscribe();
    }

    if (this.validatorSub) {
      this.validatorSub.unsubscribe();
    }

    if (this.validatorSubPassword) {
      this.validatorSubPassword.unsubscribe();
    }

  }

  ngOnInit() {
    this.init();
  }


  close() {
    this.onClose.emit();
  }

  save() {
    this.messageBox.renderApiError();
    this.userService.editUser(this.userForm.getRawValue()).subscribe(r => {
      this.onSave.emit(r);
      this.messageBox.renderSuccess({});
    }, e => {
      this.messageBox.renderApiError(e)
    });
  }

  savePassword() {
    this.messageBox.renderApiError();
    this.userService.editUser(this.passwordForm.getRawValue()).subscribe(r => {
      this.onSave.emit(r);
      this.messageBox.renderSuccess({});
    }, e => {
      this.messageBox.renderApiError(e)
    });
  }

  buildForm() {
    // if (this.userForm) {
    //   return;
    // }
    this._user = this._user || <User>{};

    this.userForm = this.fb.group({
      id: [this.user.id],
      first_name: [this.user.first_name, [Validators.required]],
      last_name: [this.user.last_name, [Validators.required]],
      email: [{
        value: this.user.email,
        disabled: this.isEditingTheLoggedInUser()
      }, [Validators.required, Validators.email]],
      ed: this.fb.group({
        id: [this.user.ed && this.user.ed.id],
        name: [this.user.ed && this.user.ed.name],
        path: [this.user.ed && this.user.ed.path]
      }),
      password: [this.user.password, []],
      password_confirmation: [this.user.password, []],
      old_password: [this.user.password, []],
      business: this.fb.group({
        id: [this.user.business && this.user.business.id],
        name: [this.user.business && this.user.business.name],
        ed_id: [this.user.business && this.user.business.ed_id]
      }),
      suspended: [this.user.suspended],
      role_id: [this.user.role_id, [Validators.required]],
      timezone: [this.user.timezone, [Validators.required]]
    });

    this.passwordForm = this.fb.group({
      id: [this.user.id],
      pass_only: [true],
      is_logged: [this.isEditingTheLoggedInUser()],
      password: [this.user.password, [Validators.required, Validators.pattern, Validators.minLength(8), Validators.maxLength(32)]],
      password_confirmation: [this.user.password, [Validators.required, Validators.pattern, Validators.minLength(8), Validators.maxLength(32)]],
      old_password: (this.isEditingTheLoggedInUser() ? [this.user.password, [Validators.required, Validators.pattern, Validators.minLength(8), Validators.maxLength(32)]] : []),
    });

    this.fv = new FormValidator(this.userForm, this.validationMessages);
    this.pfv = new FormValidator(this.passwordForm, this.validationMessagesPassword);

    this.userForm.valueChanges.subscribe(data => {
      this.fv.onValueChanged(data);
    });
    this.fv.onValueChanged();

    this.passwordForm.valueChanges.subscribe(data => {
      this.pfv.onValueChanged(data);
    });
    this.pfv.onValueChanged();

    this.validatorSub = this.fv.onError.subscribe((errors) => {console.log(errors); this.formErrors = errors});
    this.validatorSubPassword = this.pfv.onError.subscribe((errors) => {console.log(errors); this.formErrorsPassword = errors});

    this.initEDAndBusinessValues();
    this.updateUI();
  }

  fetchBusinesses() {
    this.businessService.edPath = this.userForm.controls['ed']['controls']['path'].value;
    if (!this.businessService.edPath) {
      return;
    }
    this.businessService.all().subscribe(r => {
      this.businesses = r as any[];
    }, e => this.messageBox.renderApiError(e));
  }

  updateUI() {
    setTimeout(() => {

      let role_id = this.userForm.controls['role_id'].value;

      if (role_id >= 20) {
        this.userForm.controls['ed']['controls']['name'].enable();

        this.userForm.controls['ed']['controls']['id'].setValidators([Validators.required]);
        this.userForm.controls['ed']['controls']['name'].setValidators([Validators.required]);
      } else {
        this.userForm.controls['ed']['controls']['name'].disable();
        this.userForm.controls['ed']['controls']['id'].setValidators([]);
        this.userForm.controls['ed']['controls']['name'].setValidators([]);
        this.userForm.controls['ed'].reset();
      }

      if (role_id >= 40) {
        this.userForm.controls['business']['controls']['name'].enable();
        this.userForm.controls['business']['controls']['name'].setValidators([Validators.required]);
      } else {
        this.userForm.controls['business']['controls']['name'].disable();
        this.userForm.controls['business'].reset();
      }

      if (1
        && this.userForm.controls['business']['controls']['name'].enabled
        && this.userForm.controls['business']['controls']['ed_id'].value != this.userForm.controls['ed']['controls']['id'].value
      ) {
        this.userForm.controls['business'].reset();
      }

      if (this.userForm.controls['business']['controls']['name'].enabled) {
        this.fetchBusinesses();
      }

      this.initEDAndBusinessValues();

    }, 0);
  }

  initEDAndBusinessValues() {
    if (this.userRoles.length === 1) {
      this.userForm.controls['role_id'].setValue(this.userRoles[0]['value']);
    }

    if (this.EDs.length === 1) {
      let ed = this.EDs[0];
      this.userForm.controls['ed'].setValue({
        id: ed['id'],
        name: ed['name'],
        path: ed['path']
      });
    }
  }


  onAutoComplete = ($event, control) => {
    setTimeout(() => {
      const val = $event['option']['value'];

      if (control == 'ed') {
        this.userForm.controls[control].setValue({
          id: val['id'],
          name: val['name'],
          path: val['path']
        });
      } else {
        this.userForm.controls[control].setValue({
          id: val['id'],
          name: val['name'],
          ed_id: val['ed_id']
        });
      }

      this.updateUI();
    }, 0);
  };

  resetPassword() {
    this.dialog.confirm({
      title: 'Password reset',
      message: 'Are you sure you want to reset user\'s password? User will receive an email with instruction how to set a new one.',
      cancelButton: 'Cancel',
      acceptButton: 'Yes, reset'
    }).afterClosed().subscribe((r => {
      if (!r) {
        return;
      }
      this.messageBox.renderApiError();
      this.userService.resetPassword(this.user.id).subscribe((r) => {
        this.messageBox.renderSuccess(r);
      }, e => this.messageBox.renderApiError(e));
    }));
  }

  onTabChange($event: MatTabChangeEvent) {

    if ($event.tab.textLabel == 'Surveys') {
      this.fetchUserSurveyResponses();
    }

  }

  fetchUserSurveyResponses() {
    if (!this.userSurveysDataSourceSub) return;
    this.isLoading = true;
    this.userSurveysDataSourceSub.subscribe();
  }

  getSurveyProgress(surveyResponse: SurveyResponse) {
    return SurveyService.getSurveyProgress(surveyResponse);
  }

  isProgressBarEligible(surveyResponse: SurveyResponse): boolean {
    return SurveyService.isProgressBarEligible(surveyResponse);
  }

  viewSurvey(survey: Survey) {
    const dialogRef = this.dialog.open(ViewSurveyComponent, {
      panelClass: 'dialog-lg',
      disableClose: false
    });
    dialogRef.componentInstance.isModal = true;
    dialogRef.componentInstance.survey = survey;
    this.subsCan.add(dialogRef.componentInstance.onClose.subscribe(() => dialogRef.close()));

    this.subsCan.add(dialogRef.componentInstance.onChange.subscribe(() => {

      this.fetchUserSurveyResponses();

      // this.data.pipe(map((r: any) => {
      //   let data = <Survey[]>r['data'];
      //   dialogRef.componentInstance.survey = data.find(survey => survey.id == dialogRef.componentInstance.survey.id);
      // })).subscribe();
    }));
  }

  isEditingTheLoggedInUser() {
    return this.user && this.user.id && this.user.id == this.authApi.user.id;
  }
}

