<div class="w-100 d-flex justify-content-center mb-3" *ngIf="chartType=='pie'">
  <ngx-charts-pie-chart
    [view]="[650,300]"
    [scheme]="ngxChartColors"
    [results]="ngxData"
    [doughnut]="true"
    [arcWidth]="0.4"
    [legend]="false"
    [labels]="true"
    [trimLabels]="false"
    [labelFormatting]="thousandSeparatorFormatting"
  >
  </ngx-charts-pie-chart>
</div>
<div class="w-100 d-flex justify-content-center mb-3" *ngIf="chartType=='bar'" #container>
  <ngx-charts-bar-horizontal
    [view]="[650,300]"
    [scheme]="ngxChartColors"
    [results]="ngxData"
    [xAxis]="true"
    [yAxis]="true"
    [legend]="false"
    [showXAxisLabel]="false"
    [showYAxisLabel]="false"
    [showDataLabel]="true"
    [dataLabelFormatting]="percentTickFormatting"
    [xAxisTickFormatting]="percentTickFormatting"
    [yAxisTickFormatting]="thousandSeparatorFormatting"
  >
  </ngx-charts-bar-horizontal>
</div>
<div class="w-100 d-flex justify-content-center" *ngIf="showTableResults">

  <table class="mat-table mr-3" *ngIf="question['results']['stats']">
    <thead>
    <tr class="mat-header-row">
      <th colspan=2 class="mat-header-cell">Statistics</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let row of question['results']['stats']">
      <th class="mat-header-cell">{{row['label']}}</th>
      <td class="mat-cell">
        <div class="font-weight-bold">{{row['value']}}</div>
      </td>
    </tr>
    </tbody>
  </table>

  <table class="mat-table" *ngIf="!question['results']['stats']">
    <thead>
    <tr class="mat-header-row">
      <th colspan=1 class="mat-header-cell">Results</th>
      <th colspan=1 class="mat-header-cell" *ngIf="showPercents">%</th>
      <th colspan=1 class="mat-header-cell" *ngIf="showCount">N</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let row of options">
      <th class="mat-header-cell">{{row.name}}</th>
      <td class="mat-cell" *ngIf="showPercents">
        <div class="font-weight-bold" >{{getValue(row.name) | percent }}</div>
      </td>
      <td class="mat-cell" *ngIf="showCount">
        <div class="font-weight-bold">{{getValue(row.name, false)}}</div>
      </td>
    </tr>
    </tbody>
  </table>
</div>
