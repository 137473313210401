import {Component, Input, OnInit} from '@angular/core';
import {Survey} from "../../model/common";

@Component({
  selector: 'app-survey-status-pill',
  templateUrl: './survey-status-pill.component.html',
  styleUrls: ['./survey-status-pill.component.scss']
})
export class SurveyStatusPillComponent implements OnInit {

  @Input() survey: Survey;

  constructor() { }

  ngOnInit() {
  }

}
