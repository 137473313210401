import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UtilityService} from "../../../../../../components/utility.service";
import {SurveyResultsQuestion} from "../../../../../../model/common";

@Component({
  selector: 'app-simple-block-result',
  templateUrl: './simple-block-result.component.html',
  styleUrls: ['./simple-block-result.component.scss']
})
export class SimpleBlockResultComponent implements OnInit {

  @ViewChild('container') container: HTMLDivElement;

  ngxChartColors = UtilityService.ngxChartColors;

  showPercents = true;
  showCount = true;

  ngxData = [];

  @Input('options') options = [];
  @Input() chartType = 'bar';
  @Input() showTableResults = true;

  _question: SurveyResultsQuestion;
  @Input('question') set question(question: SurveyResultsQuestion) {
    this._question = question;

    if (question.block.type == 'block-checkboxes') {
      this.showPercents = false;
    }

    this.makeNGXChartData();
  };

  get question() {
    return this._question;
  }


  constructor() {
  }

  ngOnInit() {
    this.makeNGXChartData();
  }

  getValue(optionName, returnPercents = true) {
    if (!this.question.results.answers) {
      return 0;
    }

    let answeredCount = this.question.results.answered_count || 0;
    let value = this.question.results.answers[optionName] || 0;

    if (!answeredCount || !value) {
      return 0;
    }

    if (returnPercents) {
      return (value / answeredCount);
    } else {
      return value;
    }
  }


  makeNGXChartData() {
    if (this.question.block.type == 'block-checkboxes') {
      this.chartType = null;
      return;
    }

    if (!this.options.length) return;

    let data = [];
    this.options.forEach(option => {
      data.push({
        name: option.name,
        value: Math.round(this.getValue(option.name) * 100)
      });
    });
    this.chartType = this.options.length > 3 ? 'bar' : 'pie';
    this.ngxData = data;
  }

  percentTickFormatting(val: any) {
    return val.toLocaleString() + '%';
  }

  thousandSeparatorFormatting(val: any) {
    if (typeof val === 'number') {
      return val.toLocaleString('en-US');
    }
    if (!isNaN(parseFloat(val))) {
      return parseFloat(val).toLocaleString('en-US');
    }
    return val;
  }

}
