<div class="w-100 d-flex justify-content-center">
  <table class="mat-table">
    <thead>
    <tr class="mat-header-row">
      <th colspan=2 class="mat-header-cell">Results</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let row of values">
      <th class="mat-header-cell">{{row.label}}</th>
      <td class="mat-cell">
        <div class="font-weight-bold">{{row.value}}</div>
      </td>
    </tr>
    </tbody>
  </table>
</div>
