import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {MatDialogRef, MatTableDataSource} from '@angular/material';
import {User} from '../../../../../../model/common';
import {DialogService} from '../../../../../../component/dialog';
import {NewUserDialogComponent} from '../new-user-dialog/new-user-dialog.component';

@Component({
    selector: 'app-invite-users-dialog',
    templateUrl: './invite-users-dialog.component.html',
    styleUrls: ['./invite-users-dialog.component.scss']
})
export class InviteUsersDialogComponent implements OnInit {

    @Output() onComplete = new EventEmitter<User[]>();

    displayedColumns: string[] = ['first_name', 'last_name', 'email', 'business'];
    dataSource = new MatTableDataSource<User>();
    isEmptyUsers = true;

    constructor(public dialogRef: MatDialogRef<InviteUsersDialogComponent>, private dialog: DialogService) {
    }

    ngOnInit() {
        this.dataSource.data = [];
    }

    cancelClick() {
        this.dialogRef.close();
    }

    completeClick() {
        this.onComplete.emit(this.dataSource.data);
    }

    newUserClick() {
        const d = this.dialog.open(NewUserDialogComponent, {
            disableClose: true,
            panelClass: 'dialog-md'
        });

        const component = d.componentInstance;

        component.onComplete.subscribe(user => {
            // console.log(user);

            this.dataSource.data = [...this.dataSource.data, ...[user]];
            this.isEmptyUsers = false;
            d.close();
        });

    }


}
