import {Component, Input, OnInit} from '@angular/core';
import {UtilityService} from '../utility.service';

@Component({
    selector: 'app-created-updated-section',
    templateUrl: './created-updated-section.component.html',
    styleUrls: ['./created-updated-section.component.scss']
})
export class CreatedUpdatedSectionComponent implements OnInit {
    @Input() model;

    constructor() {
    }

    ngOnInit() {
    }

    isEmptyModel() {
        return UtilityService.isEmptyObject(this.model);
    }
}
