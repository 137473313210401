import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable(

)
export class LoadingService {

  private _isLoading = false;

  onRequestStart: BehaviorSubject<any> = new BehaviorSubject({});
  onRequestEnd: BehaviorSubject<any> = new BehaviorSubject({});

  set isLoading(status: boolean) {
    this._isLoading = status;

    if (status) {
      this.onRequestStart.next(this.isLoading);
    } else {
      this.onRequestEnd.next(this.isLoading);
    }
  }

  get isLoading() {
    return this._isLoading;
  }

}
