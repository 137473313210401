import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import config from '../config';
import {DOCUMENT} from "@angular/common";

@Injectable()
export class ConfigService {

  settings: any;
  defaultSettings: any;
  onSettingsChanged: BehaviorSubject<any>;

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {

    this.defaultSettings = {
      colorClasses: {
        header: 'bg-white',
        footer: 'bg-primary',
        brand: 'bg-white',
        navigation: 'bg-white'
      },
      layout: {
        mode: 'fullwidth',
        navigation: 'left',
        header: 'below',
        footer: 'below'
      },
      printMode: false
    };

    this.settings = Object.assign({}, this.defaultSettings);
    this.onSettingsChanged = new BehaviorSubject(this.settings);
  }

  setSettings(setting) {
    this.settings = Object.assign({}, this.settings, setting);
    this.onSettingsChanged.next(this.settings);
  }

  _endpoint: string;
  get apiEndpoint(): string {
    if (this._endpoint) {
      return this._endpoint;
    }

    const host = this.document.location.host;

    if (host.search('local') != -1 || host.search('dev') != -1) {
      this._endpoint = config.apiDomain_dev;
    } else if (host.search('.test.') != -1) {
      this._endpoint = config.apiDomain_test;
    } else {
      this._endpoint = config.apiDomain;
    }

    return this._endpoint;
  }

}
