<div class="app-dialog-wrapper">
  <h3 class="app-dialog-title" *ngIf="dialogTitle.length > 0">
    <ng-content select="app-dialog-title"></ng-content>
  </h3>
  <div class="app-dialog-content" *ngIf="dialogContent.length > 0">
    <ng-content select="app-dialog-content"></ng-content>
  </div>
  <div class="app-dialog-actions" *ngIf="dialogActions.length > 0">
    <span class="app-dialog-spacer"></span>
    <ng-content select="app-dialog-actions"></ng-content>
  </div>
</div>
