import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {MediaChange, ObservableMedia} from '@angular/flex-layout';
import {Observable, of, ReplaySubject, Subscription, timer} from 'rxjs';
import {ConfigService} from '../core/config.service';
import {MessageComponent} from "../component/message";
import {UserService} from "../core/user.service";
import {DialogService} from "../component/dialog";
import {NotificationService} from "../component/notification";
import {AuthApiService} from "../core/auth-api.service";
import {EdService} from "../core/ed.service";
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from "@angular/router";
import {UpdateService} from "../core/update.service";

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, OnDestroy {
  settings: any;
  onSettingsChanged: Subscription;
  layoutMode = false;

  loading = false;

  private _media$: ReplaySubject<MediaChange> = new ReplaySubject(1);
  private _mediaSubscription: Subscription;

  sidenavOpen = true;
  sidenavMode = 'side';
  sidenavAlign = 'start';
  customizerSidenavAlign = 'end';

  title = 'Sensible Surveys';

  @ViewChild('message') messageBox: MessageComponent;

  get media$(): Observable<MediaChange> {
    return this._media$.asObservable();
  }

  constructor(
    media: ObservableMedia,
    private config: ConfigService,
    private userService: UserService,
    private dialog: DialogService,
    private notification: NotificationService,
    private authApi: AuthApiService,
    private edService: EdService,
    private router: Router,
    private sw: UpdateService
  ) {
    // check the service worker for updates
    this.sw.checkForUpdates();

    media
      .asObservable()
      .subscribe(
        res => this._media$.next(res),
        err => this._media$.error(err),
        () => this._media$.complete()
      );

    this.onSettingsChanged = this.config.onSettingsChanged.subscribe(
      settings => {
        this.settings = settings;

        this.layoutMode = this.settings.layout.mode === 'boxed';

        if (this.settings.layout.navigation === 'left') {
          this.sidenavAlign = 'start';
          this.customizerSidenavAlign = 'end';
        } else if (this.settings.layout.navigation === 'right') {
          this.sidenavAlign = 'end';
          this.customizerSidenavAlign = 'start';
        } else {
          this.sidenavAlign = 'start';
          this.customizerSidenavAlign = 'end';
          this.sidenavOpen = false;
        }
      }
    );

    this.userService.messageBox = this.messageBox;
    this.edService.messageBox = this.messageBox;
    this.authApi.messageBox = this.messageBox;
  }

  ngOnInit() {
    this._mediaSubscription = this.media$.subscribe((change: MediaChange) => {
      const isMobile = change.mqAlias === 'xs' || change.mqAlias === 'sm';

      this.sidenavMode = isMobile ? 'over' : 'side';
      this.sidenavOpen = !isMobile;
    });

    setTimeout(() => {
      if (typeof(Event) === 'function') { // all browsers
        window.dispatchEvent(new Event('resize'));
      } else { // IE11
        const event = document.createEvent('HTMLEvents');
        event.initEvent('submit', true, true);
        document.dispatchEvent(event);
      }
    }, 2000);

    this.router.events.subscribe((event: any) => {
      switch (true) {
        case event instanceof NavigationStart: {
          setTimeout(() => {
            this.loading = true;
          }, 0);
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          setTimeout(() => {
            this.loading = false;
          }, 0);
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.onSettingsChanged) {
      this.onSettingsChanged.unsubscribe();
    }
    if (this._mediaSubscription) {
      this._mediaSubscription.unsubscribe();
    }
  }

  /**
   * @param event {Event} 事件
   * @param scrollContainer {Object} 容器dom
   */
  onActivate(event, scrollContainer) {
    scrollContainer.scrollTop = 0;
  }

  onSidenavToggle() {
    this.sidenavOpen = !this.sidenavOpen;
  }
}
