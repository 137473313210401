<div class="dashboard-header">
  <div class="d-flex">
    <div class="flex-grow-1">
      <h1 class="dashboard-header__title">Survey Statistics</h1>
      <p class="dashboard-header__desc">Get insights about surveys.</p>
    </div>
  </div>
</div>
<mat-card class="mt-4">
  <mat-card-content>
    <app-message messageType="alert">
      ED statistics works!
    </app-message>
  </mat-card-content>
</mat-card>
