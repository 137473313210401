import {Component, Input, OnInit} from '@angular/core';
import {SurveyResultsQuestion} from "../../../../../../model/common";

@Component({
  selector: 'app-block-matrix-radiobuttons-result',
  templateUrl: './block-matrix-radiobuttons-result.component.html',
  styleUrls: ['./block-matrix-radiobuttons-result.component.scss']
})
export class BlockMatrixRadiobuttonsResultComponent implements OnInit {

  @Input("question") question: SurveyResultsQuestion;

  constructor() { }

  ngOnInit() {
  }

  getPercentage(row, col) {
    if(!this.question.results.answers) {
      return null;
    }

    return this.question.results.answers[row][col] / this.question.results.answered_count;

  }

}
