import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../shared/shared.module';

import {HeaderComponent} from './header.component';
import {ToolbarUserComponent} from './toolbar-user/toolbar-user.component';
import {LoadingModule} from '../../component/loading';

import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {ToolbarEdComponent} from "./toolbar-ed/toolbar-ed.component";
import {PipesModule} from "../../components/pipes.module";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    SharedModule,
    HttpClientModule,
    RouterModule,
    PerfectScrollbarModule,
    LoadingModule,
    PipesModule
  ],
  declarations: [
    HeaderComponent,
    ToolbarUserComponent,
    ToolbarEdComponent
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  exports: [
    HeaderComponent,
    ToolbarUserComponent,
    ToolbarEdComponent
  ]
})
export class HeaderModule {
}
