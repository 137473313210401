import {Component, Input, OnInit} from '@angular/core';
import {SurveyResultsQuestion} from "../../../../../../model/common";

@Component({
  selector: 'app-block-checkboxes-result',
  templateUrl: './block-checkboxes-result.component.html',
  styleUrls: ['./block-checkboxes-result.component.scss']
})
export class BlockCheckboxesResultComponent implements OnInit {


  @Input("question") question: SurveyResultsQuestion;


  constructor() {
  }

  ngOnInit() {
  }

  getPercentage(row) {
    if (!this.question.results.answers) {
      return null;
    }
    return this.question.results.answers[row] / this.question.results.answered_count;
  }
}
