import { Component, OnInit } from '@angular/core';
import { AuthApiService } from '../../core/auth-api.service';
import defaults from '../../core/defaults';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  public ed = null;

  constructor(
    private auth: AuthApiService,
  ) { }

  ngOnInit() {
    this.ed = this.auth.user.ed;
  }

  getContactEmail() {
    // console.log('getContactEmail - ed: ' + JSON.stringify(this.ed));
    if (this.ed && this.ed.contact_email) {
      return this.ed.contact_email;
    } else {
      return defaults.contactEmail;
    }
  }

  getContactPhone(callable?: boolean) {
    let phone = null;
    if (this.ed && this.ed.contact_phone) {
      phone = this.ed.contact_phone;
    } else {
      phone = defaults.contactPhone;
    }

    if (callable) {
      return defaults.countryCode + phone.replace(/[()\-+ ]/g, '');
    } else {
      return phone;
    }
  }

}
