<ul class="timeline">
  <li [class.completed]="timelineEvent.completed" *ngFor="let timelineEvent of timelineEvents">
    <div class="timestamp">
      <span class="title">{{timelineEvent.title}}</span>
      <span class="date">{{timelineEvent.date ? (timelineEvent.date | formatDate | date:'MMMM d, h:mm a') : '&nbsp;'}}</span>
    </div>
    <div class="status">
      <mat-icon *ngIf="timelineEvent.completed">check_circle</mat-icon>
      <h4> {{timelineEvent.status}} </h4>
    </div>
  </li>
</ul>
