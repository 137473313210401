<div class="signin-container" fxLayout="row">
  <div class="signin-cover" fxFlex="100" fxFlex.gt-xs="50">
    <div fxLayout="column" fxLayoutAlign="center center" style="height: 100%;">
      <app-ed-logo [logoPath]="edLogo"></app-ed-logo>
    </div>
  </div>

  <div class="signin-content mat-elevation-z12" fxFlex="100" fxFlex.gt-xs="50">

    <app-message
        #message
        [opened]="false"
    >
      <button app-message-actions mat-icon-button (click)="message.close()">
        <mat-icon>cancel</mat-icon>
      </button>
    </app-message>

    <ng-container *ngIf="user">
      <div class="mat-card-title">Hi {{user.first_name}},</div>
      <div class="mat-card-title">Welcome to the {{user.ed.name}} survey system!</div>
      <div class="mat-card-header">Please set a password for your freshly created account</div>


      <form (ngSubmit)="passwordReset()">

        <mat-form-field class="display-block">
          <input [(ngModel)]="password" name="password" type="password" placeholder="Password" required
                 matInput>

        </mat-form-field>

        <mat-form-field class="display-block">
          <input [(ngModel)]="password_confirmed" name="password_confirmation" type="password"
                 placeholder="Please confirm your password" required matInput>
        </mat-form-field>

        <button mat-raised-button color="primary" type="submit">Continue</button>

      </form>
    </ng-container>


  </div>

</div>
