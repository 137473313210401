import * as Tripetto from 'tripetto-collector';
import {condition, ConditionBlock, NodeBlock, tripetto} from 'tripetto-collector';
import {BlockComponentFactory} from '../../helpers/blocks/factory';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnChanges, OnInit,
  SimpleChanges
} from '@angular/core';
import {DropdownOption} from "../../../editor/blocks/matrix-radiobuttons/matrix-radiobuttons";


@Component({
  templateUrl: './matrix-radiobuttons.html',
  styleUrls: ['./matrix-radiobuttons.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MatrixRadiobuttonsBlockComponent extends BlockComponentFactory<MatrixRadiobuttonsBlock> {

}

@Tripetto.block({
  type: 'node',
  identifier: 'block-matrix-radiobuttons',
  ref: MatrixRadiobuttonsBlockComponent
})
export class MatrixRadiobuttonsBlock extends NodeBlock {

  readonly dataSlot = Tripetto.assert(this.valueOf('data-slot'));
  readonly required = this.dataSlot.slot.required || false;

  _state = null;

  get state() {
    if(this._state) {
      return this._state;
    }
    let s = (this.dataSlot.string || this.dataSlot.value) as string;
    this._state = s.length ? JSON.parse(s as string) : {};
    return this._state;
  };

  set state(value) {
    this._state = value;
    this.dataSlot.set(JSON.stringify(this._state));
  }

  isChecked(col, row) {
    return this.state && this.state[row.name] && this.state[row.name] == col.name;
  }

  toggle(col, row) {
    let s = this.state;

    s[row.name] = s[row.name] || {};
    s[row.name] = col.name;

    this.state = s;

    this.dataSlot.set(JSON.stringify(s));
  }


}

@tripetto({
  type: "condition",
  identifier: 'block-matrix-radiobuttons'
})
export class MatrixRadiobuttonsCondition extends ConditionBlock<{
  column: string;
}> {


  @condition
  validate(): boolean {
    const dataSlot = this.valueOf('data-slot');
    if(!dataSlot || !dataSlot.string.length) return false;

    const data = JSON.parse(dataSlot.string);
    const block: any = this.node.block;
    const columnName = block.columns.filter((c: DropdownOption) => c.id == this.props.column)[0].name;

    if (!data || !block || !columnName) {
      return false;
    }

    for (var row in data) {
      if (!data.hasOwnProperty(row)) continue;
      if (data[row] == columnName) return true;
    }

    return false;
  }
}
