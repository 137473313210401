<div class="toolbar-ed-container">
  <button mat-button (click)="toggleDropdown()" [style.pointer-events]="!edList.length?'none':'auto'"
          class="toolbar-ed-btn"
          [class.open]="isOpen">
    <span fxLayout="row" fxLayoutAlign="start center">
      <i class="material-icons">domain</i>
      <span *ngIf="currentED" class="name" fxHide fxShow.gt-sm>{{currentED.name}}</span>
      <span *ngIf="!currentED" class="name" fxHide fxShow.gt-sm>-- Default --</span>
      <mat-icon *ngIf="edList.length" class="icon" fxHide fxShow.gt-sm>keyboard_arrow_down</mat-icon>
    </span>
  </button>

  <div class="dropdown mat-elevation-z1" [class.open]="isOpen">
    <div class="content">
      <div class="list" fxFlex>
        <mat-form-field class="item mt-2" fxLayout="column">
          <input matInput placeholder="Filter ED" [(ngModel)]="edSearchText" type="search">
        </mat-form-field>
        <perfect-scrollbar>
          <div class="item" fxLayout="column" fxLayoutAlign="center left" mat-ripple (click)="selectED(null)">
            <div>-- Default --</div>
          </div>
          <div class="item" fxLayout="column" fxLayoutAlign="center left" mat-ripple
               *ngFor="let ed of edList | filterEd: edSearchText" (click)="selectED(ed)">
            <div>{{ed.name}}</div>
            <div class="mat-small">{{ed.path}}</div>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
</div>
