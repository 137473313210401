import {Component, Input, OnInit} from '@angular/core';
import {SurveyResultsQuestion} from "../../../../../../model/common";

@Component({
  selector: 'app-block-checkbox-result',
  templateUrl: './block-checkbox-result.component.html',
  styleUrls: ['./block-checkbox-result.component.scss']
})
export class BlockCheckboxResultComponent implements OnInit {


  @Input("question") question: SurveyResultsQuestion;


  constructor() { }

  ngOnInit() {
  }



}
