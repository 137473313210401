import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseApiService} from './base-api.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthApiService} from "./auth-api.service";
import {SurveyResponse} from "../model/common";
import {SurveyService} from "./survey.service";
import {ConfigService} from "./config.service";

@Injectable()
export class EdService extends BaseApiService {

  constructor(public http: HttpClient, private auth: AuthApiService, private configService: ConfigService) {
    super();
  }

  public get url(): string {
    return this.configService.apiEndpoint + '/api/ed';
  }

  get edPath(): String {
    return this.auth.user.ed.path;
  }

  all(page: number = null) {
    let params = {};
    if (page) {
      params['page'] = page;
    }
    return this.get('/', params);
  }

  save(data: any): Observable<any> {
    return this.post('/', data).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  search(data) {
    return this.post('/search', data).pipe(
      map((response) => {
        return response;
      })
    );
  }

  deleteED(id) {
    return this.delete('/' + id)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  businessUsers() {
    return this.get(`/${this.edPath}/users`);
  }

  dashboard() {
    return this.get(`/${this.edPath}/dashboard`).pipe(map(r => {

      if (r && r.hasOwnProperty('current_surveys')) {
        r['current_surveys'].map(survey => {
          if (!survey.hasOwnProperty('responses')) {
            return survey;
          }

          survey['responses'].map(row => {
            row['status'] = SurveyService.getSurveyResponseStatus(<SurveyResponse>row);
            return row;
          });

          return survey;
        });
      }

      return r;
    }));
  }

  userDashboard() {
    return this.get(`/${this.edPath}/user-dashboard`);
  }

  EDTemplates() {
    return this.get(`/${this.edPath}/survey-definitions/templates`);
  }

  getTemplateDefinition(definitionID) {
    return this.get(`/${this.edPath}/survey-definitions/templates/${definitionID}`);
  }

  getDefinition(definitionID: number) {
    return this.get(`/${this.edPath}/survey-definitions/${definitionID}`);
  }

}
