import {Component, OnInit, ViewChild} from '@angular/core';
import {AuthApiService} from "../../core/auth-api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {User} from "../../model/common";
import {MessageComponent} from "../../component/message";

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  @ViewChild('message') messageBox: MessageComponent;

  user: User;

  token: string;
  sub: any;

  email: string;
  password: string;
  password_confirmed: string;

  edLogo = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z/C/HgAGgwJ/lK3Q6wAAAABJRU5ErkJggg==';

  constructor(private auth: AuthApiService, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {

    this.sub = this.route.params.subscribe(params => {

      const tokens = params['tokens'];
      // console.log(tokens.split('.'));
      const loginToken = tokens.split('.')[0];
      this.token = tokens.split('.')[1];

      this.auth.loginWithToken(loginToken).subscribe(r => {
        this.user = this.auth.user;
        if (this.user.activated) {
          this.router.navigate(['/signin/' + this.auth.getEDPath()]);
          return;
        }

        this.updateLogo();
      }, (e) => {
        this.messageBox.renderApiError(e);
        this.updateLogo();
      });
    });
  }

  updateLogo() {
    if (this.user && this.user.ed.logo_url) {
      this.edLogo = this.user.ed.logo_url;
    } else {
      this.edLogo = 'assets/images/sensiblesurveyslogo.png';
    }
  }


  passwordReset() {
    this.auth.passwordReset(this.user.email, this.password, this.password_confirmed, this.token)
      .subscribe(r => {
        this.router.navigate(['/']);
      }, (err) => {
        this.messageBox.renderApiError(err);
      });
  }

}
