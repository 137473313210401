<div class="mat-typography start-survey-header">
  <div class="row align-items-center">
    <div class="col-md-6">
      <strong class="text-primary text-uppercase">Select Survey Template</strong><br>
      <strong>Select from the list of surveys the survey you want to use.</strong>
    </div>
    <div class="col-md-4 ml-md-auto">
      <mat-form-field class="w-100">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search Survey Title">
      </mat-form-field>
    </div>
  </div>
</div>
<div class="mat-typography survey-template-table">
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        Select
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-radio-button (click)="selectRow($event,row)"
                          [checked]="_selectedRow == row">
        </mat-radio-button>
      </td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef> Survey Template Name</th>
      <td mat-cell *matCellDef="let element">{{element['title'] || element['survey']['title']}}</td>
    </ng-container>

    <ng-container matColumnDef="updated_at">
      <th mat-header-cell *matHeaderCellDef> Last Updated</th>
      <td mat-cell *matCellDef="let element"> {{element.updated_at | formatDate | date}}</td>
    </ng-container>

    <ng-container matColumnDef="preview">
      <th mat-header-cell *matHeaderCellDef> Preview</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="preview($event,element)">
          <mat-icon>visibility</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <div class="col d-flex pt-3">
    <mat-checkbox 
      [checked]="true"
      [disabled]="isPrefillDisabled()"
      [(ngModel)]="no_prefill"
      (change)="onPrefillChanged($event)">
      Do not pre-fill responses
    </mat-checkbox>
  </div>

</div>
