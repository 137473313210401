import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Survey, SurveyResponse} from '../../../../../model/common';
import {Router} from '@angular/router';
import {AuthApiService} from '../../../../../core/auth-api.service';
import {DialogService} from "../../../../../component/dialog";
import {NotificationService} from "../../../../../component/notification";
import {MessageComponent} from "../../../../../component/message";
import {SurveyService} from "../../../../../core/survey.service";
import {SurveyResultsComponent} from "../../../survey/survey-results/survey-results.component";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-user-dashboard-survey',
  templateUrl: './user-dashboard-survey.component.html',
  styleUrls: ['./user-dashboard-survey.component.scss']
})
export class UserDashboardSurveyComponent implements OnInit, OnDestroy {
  public chartColors = [
    {
      backgroundColor: ['rgba(255,0,0,0.3)', '#ccc'],
    },
  ];

  @ViewChild('message') message: MessageComponent;

  @Input() survey: Survey;

  @Input() set surveyResponse(r: SurveyResponse) {
    let survey = r.survey;
    survey.survey_response = r;
    this.survey = survey;
  }

  subsCan = new Subscription();

  EDPath = this.auth.getEDPath();

  constructor(
    private router: Router,
    private auth: AuthApiService,
    private dialog: DialogService,
    private surveyService: SurveyService,
    private notification: NotificationService
  ) {
  }

  ngOnInit() {
  }

  getSurveyProgress(survey: Survey): number {
    return survey.survey_response.progress || 0;
  }

  openSurvey(survey: Survey) {
    const url = this.auth.getEDPath() + `/survey/${survey.id}`;
    this.router.navigate([url]);
  }

  isSurveyComplete(survey: Survey): boolean {
    return survey.survey_response ? (survey.survey_response.submitted && !survey.survey_response.exclude_from_results) : false;
  }

  getSurveyResponsePrintPath(survey: Survey): string {
    if (this.isResponseSubmitted(survey)) {
      return `${this.EDPath}/survey/${survey.id}/survey-response/${survey.survey_response.id}/print`;
    } else {
     return `${this.EDPath}/survey/${survey.id}`;
    }
  }

  isResponseSubmitted(survey: Survey): boolean {
    return survey.hasOwnProperty('survey_response') && survey.survey_response !== null ?
      Boolean(survey.survey_response.submitted) :
      false;
  }

  requestAccessToSurvey(survey: Survey) {
    this.dialog.confirm({
      title: 'Request an invite',
      message: 'Are you sure you want to participate in this survey? Your request will be emailed to Account owner for approval.',
      cancelButton: 'Cancel',
      acceptButton: 'Yes, Request an invite'
    }).afterClosed().subscribe((r => {
      if (!r) return;
      this.message.renderApiError();
      this.surveyService.requestSurveyInvite(survey.id).subscribe(r => {
        this.message.renderSuccess(r);
      }, e => {
        this.message.renderApiError(e)
      });
    }));
  }


  viewResults(survey: Survey) {
    const dialogRef = this.dialog.open(SurveyResultsComponent, {
      disableClose: false,
      width: '80vw',
      maxHeight: '90vh'
    });

    dialogRef.componentInstance.isModal = true;
    this.subsCan.add(dialogRef.afterOpened().subscribe(() => {
      dialogRef.componentInstance.init(survey.id);
    }));

    this.subsCan.add(dialogRef.componentInstance.onClose.subscribe(() => {
      dialogRef.close();
    }));
  }

  ngOnDestroy(): void {
    if (this.subsCan) {
      this.subsCan.unsubscribe();
    }
  }


}
