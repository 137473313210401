<app-message
  #message
  [opened]="false"
>
  <button app-message-actions mat-icon-button (click)="message.close()">
    <mat-icon>cancel</mat-icon>
  </button>
</app-message>
<mat-card *ngIf="survey" class="dashboard-card">
  <div class="row">
    <div class="col">
      <mat-card-title class="dashboard-card-title">
        {{survey.title}}
        <app-survey-status-pill [survey]="survey"></app-survey-status-pill>
      </mat-card-title>
      <mat-card-subtitle>
        <span>Survey Open: {{survey.start_date | formatDate | date:'longDate'}} </span> | <span
        class="text-danger">Survey Close: {{survey.end_date | formatDate | date:'longDate'}}</span>
      </mat-card-subtitle>
      <mat-card-subtitle *ngIf="survey.video_link">
        <span><a href="{{survey.video_link}}" target="_blank">Watch Video Tutorial</a></span>
      </mat-card-subtitle>
      <mat-card-content>
        <p *ngIf="survey.description">{{survey.description}}</p>
      </mat-card-content>
    </div>
    <div class="col-md-5 align-self-center" *ngIf="getSurveyProgress(survey)">
      <app-message messageType="alert"
                   color="accent"
                   *ngIf="isSurveyComplete(survey)"
                   [icon]="false"
                   class="text-center p-3 pt-4">
        <mat-icon class="mat-icon--lg">check</mat-icon>
        <p>You have already completed and <strong>submitted</strong> the survey. You will be able to view result once
          the survey closes and the results are generated.</p>
      </app-message>
      <canvas baseChart
              *ngIf="!isSurveyComplete(survey)"
              width="100%"
              [data]="[getSurveyProgress(survey), 100 - getSurveyProgress(survey)]"
              [labels]="['Completed', 'Incomplete']"
              [colors]="chartColors"
              [chartType]="'doughnut'"
              [legend]="false">
      </canvas>
      <p class="text-center mt-2"><strong>{{getSurveyProgress(survey)}}% Complete</strong></p>
    </div>
  </div>
  <div class="dashboard-survey-card-actions">
    <ng-container *ngIf="survey.status != 'canceled'">
      <button
        mat-raised-button
        color="accent"
        class="w-100"
        [disabled]="survey.status != 'running'"
        *ngIf="survey.survey_response && !getSurveyProgress(survey)"
        (click)="openSurvey(survey)">Start&nbsp;Survey
      </button>
      <button
        mat-raised-button
        color="accent"
        class="w-100 mt-2"
        [disabled]="survey.status != 'running'"
        *ngIf="getSurveyProgress(survey) && !isSurveyComplete(survey)"
        (click)="openSurvey(survey)">Continue Survey
      </button>
      <a
        mat-raised-button
        color="primary"
        class="w-100 mt-2"
        target="_blank"
        [disabled]="!isResponseSubmitted(survey)"
        [routerLink]="[getSurveyResponsePrintPath(survey)]"
        >
        Print your survey response 
        {{!isResponseSubmitted(survey) ? '(available after submitting your response)' : ''}}
      </a>
      <button mat-raised-button 
        class="w-100 mt-2"
        [color]="'accent'"
        *ngIf="isSurveyComplete(survey) && survey.results_compiled_at"
        [routerLink]="[EDPath+'/results/'+survey.id]">
          View Survey Results
      </button>
    </ng-container>
  </div>
</mat-card>
