import {Component, ElementRef, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthApiService} from '../../../core/auth-api.service';
import {EdService} from "../../../core/ed.service";
import {ED} from "../../../model/common";

@Component({
  selector: 'app-toolbar-ed',
  templateUrl: './toolbar-ed.component.html',
  styleUrls: ['./toolbar-ed.component.scss']
})
export class ToolbarEdComponent implements OnInit, OnDestroy {
  isOpen = false;
  onUserChangeSub = null;
  currentUser = null;
  currentED = null;
  edList = [];
  edSearchText: string = null;

  @HostListener('document:click', ['$event', '$event.target'])
  onClick(event: MouseEvent, targetElement: HTMLElement) {
    if (!targetElement) {
      return;
    }

    const clickedInside = this._elementRef.nativeElement.contains(
      targetElement
    );
    if (!clickedInside) {
      this.isOpen = false;
    }
  }

  constructor(
    private _elementRef: ElementRef,
    private router: Router,
    private auth: AuthApiService,
    private edService: EdService
  ) {
    this.updateState();
  }

  ngOnInit() {
    this.currentUser = this.auth.user;

    this.onUserChangeSub = this.auth.onUserChange.subscribe(user => {
      this.currentUser = user;
      this.updateState();
    });

    this.updateState();
  }

  updateState() {
    if (!this.auth.user) return;

    if (!this.auth.isSuperAdmin()) {
      this.edList = [];
    } else if (!this.edList.length) {
      this.edService.all().subscribe(result => {
        this.edList = result['data'];
      });
    }

    this.currentED = this.auth.user.ed;
  }

  selectED(ed: ED | null) {
    const user = this.auth.user;
    user.ed = ed;
    this.auth.user = user;
    this.isOpen = false;
    this.router.navigate(['/']);
  }

  toggleDropdown() {
    if (!this.edList.length) {
      return;
    }
    this.isOpen = !this.isOpen;
  }

  ngOnDestroy(): void {
    this.onUserChangeSub.unsubscribe();
  }
}
