import {Component, Input, OnInit} from '@angular/core';
import {SurveyResultsQuestion} from "../../../../../../model/common";

@Component({
  selector: 'app-block-tripetto-text-result',
  templateUrl: './block-tripetto-text-result.component.html',
  styleUrls: ['./block-tripetto-text-result.component.scss']
})
export class BlockTripettoTextResultComponent implements OnInit {

  @Input("question") question: SurveyResultsQuestion;

  values = [];

  constructor() {
  }

  ngOnInit() {
    if (this.question.results['answers']) {

      let keys = Object.keys(this.question.results['answers']);

      keys.forEach((k) => {
        this.values.push({
          'label': k,
          'value': this.question.results['answers'][k]
        });
      });

      this.values = this.values.sort((a, b) => b['value'] - a['value']);
    }
  }


}
