<div *ngIf="!surveyTemplate" class="text-center m-4">
    <div class="mat-title text-info">Please, select survey template on Step 1</div>
</div>

<div class="mat-typography" *ngIf="surveyTemplate">
    <div class="preview-survey-header">
        <div class="row align-items-center mb-2">
            <div class="col-md-8">
                <strong class="text-primary text-uppercase">Preview Survey Questions</strong><br>
                <strong>If there is a need to add, delete, or edit survey questions during the survey creation process,
                    please contact JH Media Group to request their changes.</strong>
            </div>
            <div class="col-md-4">
                <button mat-stroked-button color="primary" class="w-100" (click)="util.showHelpModal()">Contact Us</button>
            </div>
        </div>
    </div>
    <div class="survey-preview">
        <div class="row mt-1 mb-1">
            <div class="col text-right">
                <button mat-button color="primary" (click)="edit()"><mat-icon>edit</mat-icon> Edit survey</button>
                <button mat-button color="accent" (click)="revert()" *ngIf="true || surveyTemplate && surveyTemplate.original">
                    <mat-icon>settings_backup_restore</mat-icon> Revert All Changes
                </button>
            </div>
        </div>
        <app-take-survey
          #appTakeSurvey
          class="mat-elevation-z2"
                [surveyDefinition]="surveyTemplate"
        >
        </app-take-survey>
        <div class="text-center mt-4 mb-2 h5">
            <mat-checkbox [(ngModel)]="questionsApproved">I have <strong>reviewed and approve</strong> the existing survey
                questions.
            </mat-checkbox>
        </div>
    </div>
</div>
