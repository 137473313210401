import {Component, Input, OnInit} from '@angular/core';
import {SurveyResultsQuestion} from "../../../../../../model/common";

@Component({
  selector: 'app-block-matrix-dropdowns-result',
  templateUrl: './block-matrix-dropdowns-result.component.html',
  styleUrls: ['./block-matrix-dropdowns-result.component.scss']
})
export class BlockMatrixDropdownsResultComponent implements OnInit {

  @Input("question") question: SurveyResultsQuestion;

  ngOnInit(): void {

  }

  getResponsesSum(row, col) {
    if (!this.question.results.answers) {
      return null;
    }

    if (!Object.keys(this.question.results.answers).length) {
      return null;
    }

    let data = this.question.results.answers[row][col];

    let count = 0;
    for (let dataKey in data) {
      // noinspection JSUnfilteredForInLoop
      count += data[dataKey];
    }

    return count > 0 ? count :  null;
  }


}
