import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filterQuestions'
})
export class FilterQuestionsPipe implements PipeTransform {
  transform(items: any[]): any[] {
    if (!items) {
      return [];
    }

    return items.filter(it => {
      return it.hasOwnProperty('questions');
    });
  }
}
