import {NgModule} from '@angular/core';
import {FilterEdPipe} from "./filter-ed.pipe";
import {FilterTitlePipe} from "./filter-title.pipe";
import {FilterZeroPipe} from "./filter-zero.pipe";
import {FilterQuestionsPipe} from "./filter-questions.pipe";

@NgModule({
  imports: [],
  declarations: [
    FilterEdPipe,
    FilterZeroPipe,
    FilterTitlePipe,
    FilterQuestionsPipe,
  ],
  exports: [
    FilterEdPipe,
    FilterZeroPipe,
    FilterTitlePipe,
    FilterQuestionsPipe,
  ]
})
export class PipesModule {
}
