<mat-card class="mat-typography">
  <mat-card-title
    *ngIf="name"
    markdown
    [content]="name"
    [context]="context"
    [enumerator]="enumerator"
    [required]="!placeholder && block.required"
  ></mat-card-title>
  <mat-card-content *ngIf="description" markdown [content]="description" [context]="context"></mat-card-content>
  <mat-form-field class="form-field-full-width">
    <mat-select
      #ref
      [placeholder]="placeholder"
      [required]="block.required"
      [value]="block.value"
      (selectionChange)="block.value=ref.value"
    >
      <mat-option *ngFor="let option of block.props.options" [value]="option.id">{{ option.name || "..." }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-card-footer *ngIf="explanation" markdown [content]="explanation" [context]="context"></mat-card-footer>
</mat-card>
