import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EDFormComponent} from "./edform/edform.component";
import {SharedModule} from "../shared/shared.module";
import {ReactiveFormsModule} from "@angular/forms";
import {SurveyEditorComponent} from './survey-editor/survey-editor.component';
import {UserFormComponent} from './user-form/user-form.component';
import {CreatedUpdatedSectionComponent} from './created-updated-section/created-updated-section.component';
import {EventsLogTableComponent} from './events-log-table/events-log-table.component';
import {EditProfileComponent} from "./edit-profile/edit-profile.component";
import {TripettoModule} from "../tripetto/tripetto.module";
import {UsersTableComponent} from './users-table/users-table.component';
import {CollectorComponent} from "../tripetto/collector/collector.component";
import {SurveyResponsesTableComponent} from './survey-responses-table/survey-responses-table.component';
import {SurveyDefinitionEditorComponent} from './survey-editor/survey-definition-editor/survey-definition-editor.component';
import {FilterEdPipe} from "./filter-ed.pipe";
import { SurveyStatusPillComponent } from './survey-status-pill/survey-status-pill.component';
import { TimelineComponent } from './timeline/timeline.component';
import {FilterZeroPipe} from "./filter-zero.pipe";
import {FilterTitlePipe} from "./filter-title.pipe";
import {FilterQuestionsPipe} from "./filter-questions.pipe";
import {FormatDatePipe} from "./format-date.pipe";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {NgxChartsModule} from "@swimlane/ngx-charts";
import {PipesModule} from "./pipes.module";
import {UtilityService} from "./utility.service";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    TripettoModule,
    NgxDatatableModule,
    NgxChartsModule,
    PipesModule
  ],
  declarations: [
    EDFormComponent,
    SurveyEditorComponent,
    UserFormComponent,
    CreatedUpdatedSectionComponent,
    EventsLogTableComponent,
    EditProfileComponent,
    UsersTableComponent,
    SurveyResponsesTableComponent,
    SurveyDefinitionEditorComponent,
    TimelineComponent,
    SurveyStatusPillComponent,
    FormatDatePipe
  ],
  exports: [
    EDFormComponent,
    SurveyEditorComponent,
    UserFormComponent,
    CreatedUpdatedSectionComponent,
    EventsLogTableComponent,
    EditProfileComponent,
    UsersTableComponent,
    CollectorComponent,
    ReactiveFormsModule,
    SurveyResponsesTableComponent,
    FilterEdPipe,
    FilterTitlePipe,
    FilterQuestionsPipe,
    TimelineComponent,
    SurveyStatusPillComponent,
    FilterZeroPipe,
    FormatDatePipe,
    NgxDatatableModule,
    NgxChartsModule,
    TripettoModule,
    PipesModule
  ],
  entryComponents: [
    EDFormComponent,
    SurveyEditorComponent,
    UserFormComponent,
    EditProfileComponent,
    SurveyDefinitionEditorComponent
  ],
  providers: [
    UtilityService
  ]
})
export class ComponentsModule {
}
