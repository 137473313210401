<mat-card class="mat-typography">
  <mat-card-title
    *ngIf="name"
    markdown
    [content]="name"
    [context]="context"
    [enumerator]="enumerator"
    [required]="!placeholder && block.required"
  ></mat-card-title>
  <mat-card-content *ngIf="description" markdown [content]="description" [context]="context"></mat-card-content>
  <mat-form-field class="form-field-full-width">
    <input
      #ref
      type="password"
      matInput
      [required]="block.required"
      [placeholder]="placeholder"
      [value]="block.passwordSlot.value"
      [(ngModel)]="block.passwordSlot.value"
      (blur)="block.onBlur(ref)"
    />
  </mat-form-field>
  <mat-card-footer *ngIf="explanation" markdown [content]="explanation" [context]="context"></mat-card-footer>
</mat-card>
