<div class="w-100 d-flex justify-content-center">
  <table class="mat-table">
    <thead>
    <tr class="mat-header-row">
      <th class="mat-header-cell">&nbsp;</th>
      <th class="mat-header-cell" *ngFor="let c of question.block.columns">{{c.name}}</th>
    </tr>
    </thead>
    <tbody>
    <tr class="mat-row" *ngFor="let row of question.block.rows">
      <th class="mat-header-cell">{{row.name}}</th>
      <td class="mat-cell" *ngFor="let col of question.block.columns">
        <ng-container *ngIf="question.results.answers">
          <div class="font-weight-bold">{{getPercentage(row.name, col.name) |  percent | filterZero}}</div>
          <!--<div>{{question.results.answers[row.name][col.name] | filterZero}}</div>-->
        </ng-container>
      </td>
    </tr>
    </tbody>
  </table>
</div>
