<mat-drawer-container class="mat-drawer-container" [class.boxed]="layoutMode">

  <mat-drawer class="sidenav" [position]="sidenavAlign" [mode]="sidenavMode" [opened]="sidenavOpen" #sidenav *ngIf="!settings.printMode">
    <app-brand [ngClass]="settings.colorClasses.brand"></app-brand>
    <app-navigation [ngClass]="settings.colorClasses.navigation"></app-navigation>
  </mat-drawer>

  <div class="sidenav-container" fxLayout="column">
    <app-header (sidenavToggle)="onSidenavToggle()" [ngClass]="settings.colorClasses.header"
                *ngIf="settings.layout.header=='below' && !settings.printMode">
    </app-header>
    <div class="main-container" #scrollContainer>
      <div *ngIf="loading" class="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
        <mat-progress-spinner
          [mode]="'indeterminate'">
        </mat-progress-spinner>
      </div>
      <router-outlet [hidden]="loading" (activate)="onActivate($event, scrollContainer)">
      </router-outlet>
    </div>
  </div>

</mat-drawer-container>

