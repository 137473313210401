<div class="row m-5">
  <div class="col text-center">
    <mat-icon class="help-icon">help</mat-icon>
  </div>
</div>
<div class="row m-5">
  <div class="col text-center">
    <h5 class="text-center">
      If you have any trouble please send an email to <br/><a
      href="mailto:{{getContactEmail()}}">{{getContactEmail()}} </a><br/> for assistance or <br/>call <a
      href="sip://{{getContactPhone(true)}}">{{getContactPhone()}}</a>
    </h5>
  </div>
</div>
