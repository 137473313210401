import {Component, Input, OnInit} from '@angular/core';
import {ITimelineEvent} from './timelineEvent.interface';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {
  @Input() timelineEvents: Array<ITimelineEvent>;
  constructor() { }

  ngOnInit() {
  }

}
