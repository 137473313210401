/**
 * @license
 * Copyright Stbui All Rights Reserved.
 * https://github.com/stbui
 */

import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {PopoverService} from './popover.service';

@Component({
  moduleId: module.id,
  selector: 'stbui-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
  exportAs: 'stbuiPopover',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PopoverService]
})
export class PopoverComponent implements AfterContentInit {
  // @ViewChild(TemplateRef, { static: false }) templateRef: TemplateRef<any>;
  // @ViewChild('focusTrapElement', { static: false }) private _focusTrapElement: ElementRef;

  @ViewChild(TemplateRef) templateRef: TemplateRef<any>;
  @ViewChild('focusTrapElement') private _focusTrapElement: ElementRef;

  @Output() onOpen = new EventEmitter();

  constructor(
    private _popoverService: PopoverService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngAfterContentInit() {
  }

  open() {
    this.onOpen.emit();
    this._popoverService.afterOpened();
  }

  close() {
    console.log('close');
  }

  toggle() {
    console.log('toggle');
  }
}
