import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import {IndustriesService} from "../../../../../core/industries.service";
import {MessageComponent} from "../../../../../component/message";
import {MatCheckbox, MatListOption, MatSelectionList, MatExpansionPanel} from "@angular/material";
import {Industry, Occupation} from "../../../../../model/common";
import {DialogService} from "../../../../../component/dialog";

@Component({
  selector: 'app-step-industry-and-occupation-list',
  templateUrl: './step-industry-and-occupation-list.component.html',
  styleUrls: ['./step-industry-and-occupation-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepIndustryAndOccupationListComponent implements OnInit {

  @Input() message: MessageComponent;

  private occupationByIDs: { [id: number]: Occupation } = {};

  @Input() set occupations(occupations: Occupation[]) {
    this.zone.runOutsideAngular(() => {
      this.occupationByIDs = {};

      if (!occupations) {
        return;
      }

      occupations.forEach((occupation) => {
        this.occupationByIDs[occupation.id] = occupation;
      });

      if (this.changeDetector) {
        this.changeDetector.detectChanges();
      }
    });
  };

  @Input() allOccupationsSelected: boolean;

  @Output() occupationsChanged = new EventEmitter();

  @ViewChildren('occupationsList') occupationLists: QueryList<MatSelectionList>;
  @ViewChildren('industryPanel') industryPanels: QueryList<MatExpansionPanel>;

  industries: Industry[] = [];
  commonOccupationsIDs: Number[] = [];
  commonOccupations: Occupation[] = [];
  searchOccupations: string = null;

  constructor(
    private industriesService: IndustriesService,
    private dialog: DialogService,
    private changeDetector: ChangeDetectorRef,
    private zone: NgZone,
  ) { }

  ngOnInit() {
    this.industriesService.all().subscribe(
      r => {
        this.industries = r['industries'] as Industry[];
        this.commonOccupationsIDs = r['common_occupations_ids'] as Number[];

        this.industries.forEach((industry: Industry) => {
          industry.occupations.forEach((occupation: Occupation) => {
            if (this.commonOccupationsIDs.indexOf(occupation.id) > -1) {
              this.commonOccupations.push(occupation);
            }
          });
        });

        this.changeDetector.detectChanges();
      }, e => {
        this.message.renderApiError(e);
        this.changeDetector.detectChanges();
      });
  }

  onIndustryAdd(input: HTMLInputElement) {
    let industryTitle = input.value.trim();
    if (!industryTitle.length) return;
    if (this.industries.filter(i => i.title.toLocaleLowerCase() == input.value.toLocaleLowerCase()).length) {
      return;
    }
    this.industries.unshift(<Industry>{title: input.value, occupations: []});
    input.value = null;
    return false;
  }

  onOccupationAdd(industry: Industry, input: HTMLInputElement, description: HTMLInputElement, list: MatSelectionList) {
    const occupationTitle = input.value.trim();
    const descriptionValue = description.value.trim();

    if (!occupationTitle.length) { return; }
    if (!descriptionValue.length) { return; }
    if (industry.occupations.filter(i => i.title.toLocaleLowerCase() == input.value.toLocaleLowerCase()).length) {
      return;
    }
    const occupation = <Occupation>{
      title: input.value,
      description: descriptionValue,
      industry: <Industry>{id: industry.id, title: industry.title},
    };

    industry.occupations.push(occupation);
    input.value = null;
    description.value = null;

    if (this.changeDetector) {
      this.changeDetector.detectChanges();
    }
    return false;
  }

  onSearchOccupationsChanged() {
    this.industryPanels.forEach(panel => {
      panel.close();
    });
    this.removeOccupationHighlight();

    if (this.searchOccupations.length < 4) {
      return;
    }

    this.industries.forEach((industry, index) => {
      industry.occupations.forEach(occupation => {
        if (occupation.title.toUpperCase().includes(this.searchOccupations.toUpperCase())) {
          this.industryPanels.toArray()[index].open();
          occupation['searchHighlight'] = true;
        }
      });
    });
  }

  removeOccupationHighlight() {
    this.industries.forEach((industry, index) => {
      industry.occupations.forEach(occupation => {
        occupation['searchHighlight'] = false;
      });
    });
  }

  removeOccupation($event, industry: Industry, occupation: Occupation, list: MatSelectionList, item: MatListOption) {
    $event.stopImmediatePropagation();

    this.dialog.confirm({
      title: 'Remove occupation',
      message: 'Are you sure you want to remove this occupation from the list?',
      cancelButton: 'Cancel',
      acceptButton: 'Yes, remove'
    }).afterClosed().subscribe(r => {
      if (r !== true) {
        return;
      }

      industry.occupations.indexOf(occupation);

      const index = industry.occupations.indexOf(occupation);

      if (index > -1) {
        list.selectedOptions.deselect(item);
        industry.occupations.splice(index, 1);
      }

      if (this.changeDetector) {
        this.changeDetector.detectChanges();
      }
    });
  }

  toggleAllOccupations($event, check: MatCheckbox, list: MatSelectionList) {
    $event.preventDefault();
    if (check.checked) {
      list.selectedOptions.clear();
    } else {
      list.selectAll();
    }
    this.selectionChange();
  }

  getSelectedOccupationsCount(occupationsList: MatSelectionList) {
    if (!occupationsList) return 0;
    return occupationsList.selectedOptions.selected.length;
  }

  isChecked(occupationsList: MatSelectionList) {
    if (!occupationsList) return false;
    return occupationsList.selectedOptions.selected.length && occupationsList.selectedOptions.selected.length == occupationsList.options.length;
  }

  isIndeterminate(occupationsList: MatSelectionList) {
    if (!occupationsList) return false;
    return occupationsList.selectedOptions.selected.length && occupationsList.selectedOptions.selected.length != occupationsList.options.length;
  }

  selectionChange() {
    this.emitChanges();
  }

  emitChanges() {
    let occupations = [];
    this.occupationLists.forEach(list => {
      list.selectedOptions.selected.forEach((occupation: MatListOption) => {
        occupations.push(occupation.value);
      });
    });
    this.occupationsChanged.emit(occupations);
  }

  occupationSelected(occupation): boolean {
    return this.occupationByIDs.hasOwnProperty(occupation.id);
  }

  removeIndustry($event, industry, occupationsList: MatSelectionList) {
    $event.stopImmediatePropagation();

    this.dialog.confirm({
      title: 'Remove industry',
      message: 'Are you sure you want to remove this industry from the list?',
      cancelButton: 'Cancel',
      acceptButton: 'Yes, remove'
    }).afterClosed().subscribe(r => {
      if (r !== true) {
        return;
      }

      occupationsList.deselectAll();
      this.selectionChange();

      this.industries.indexOf(industry);

      const index = this.industries.indexOf(industry);

      if (index > -1) {
        this.industries.splice(index, 1);
      }

      if (this.changeDetector) {
        this.changeDetector.detectChanges();
      }


    });
  }

  selectAllOccupations() {
    this.zone.runOutsideAngular(() => {

      this.occupationLists.forEach(list => {
        list.selectAll();
      });

      this.emitChanges();

      if (this.changeDetector) {
        this.changeDetector.detectChanges();
      }
    });
  }

  deselectAllOccupations() {
    this.zone.runOutsideAngular(() => {

      this.occupationLists.forEach(list => {
        list.deselectAll();
      });

      this.emitChanges();

      if (this.changeDetector) {
        this.changeDetector.detectChanges();
      }
    });
  }

  isAllOccupationsChecked() {
    if (!this.occupationLists) return false;
    let c = 0;
    this.occupationLists.forEach(list => {
      if (list.options.length == list.selectedOptions.selected.length) {
        c++;
      }
    });
    return c == this.occupationLists.length;
  }

  onDescpopoverOpen(occupation, descContainer) {
    if (occupation.description) {
      descContainer.innerHTML = occupation.description;
    } else {
      this.industriesService.getOccupation(occupation.id).subscribe(r => {
        descContainer.innerHTML = r['description'];
    });
    }
  }

  commonOccupationsSelectionChange(commonOccupationsList) {
    let list = commonOccupationsList as MatSelectionList;
    let occupations = [];

    this.occupationLists.forEach(list => {
      list.selectedOptions.selected.forEach((occupation: MatListOption) => {
        occupations.push(occupation.value);
      });
    });

    list.selectedOptions.selected.forEach((occupation: MatListOption) => {
      if(occupations.indexOf(occupation.value) > -1) return;
      occupations.push(occupation.value);
    });

    this.occupationsChanged.emit(occupations);
  }


}
