import {Component, Input, Output, EventEmitter } from '@angular/core';
import * as screenfull from 'screenfull';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  @Input() customizer;
  @Output() sidenavToggle = new EventEmitter<boolean>();

  isFullscreen: boolean = false;
  showLoading: boolean;

  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.showLoading = true;
      } else if (event instanceof NavigationEnd) {
        this.showLoading = false;
      }
    });
  }

  toggleFullscreen() {
    if (screenfull.enabled) {
      screenfull.toggle();
      this.isFullscreen = !this.isFullscreen;
    }
  }

  onToggleSidenavClicked() {
    this.sidenavToggle.emit(true);
  }
}
