import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material";
import {Survey} from "../../../model/common";
import {MessageComponent} from "../../../component/message";
import {SurveyService} from "../../../core/survey.service";
import {catchError} from "rxjs/operators";
import {AuthApiService} from "../../../core/auth-api.service";
import {Observable, of} from "rxjs";
import {ConfigService} from '../../../core/config.service';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {

  EDPath = this.auth.getEDPath();
  DownloadUrl = '';
  isEDMember: boolean;
  @ViewChild('message') messageBox: MessageComponent;

  dataSource = new MatTableDataSource<Survey>();
  displayedColumns = ['title', 'survey_takers_count', 'completion_rate', 'view', 'export'];

  data: Survey[] = [];

  constructor(private surveyService: SurveyService, private auth: AuthApiService, public configService: ConfigService) {
    this.DownloadUrl = this.configService.apiEndpoint + `/api/ed`;
    this.isEDMember = this.auth.isEDMember();
  }

  ngOnInit() {
    this.messageBox.renderApiError();
    this.surveyService.getResults().pipe(catchError((err, caught: Observable<any>) => {
      this.messageBox.renderApiError(err);
      return of([]);
    })).subscribe((r) => {
      this.data = <Survey[]>r;
    });

  }

}
