<div class="mat-card mat-typography tripetto-matrix-radiobuttons">

  <div class="mat-card-title" *ngIf="name" markdown [content]="name" [context]="context" [enumerator]="enumerator" [required]="!placeholder && block.required"></div>
  <div class="mat-card-content" *ngIf="description" markdown [content]="description" [context]="context"></div>

  <div class="mat-card-content">
    <table class="mat-table w100">
      <thead>
      <tr class="mat-header-row">
        <th class="mat-header-cell">&nbsp;</th>
        <th class="mat-header-cell" *ngFor="let c of block.props.columns">{{c.name}}</th>
      </tr>
      </thead>
      <tbody>
      <tr class="mat-row" *ngFor="let row of block.props.rows">
        <th class="mat-header-cell mat-header-cell-column">{{row.name}}</th>
        <td class="mat-cell" *ngFor="let col of block.props.columns">
          <mat-radio-button [name]="block.dataSlot.key+'_'+row.id"
                            [value]="block.dataSlot.key+'_'+col.id"
                            [checked]="block.isChecked(col, row)"
                            (change)="block.toggle(col, row)"
          ></mat-radio-button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="mat-card-footer" *ngIf="explanation" markdown [content]="explanation" [context]="context"></div>
</div>

