import * as Tripetto from 'tripetto-collector';
import {condition, ConditionBlock, NodeBlock, tripetto} from 'tripetto-collector';
import {BlockComponentFactory} from '../../helpers/blocks/factory';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatCheckbox} from "@angular/material";
import {DropdownOption} from "../../../editor/blocks/matrix-checkboxes/matrix-checkboxes";

@Component({
  templateUrl: './matrix-checkboxes.html',
  styleUrls: ['./matrix-checkboxes.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MatrixCheckboxesBlockComponent extends BlockComponentFactory<MatrixCheckboxesBlock> {

}

@Tripetto.block({
  type: 'node',
  identifier: 'block-matrix-checkboxes',
  ref: MatrixCheckboxesBlockComponent
})
export class MatrixCheckboxesBlock extends NodeBlock {

  readonly dataSlot = Tripetto.assert(this.valueOf('data-slot'));
  readonly required = this.dataSlot.slot.required || false;

  _state = null;

  get state() {
    if (this._state) {
      return this._state;
    }
    let s = (this.dataSlot.string || this.dataSlot.value) as string;
    this._state = s.length ? JSON.parse(s as string) : null;
    return this._state;
  };

  set state(value) {
    this._state = value;

    for (let rowKey in this._state) {
      let rowState = false;
      for (let columnKey in this._state[rowKey]) {
        // noinspection JSUnfilteredForInLoop
        rowState = rowState || this._state[rowKey][columnKey];
      }
      if (!rowState) {
        delete this._state[rowKey];
      }
    }

    let slotValue = Object.keys(this._state).length ? JSON.stringify(this._state) : '';
    this.dataSlot.set(slotValue);
  }

  isChecked(col, row) {
    return this.state && this.state[row.name] && this.state[row.name][col.name];
  }

  toggle(ref: MatCheckbox, col, row) {
    let s = this.state || {};

    s[row.name] = s[row.name] || {};
    s[row.name][col.name] = s[row.name][col.name] || {};
    s[row.name][col.name] = ref.checked;

    this.state = s;
  }


}


@tripetto({
  type: "condition",
  identifier: 'block-matrix-checkboxes'
})
export class MatrixCheckboxesCondition extends ConditionBlock<{
  column: string;
}> {


  @condition
  validate(): boolean {
    const dataSlot = this.valueOf('data-slot');
    if (!dataSlot || !dataSlot.string.length) return false;

    const data = JSON.parse(dataSlot.string);
    const block: any = this.node.block;
    const columnName = block.columns.filter((c: DropdownOption) => c.id == this.props.column)[0].name;

    if (!data || !block || !columnName) {
      return false;
    }

    for (var row in data) {
      if (!data.hasOwnProperty(row)) continue;
      if (data[row][columnName]) return true;
    }

    return false;
  }
}
