<div class="mat-typography">
  <div class="survey-information-header" *ngIf="!hideHeader">
    <strong class="text-uppercase text-primary">Survey Information</strong><br>
    <strong>Fill out the form to setup survey title and survey open/close dates with description.</strong>
  </div>
  <form [formGroup]="form">
    <div class="form-row mt-4">
      <div class="col">
        <mat-form-field class="w-100">
          <input formControlName="title" matInput placeholder="Survey Title">
          <mat-error *ngIf="formErrors.title">{{formErrors.title}}</mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field class="w-100">
          <input formControlName="video_link" matInput placeholder="Video Link">
        </mat-form-field>
      </div>
      <div class="w-100"></div>
      <div class="col">
        <mat-form-field class="w-100">
          <input
            #dateFrom
            matInput
            formControlName="start_date"
            placeholder="Choose Open Date"
            [readonly]="true"
            [matDatepicker]="pickerStartDate"
            [max]="form.get('end_date').value"
            (focus)="pickerStartDate.open()"
          >
          <mat-datepicker-toggle matSuffix [for]="pickerStartDate"></mat-datepicker-toggle>
          <mat-datepicker #pickerStartDate></mat-datepicker>
          <mat-error *ngIf="formErrors.start_date">{{formErrors.start_date}}</mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field class="w-100">
          <input
            #dateTo
            matInput
            formControlName="end_date"
            placeholder="Choose Close Date"
            [readonly]="true"
            [matDatepicker]="pickerEndDate"
            [min]="form.get('start_date').value"
            (focus)="pickerEndDate.open()"
          >
          <mat-datepicker-toggle matSuffix [for]="pickerEndDate"></mat-datepicker-toggle>
          <mat-datepicker #pickerEndDate></mat-datepicker>
          <mat-error *ngIf="formErrors.end_date">{{formErrors.end_date}}</mat-error>
        </mat-form-field>
      </div>
      <div class="w-100"></div>
      <div class="col">
        <mat-form-field class="w-100">
          <mat-label>Results Safe-Harbor count</mat-label>
          <mat-select formControlName="safe_harbor_count">
            <mat-option [value]="0">
              -- Disabled --
            </mat-option>
            <mat-option *ngFor="let val of [5,10,15]" [value]="val">
              {{val}}
            </mat-option>
          </mat-select>
          <mat-hint>Number of question responses needed to make it valid</mat-hint>
          <mat-error *ngIf="formErrors.safe_harbor_count">{{formErrors.safe_harbor_count}}</mat-error>
        </mat-form-field>
      </div>
      <div class="col d-flex align-items-center">
        <mat-checkbox
          formControlName="show_businesses_list_in_results"
        >
          Show businesses list in results
        </mat-checkbox>
        <mat-error
          *ngIf="formErrors.show_businesses_list_in_results">{{formErrors.show_businesses_list_in_results}}</mat-error>
      </div>
      <div class="w-100"></div>

      <div class="col mt-3">
        <mat-form-field class="w-100">
          <mat-label>Survey description</mat-label>
          <textarea matInput
                    rows="10"
                    formControlName="description"></textarea>
        </mat-form-field>
      </div>
      <div class="w-100"></div>

      <div class="col d-flex align-items-center">
        <mat-checkbox
          [(ngModel)]="use_custom_survey_invitation"
          [ngModelOptions]="{standalone: true}"
          (ngModelChange)="onUseCustomSurveyInvitationChanged()">
          Use custom survey invitation
        </mat-checkbox>
      </div>
      <div class="w-100"></div>

      <div class="col mt-3" *ngIf="use_custom_survey_invitation">
        <mat-form-field class="w-100">
          <mat-label>Custom survey invitation</mat-label>
          <textarea matInput
                    rows="10"
                    formControlName="custom_survey_invitation"></textarea>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
