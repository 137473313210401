import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SurveyResults, SurveyResultsSection} from "../../../../../model/common";

@Component({
  selector: 'app-survey-results-table-of-contents',
  templateUrl: './survey-results-table-of-contents.component.html',
  styleUrls: ['./survey-results-table-of-contents.component.scss']
})
export class SurveyResultsTableOfContentsComponent implements OnInit {

  @Input('surveyResults') surveyResults: SurveyResults;

  @Output() sectionSelected = new EventEmitter<SurveyResultsSection>();

  constructor() {
  }

  ngOnInit() {
  }

  sectionClick(section) {
    this.sectionSelected.emit(section);
  }
}
