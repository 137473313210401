import {Component, Input, OnInit} from '@angular/core';
import {SurveyResultsQuestion} from "../../../../../../model/common";
import 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-block-tripetto-dropdown-result',
  templateUrl: './block-tripetto-dropdown-result.component.html',
  styleUrls: ['./block-tripetto-dropdown-result.component.scss']
})
export class BlockTripettoDropdownResultComponent implements OnInit {

  @Input() question: SurveyResultsQuestion;

  constructor() {
  }

  ngOnInit() {
  }


}
