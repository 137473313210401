import { Injectable } from '@angular/core';
import {NotificationService} from "../component/notification/index";

@Injectable()
export class UserSettingsService {

    private instanceSettings = {};
    private userSettings = {};

    constructor(
        private notification: NotificationService,
    ) { }

    setInstanceSetting(name: string, value: any) {
        this.instanceSettings[name] = value;
    }

    getInstanceSetting(name: string): any {
        return this.instanceSettings[name];
    }

    clearInstanceSetting(name: string): any {
        delete this.instanceSettings[name];
    }

    setUserSetting(name: string, type: string, value: any) {
        try {
            if ((typeof(Storage) !== 'undefined')) {
                localStorage.setItem(name, this.settingToString(type, value));
            } else {
                this.userSettings[name] = value;
            }
        } catch (e) {
            this.notification.error('An error occurred while saving your settings for "' + name + '" to your browser local storage.', 'Local storage error');
            this.userSettings[name] = value;
        }
    }

    getUserSetting(name: string, type: string): any {
        try {
            if ((typeof(Storage) !== 'undefined') && localStorage.getItem(name)) {
                return this.stringToSetting(type, localStorage.getItem(name));
            } else {
                return this.userSettings[name];
            }
        } catch (e) {
            this.notification.error('An error occurred while reading your settings for "' + name + '" from your browser local storage.', 'Local storage error');
            return this.userSettings[name];
        }

    }

    clearUserSetting(name: string) {
        try {
            if ((typeof(Storage) !== 'undefined')) {
                localStorage.removeItem(name);
            } else {
                delete this.userSettings[name];
            }
        } catch (e) {
            this.notification.error('An error occurred while clearing your settings for "' + name + '" from your browser local storage.', 'Local storage error');
            delete this.userSettings[name];
        }
    }

    settingToString(type: string, value: any): string {
        switch (type) {
            case 'Number': return value.toString();
            case 'String': return value;
            case 'Array': return JSON.stringify(value);
            case 'JSON': return JSON.stringify(value);
            case 'boolean': return value.toString();
            default: return '';
        }
    }

    stringToSetting(type: string, value: string) {
        switch (type) {
            case 'Number': return Number(value);
            case 'String': return value;
            case 'Array': return JSON.parse(value);
            case 'JSON': return JSON.parse(value);
            case 'boolean': return (value === 'true');
            default: return null;
        }
    }

}