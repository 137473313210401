<mat-card class="mb-3 mr-3" *ngIf="filterQuestion.length">
  <mat-card-content>
    <div class="row">

      <div class="col">
        <mat-form-field class="mr-2" *ngFor="let question of filterQuestion" >
          <mat-label>{{question.name}}</mat-label>
          <mat-select multiple (selectionChange)="filterChange($event, question)">
            <mat-option *ngFor="let option of getQuestionOptions(question)"
                        [value]="option['name']">{{option['name']}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-2 d-flex flex-column justify-content-center">
        <button (click)="filter()"
                mat-raised-button
                color="accent"
                class="w-100">
          <mat-icon>filter_list</mat-icon>
          Filter
        </button>
      </div>

    </div>

  </mat-card-content>
</mat-card>
