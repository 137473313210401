<h2 mat-dialog-title>
  Select Occupations
  <button mat-icon-button type="button" mat-dialog-close color="primary">
    <mat-icon>close</mat-icon>
  </button>
</h2>
<mat-dialog-content class="mat-typography">
  <div class="d-flex flex-column h-100">

    <div class="row">
      <div class="col">
        <mat-card class="shadow-none">
          <mat-card-title>How to take the Compensation survey</mat-card-title>
          <mat-card-content>
            <p>Choose all of the roles under each occupation group that are applicable to your business.</p>
            <app-message
              [opened]="!!mandatoryOccupationIDs.length"
              [sublabel]="'In order to collect standardized data across companies, we have pre-selected several common job roles and ask that you provide compensation data for those roles. <br/> Please also select any other job roles that are present in your company but are not represented in the pre-selected job roles.'">
            </app-message>
          </mat-card-content>
        </mat-card>
      </div>
    </div>

    <mat-form-field class="w-100">
      <input matInput placeholder="Filter by occupation group" #industriesFilter>
    </mat-form-field>


    <div class="flex-grow-1 overflow-auto p-1">
      <mat-accordion>
        <mat-expansion-panel *ngFor="let industry of surveySection.industries | filterTitle:industriesFilter.value">
          <mat-expansion-panel-header>
            <mat-panel-title>{{industry.title}}</mat-panel-title>
            <mat-panel-description
              style="flex-grow: 0">{{industrySelectedOccupationsCount(industry)}}
              selected
            </mat-panel-description>
          </mat-expansion-panel-header>

          <ng-template matExpansionPanelContent>
            <mat-form-field class="w-100">
              <input matInput placeholder="Search occupation" #occupationFilter>
            </mat-form-field>

            <div class="industry-selector">
              <mat-list>
                <mat-list-item
                  *ngFor="let occupation of industry.occupations | filterTitle: occupationFilter.value"
                >
                  <div class="d-flex flex-row flex-grow-1 align-items-center"
                       [matTooltip]="occupationCheck.disabled ? 'It is mandatory to provide data for this occupation' : null"
                  >
                    <mat-checkbox
                        #occupationCheck
                        class="occupation-check"
                        [checked]="isOccupationRowChecked(occupation)"
                        (change)="onOccupationRowClick($event, occupationCheck, occupation)"
                        [disabled]="isMandatoryOccupation(occupation)"
                    ></mat-checkbox>

                    <div class="overflow-hidden text-nowrap text-truncate flex-shrink-1 flex-grow-1"
                         [title]="occupation.title">{{occupation.title}}</div>

                    <div class="flex-shrink-0">
                      <button mat-stroked-button [color]="'primary'" [popoverTriggerFor]="descriptionPopover">
                        Description
                      </button>
                      <stbui-popover #descriptionPopover (onOpen)="onDescpopoverOpen(occupation, descContainer)">
                        <div class="mat-card mat-elevation-z5" style="width: 350px">
                          <div class="mat-card-content" #descContainer></div>
                        </div>
                      </stbui-popover>
                    </div>
                  </div>
                </mat-list-item>
              </mat-list>
            </div>
          </ng-template>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="mt-2 mb-1">
  <button mat-raised-button color="primary" mat-dialog-close class="w-100">Done</button>
</mat-dialog-actions>

