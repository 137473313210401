import {HttpClient} from '@angular/common/http';
import {catchError, map} from "rxjs/operators";
import {MessageComponent} from "../component/message";

export abstract class BaseApiService {

  public http: HttpClient;
  private _url: string;

  public messageBox: MessageComponent;

  protected constructor() {
  }

  public get url(): string {
    return this._url;
  }

  public set url(_url) {
    this._url = _url;
  }

  public get(endpoint: string, params?: any) {
    return this.http.get(this.url + endpoint, {params: params}).pipe(
      map((r) => {
        return r
      }),
      catchError((err: any) => {
        if (this.messageBox) {
          this.messageBox.renderApiError(err);
        }
        throw err;
      })
    );
  }

  public post(endpoint: string, data: any, headers?: any) {
    return this.http.post(this.url + endpoint, data, headers).pipe(
      map((r) => {
        return r
      }),
      catchError((err: any) => {
        if (this.messageBox) {
          this.messageBox.renderApiError(err);
        }
        throw err;
      })
    );
  }

  public put(endpoint: string, data: any) {
    return this.http.put(this.url + endpoint, data).pipe(
      map((r) => {
        return r
      }),
      catchError((err: any) => {
        if (this.messageBox) {
          this.messageBox.renderApiError(err);
        }
        throw err;
      })
    );
  }

  public patch(endpoint: string, data: any) {
    return this.http.patch(this.url + endpoint, data).pipe(
      map((r) => {
        return r
      }),
      catchError((err: any) => {
        if (this.messageBox) {
          this.messageBox.renderApiError(err);
        }
        throw err;
      })
    );
  }

  public delete(endpoint: string) {
    return this.http.delete(this.url + endpoint).pipe(
      map((r) => {
        return r
      }),
      catchError((err: any) => {
        if (this.messageBox) {
          this.messageBox.renderApiError(err);
        }
        throw err;
      })
    );
  }


  public cacheSet(key: string, value: any, ttl?: number) {
    ttl = ttl || 5 * 30;
    try {
      window.localStorage.setItem('app_cache_' + key, JSON.stringify({
        value: value,
        expires_at: Date.now() + (ttl * 1000)
      }));
    } catch (e) {}
  }

  public cacheGet(key) {
    key = 'app_cache_' + key;

    let cacheItem = null;
    try {
      cacheItem = window.localStorage.getItem(key);
    } catch (e) {}

    if (!cacheItem) {
      return null;
    }

    cacheItem = JSON.parse(cacheItem);
    if (Date.now() >= cacheItem['expires_at']) return null;

    return cacheItem['value'];
  }

  public cacheDel(key) {
    try {
      window.localStorage.removeItem('app_cache_' + key);
    } catch (e) {}
  }

}
