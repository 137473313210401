import {Component, OnInit} from '@angular/core';
import {AuthApiService} from "../core/auth-api.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private auth: AuthApiService, private router: Router) {
  }

  ngOnInit() {

    this.router.navigate([this.auth.getRootPath()]);

  }
}
