import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {NavigationService} from './navigation.service';
import {AuthApiService} from "../../core/auth-api.service";
import {NavigationModel} from "./navigation.model";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {
  navigationModel: any[];
  navigationModelChangeSubscription: Subscription;
  userChangedSub: Subscription;

  constructor(private service: NavigationService, private auth: AuthApiService) {
    this.navigationModelChangeSubscription = this.service.onNavigationModelChange.subscribe(
      navigation => {
        this.navigationModel = navigation;
      }
    );

    this.userChangedSub = this.auth.onUserChange.subscribe(user => {
        this.service.setNavigationModel(new NavigationModel(this.auth));
      }
    );
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.navigationModelChangeSubscription.unsubscribe();
    this.userChangedSub.unsubscribe();
  }

  get version() {
    return environment.version;
  }

}
