<span
  *ngIf="survey"
  class="badge badge-pill"
  [ngClass]="{
    'badge-light': survey.status == 'canceled',
    'badge-success': survey.status == 'running',
    'badge-secondary': survey.status == 'planning',
    'badge-primary': survey.status == 'completed',
    'badge-warning': survey.status == 'paused'
    }">
  {{survey.status | titlecase}}
</span>

