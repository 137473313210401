import * as Tripetto from 'tripetto-collector';
import {condition, ConditionBlock, NodeBlock, tripetto} from 'tripetto-collector';
import {BlockComponentFactory} from '../../helpers/blocks/factory';
import {Component} from '@angular/core';
import {MatCheckbox} from "@angular/material";
import {DropdownOption} from "../../../editor/blocks/checkboxes/checkboxes";


@Component({
  templateUrl: './checkboxes.html',
  styleUrls: ['./checkboxes.scss']
})
export class CheckboxesBlockComponent extends BlockComponentFactory<CheckboxesBlock> {

}

@Tripetto.block({
  type: 'node',
  identifier: 'block-checkboxes',
  ref: CheckboxesBlockComponent
})
export class CheckboxesBlock extends NodeBlock {

  readonly dataSlot = Tripetto.assert(this.valueOf('data-slot'));
  readonly required = this.dataSlot.slot.required || false;

  _state = null;

  get state() {
    if(this._state) {
      return this._state;
    }
    let s = (this.dataSlot.string || this.dataSlot.value) as string;
    this._state = s.length ? JSON.parse(s as string) : {};
    return this._state;
  };

  set state(value) {
    this._state = value;

    for (let stateKey in this._state) {
      if (!this._state.hasOwnProperty(stateKey)) continue;

      let item = this._state[stateKey];
      if (!item) {
        delete this._state[stateKey];
      }
    }

    let slotValue = Object.keys(this._state).length ? JSON.stringify(this._state) : '';
    this.dataSlot.set(slotValue);
  }

  isChecked(checkbox) {
    return this.state && this.state[checkbox.name];
  }

  toggle(ref: MatCheckbox, checkbox) {
    let s = this.state;
    s[checkbox.name] = ref.checked;
    this.state = s;
  }


}


@tripetto({
  type: "condition",
  identifier: 'block-checkboxes'
})
export class CheckboxesCondition extends ConditionBlock<{
  checkbox: string;
}> {


  @condition
  validate(): boolean {
    const dataSlot = this.valueOf('data-slot');
    if (!dataSlot || !dataSlot.string.length) return false;

    const data = JSON.parse(dataSlot.string);
    const block: any = this.node.block;
    const checkboxName = block.checkboxes.filter((c: DropdownOption) => c.id == this.props.checkbox)[0].name;

    if (!data || !block || !checkboxName) {
      return false;
    }

    return data.hasOwnProperty(checkboxName) && data[checkboxName];
  }
}

