<div class="signin-container" fxLayout="row">
  <div class="signin-cover" fxFlex="100" fxFlex.gt-xs="50">
    <div fxLayout="column" fxLayoutAlign="center center" style="height: 100%;">
      <img src="assets/images/sensiblesurveyslogo.png" alt="logo" style="max-width: 65%;opacity: 0.8;">
    </div>
  </div>
  <div class="signin-content mat-elevation-z12" fxFlex="100" fxFlex.gt-xs="50">

    <mat-card-title>
      <app-message
        #message
        [opened]="false"
      >
        <button app-message-actions mat-icon-button (click)="message.close()">
          <mat-icon>cancel</mat-icon>
        </button>
      </app-message>
    </mat-card-title>


    <mat-card-title> Reset Your Password</mat-card-title>

    <form (ngSubmit)="passwordReset()">
      <mat-form-field class="display-block">
        <input [(ngModel)]="email" name="email" type="email" placeholder="Please enter your email address" required
               matInput>
        <!-- <mat-error *ngIf="formErrors.email">{{formErrors.email}}</mat-error> -->
      </mat-form-field>

      <mat-form-field class="display-block">
        <input [(ngModel)]="password" name="password" type="password" placeholder="please enter your password" required
               matInput autocomplete="new-password">
        <!-- <mat-error *ngIf="formErrors.password">{{formErrors.password}}</mat-error> -->
      </mat-form-field>

      <mat-form-field class="display-block">
        <input [(ngModel)]="cpassword" name="password_confirmation" type="password"
               placeholder="please confirm your password" required matInput autocomplete="new-password">
        <!-- <mat-error *ngIf="formErrors.password">{{formErrors.password}}</mat-error> -->
      </mat-form-field>

      <button mat-raised-button color="primary" type="submit">Reset My Password</button>
      <a class="redirect" [routerLink]="['/signin']">Sign In ?</a>

    </form>

  </div>

</div>
