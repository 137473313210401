import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {EditorModule} from "./editor/editor.module";
import {CollectorModule} from "./collector/collector.module";
import {CollectorComponent} from "./collector/collector.component";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EditorModule,
    CollectorModule
  ],
  exports:[
    EditorModule,
    CollectorModule
  ],
  entryComponents:[
    CollectorComponent
  ]

})
export class TripettoModule {
}
