import {Component, OnInit} from '@angular/core';
import {AuthApiService} from "../../../core/auth-api.service";
import {ED} from "../../../model/common";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  userRoles = [];
  ed = null;

  constructor(private authApi: AuthApiService) {
  }

  ngOnInit() {
    this.userRoles = this.authApi.userUserRoles;
    this.ed = this.authApi.user.ed || <ED>{};
  }

}
