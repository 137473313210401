<h2 mat-dialog-title>
    New User Info
    <button mat-icon-button type="button" color="primary" (click)="cancelClick()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content>
    <app-message #message [opened]="false" class="mat-typography">
        <button app-message-actions mat-icon-button (click)="message.close()">
            <mat-icon>cancel</mat-icon>
        </button>
    </app-message>
    <form [formGroup]="userForm" (ngSubmit)="save()" (keydown.enter)="$event.preventDefault()">
        <div class="form-row">
            <div class="col-md-6">
                <mat-form-field class="w-100">
                    <input formControlName="first_name" matInput placeholder="First Name">
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="w-100">
                    <input formControlName="last_name" matInput placeholder="Last Name" >
                </mat-form-field>
            </div>
        </div>
        <div class="form-row">
            <div class="col">
                <mat-form-field class="w-100">
                    <input formControlName="email" matInput placeholder="E-Mail" >
                </mat-form-field>
            </div>
        </div>
        <div class="form-row">
            <div class="col">
                <div formGroupName="business">
                    <mat-form-field class="w-100">
                        <input #businessName formControlName="name" matInput placeholder="Business"
                               [matAutocomplete]="autoBusiness"/>
                        <mat-icon *ngIf="!businessName.disabled" matSuffix
                                  matTooltip="Select from the list or type in new one">
                            help_outline
                        </mat-icon>
                        <mat-autocomplete #autoBusiness="matAutocomplete"
                                          (optionSelected)="onAutoComplete($event)"
                        >
                            <mat-option *ngFor="let option of businesses | filterEd:businessName.value" [value]="option">
                                {{option.name}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button class="mat-primary" [disabled]="!userForm.valid" (click)="save()">Add User</button>
</mat-dialog-actions>
