<mat-table style="display: none">
  <mat-header-row *matHeaderRowDef="[]"></mat-header-row>
</mat-table>
<section *ngIf="status !== 'uninitialized'">
  <!-- Form finished -->
  <mat-card *ngIf="status === 'finished'">
    <mat-card-title>✔ You’ve completed the form</mat-card-title>
    <p>For the purpose of this demo the form output is visible in your browser’s developer console. Go there to see the
      collected data.</p>
  </mat-card>

  <!-- Form stopped -->
  <mat-card *ngIf="status === 'stopped'">
    <mat-card-title>⏹ You’ve stopped the form</mat-card-title>
    <p>Press the play icon to start a new session.</p>
  </mat-card>

  <!-- Form paused -->
  <mat-card *ngIf="status === 'paused'">
    <mat-card-title>⏸ You’ve paused the form</mat-card-title>
    <p>
      For the purpose of this demo the paused form is saved in your browser’s local store. Refresh the browser to resume
      the paused form.
    </p>
  </mat-card>

  <!-- Form not started -->
  <mat-card *ngIf="status === 'ready'">
    <mat-card-title>⏹ You haven’t started the form yet</mat-card-title>
    <p>Press the play icon to start a new session.</p>
  </mat-card>

  <tripetto-block [node]="node" [collector]="this" *ngFor="let node of storyline.activeNodes"></tripetto-block>

  <!-- Form is empty -->
  <mat-card *ngIf="isEmpty">
    <mat-card-title>👋 Nothing to show here yet</mat-card-title>
    <p>Add blocks to the form first to get the magic going.</p>
  </mat-card>

  <!-- Form is evaluating -->
  <mat-card *ngIf="isEvaluating"><p>⏳ Please wait...</p></mat-card>

  <!-- Inline buttons -->
  <!--<mat-toolbar *ngIf="status === 'running'" class="footer">-->
    <!--<mat-toolbar-row *ngIf="progressbar"-->
    <!--&gt;-->
      <!--<tripetto-collector-progressbar [storyline]="collector.storyline"-->
                                      <!--style="width: 100%;"></tripetto-collector-progressbar-->
      <!--&gt;-->
    <!--</mat-toolbar-row>-->
    <!--<mat-toolbar-row *ngIf="buttons">-->
      <!--<tripetto-collector-buttons [storyline]="collector.storyline"></tripetto-collector-buttons>-->
    <!--</mat-toolbar-row>-->
    <!--<mat-toolbar-row *ngIf="mode === 'paginated' && pages"-->
    <!--&gt;-->
      <!--<tripetto-collector-pages [storyline]="collector.storyline"></tripetto-collector-pages-->
      <!--&gt;-->
    <!--</mat-toolbar-row>-->
  <!--</mat-toolbar>-->
</section>
