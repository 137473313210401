import {Component, OnInit, Input, SimpleChanges, SecurityContext} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {map} from "rxjs/internal/operators";

@Component({
    selector: 'app-ed-logo',
    templateUrl: './ed-logo.component.html',
    styleUrls: ['./ed-logo.component.scss']
})
export class EdLogoComponent implements OnInit {

    @Input() logoPath: string = null;
    logoImage: boolean = false;
    logoHtml: boolean = false;
    innerHtml: SafeHtml = null;

    readonly htmlFileExtensions = ['htm', 'html'];
    readonly imgFileExtensions = ['jpg', 'jpeg', 'png', 'gif'];

    constructor(
        private httpClient: HttpClient,
        private domSanitizer: DomSanitizer,
    ) {}

    ngOnInit() {
        this.parseLogoUrl();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.parseLogoUrl();
    }

    parseLogoUrl() {
        if (!this.logoPath) {
            return;
        }
        this.logoImage = false;
        this.logoHtml = false;
        const extension = this.logoPath.substr(this.logoPath.lastIndexOf('.') + 1);

        if (this.htmlFileExtensions.indexOf(extension) > -1) {
            this.logoHtml = true;
            this.getStaticHtml().subscribe(
                response => {
                    this.innerHtml = this.domSanitizer.bypassSecurityTrustHtml(response);
                }
            );
        }
        if (this.imgFileExtensions.indexOf(extension) > -1) {
            this.logoImage = true;
        }
    }

    getStaticHtml(): Observable<string> {
        return this.httpClient.get(this.logoPath, {
          responseType: 'text'
        }).pipe(
          map(response => this.mapStaticHtml(response))
        );
      }
    
    private mapStaticHtml(htmlString: string): string {
        const str = this.domSanitizer.sanitize(SecurityContext.HTML, htmlString); 
        return htmlString; // str; // sanitized, but inline styles removed
    }

  }
  