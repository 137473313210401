import {Directive, ElementRef, HostListener} from "@angular/core";

@Directive({
  selector: '[moneyAmount]'
})
export class MoneyAmountDirective {
  // Allow decimal numbers and negative values
  private regex: RegExp = new RegExp(/^([0-9],?)*([0-9]?)+(\.[0-9]*)?$/g);


  constructor(private el: ElementRef) {

  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (
      // Allow: Delete, Backspace, Tab, Escape, Enter
      [46, 8, 9, 27, 13].indexOf(event.keyCode) !== -1 ||
      (event.keyCode === 65 && event.ctrlKey === true) || // Allow: Ctrl+A
      (event.keyCode === 67 && event.ctrlKey === true) || // Allow: Ctrl+C
      (event.keyCode === 86 && event.ctrlKey === true) || // Allow: Ctrl+V
      (event.keyCode === 88 && event.ctrlKey === true) || // Allow: Ctrl+X
      (event.keyCode === 65 && event.metaKey === true) || // Cmd+A (Mac)
      (event.keyCode === 67 && event.metaKey === true) || // Cmd+C (Mac)
      (event.keyCode === 86 && event.metaKey === true) || // Cmd+V (Mac)
      (event.keyCode === 88 && event.metaKey === true) || // Cmd+X (Mac)
      (event.keyCode >= 35 && event.keyCode <= 39) // Home, End, Left, Right
    ) {
      return;  // let it happen, don't do anything
    }
    let current: string = this.el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event) {
    event.preventDefault();

    let pastedInput: string = null;
    if (window['clipboardData']) { // IE
      pastedInput = window['clipboardData'];
    } else if (event.clipboardData && event.clipboardData.getData) { // other browsers
      pastedInput = event.clipboardData.getData('text/plain');
    }
    // let current: string = this.el.nativeElement.value;
    // let next: string = current.concat(event.key);

    let current: string = this.el.nativeElement.value;
    let next: string = current.concat(pastedInput);
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
      return;
    }

    document.execCommand('insertText', false, pastedInput);
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
    const textData = event.dataTransfer.getData('text');

    let current: string = this.el.nativeElement.value;
    let next: string = current.concat(textData);
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
      return;
    }

    this.el.nativeElement.focus();
    document.execCommand('insertText', false, textData);
  }

}
