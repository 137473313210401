<mat-card>
  <mat-card-title
    *ngIf="name"
    markdown
    [content]="name"
    [context]="context"
    [enumerator]="enumerator"
    [required]="block.required"
  ></mat-card-title>
  <mat-card-content *ngIf="description" markdown [content]="description" [context]="context"></mat-card-content>
  <div (click)="block.exampleSlot.set('A nice value!')" style="color: red;">
    This is an example block with an example data slot that can be set. If the block is required, the validation will pass as soon as the
    value is set. <br />
    Current value of example slot: <b>{{block.exampleSlot.string || 'Not set'}}</b> (click here to set a value)
  </div>
  <mat-card-footer *ngIf="explanation" markdown [content]="explanation" [context]="context"></mat-card-footer>
</mat-card>
