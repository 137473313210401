import {Component, OnInit, ViewChild} from '@angular/core';
import {map} from "rxjs/operators";
import {EdService} from "../../../../core/ed.service";
import {AuthApiService} from "../../../../core/auth-api.service";
import {MessageComponent} from "../../../../component/message";
import {Survey, SurveyResponse} from "../../../../model/common";

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss']
})
export class UserDashboardComponent implements OnInit {


  @ViewChild('message') message: MessageComponent;

  dashboardDS;
  dashboardData;

  surveyResponses: SurveyResponse[];
  surveys: Survey[];

  constructor(private edService: EdService, private auth: AuthApiService) {
  }

  ngOnInit() {
    this.dashboardDS = this.edService.userDashboard().subscribe(r => {
      this.dashboardData = r;

      if (this.dashboardData['survey_responses']) {
        this.surveyResponses = <SurveyResponse[]> this.dashboardData['survey_responses'];
      }

      if (this.dashboardData['surveys']) {
        this.surveys = <Survey[]> this.dashboardData['surveys'];
      }

      return r;
    }, e => {
      this.message.renderApiError(e);
    });

  }

}
