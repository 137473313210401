import {NgModule} from '@angular/core';
import {RouterModule, Routes, ExtraOptions} from '@angular/router';

import {AdminComponent} from './admin/admin.component';
import {SigninComponent} from './pages/signin/signin.component';
import {AdminGuard, AuthGuard, EDPathGuard} from './app.guard';
import {HomeComponent} from "./home/home.component";
import {NotFoundComponent} from "./pages/not-found/not-found.component";
import {ResetComponent} from "./pages/forgot-password/reset/reset.component";
import {WelcomeComponent} from "./pages/welcome/welcome.component";
import {HelpComponent} from "./pages/help/help.component";

const routes: Routes = [
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: 'signin', component: SigninComponent},
  {path: 'signin/:ed-path', component: SigninComponent},
  // {path: 'signup', component: SignupComponent},
  {path: 'reset-password/:token', component: ResetComponent},
  {path: 'welcome/:tokens', component: WelcomeComponent},
  {path: '404', component: NotFoundComponent},
  {
    path: '',
    component: AdminComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'admin',
        canActivateChild: [AdminGuard],
        loadChildren: './pages/admin/admin.module#AdminModule'
      },
      {
        path: 'profile',
        loadChildren: './pages/profile/profile.module#ProfileModule'
      },
      {
        path: 'help',
        component: HelpComponent
      },
      {
        path: ':ed-path',
        canActivateChild: [EDPathGuard],
        loadChildren: './pages/ed/ed.module#EDModule'
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
