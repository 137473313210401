import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SurveyResults, SurveyResultsQuestion} from "../../../../../model/common";

@Component({
  selector: 'app-survey-results-filters',
  templateUrl: './survey-results-filters.component.html',
  styleUrls: ['./survey-results-filters.component.scss']
})
export class SurveyResultsFiltersComponent implements OnInit {

  @Input('surveyResults') surveyResults: SurveyResults;
  @Input('filters') filters: string[];
  @Output() onFilter = new EventEmitter<any>();

  filterQuestion: SurveyResultsQuestion[] = [];
  filterState = {};

  constructor() {
  }

  ngOnInit() {
    this.init();
  }

  init() {
    if (!this.filters || !this.filters.length) { return; }
    this.filterQuestion = [];

    this.surveyResults.sections.forEach(section => {
      if (section['is_compensation_survey']) {
        return;
      }
      section.questions.forEach(question => {
        if (this.filters.filter((f) => f == question['id']).length) {
          this.filterQuestion.push(question);
        }
      });
    });
  }

  getQuestionOptions(question: SurveyResultsQuestion) {
    if (question.block.hasOwnProperty('options')) {
      return question.block.options;
    }

    if (question.block.hasOwnProperty('buttons')) {
      return question.block.buttons;
    }
  }

  filterChange($event, question) {
    if ($event.value.length) {
      this.filterState[question.id] = $event.value;
    } else {
      delete this.filterState[question.id];
    }
  }

  filter() {
    this.onFilter.emit(this.filterState);
  }
}
