<div class="mat-card mat-typography tripetto-matrix-radiobuttons">

  <div class="mat-card-title" *ngIf="name" markdown [content]="name" [context]="context" [enumerator]="enumerator"
       [required]="!placeholder && block.required"></div>
  <div class="mat-card-content" *ngIf="description" markdown [content]="description" [context]="context"></div>

  <div class="mat-card-content">
    <table class="mat-table w100">
      <thead>
      <tr class="mat-header-row">
        <th class="mat-header-cell">&nbsp;</th>
        <th class="mat-header-cell" *ngFor="let c of block.props.columns">{{c.name}}</th>
      </tr>
      </thead>
      <tbody>
      <tr class="mat-row" *ngFor="let row of block.props.rows">
        <th class="mat-header-cell mat-header-cell-column">{{row.name}}</th>
        <td class="mat-cell text-cell" *ngFor="let col of block.props.columns">

          <mat-form-field>
            <span matPrefix *ngIf="block.props.prefix">{{block.props.prefix}}</span>
            <input #ref
                   moneyAmount
                   matInput
                   type="text"
                   [name]="block.dataSlot.key+'_'+row.id"
                   [value]="block.value(col, row)"
                   (input)="block.change(ref, col, row)"
                   (focus)="block.onFocus(ref, col, row)"
                   (blur)="block.onBlur(ref, col, row)"
            />
            <span matSuffix *ngIf="block.props.suffix">{{block.props.suffix}}</span>
          </mat-form-field>

        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="mat-card-footer" *ngIf="explanation" markdown [content]="explanation" [context]="context"></div>
</div>

