<mat-card class="mat-typography">
  <mat-card-title
    *ngIf="name"
    markdown
    [content]="name"
    [context]="context"
    [enumerator]="enumerator"
    [required]="!placeholder && block.required"
  ></mat-card-title>
  <mat-card-content *ngIf="description" markdown [content]="description" [context]="context"></mat-card-content>
  <mat-form-field class="form-field-full-width">
    <textarea
      #ref
      matTextareaAutosize
      matAutosizeMinRows="3"
      matInput
      [required]="block.required"
      [placeholder]="placeholder"
      [value]="block.textareaSlot.string"
      [(ngModel)]="block.textareaSlot.value"
      (blur)="block.onBlur(ref)"
    ></textarea>
  </mat-form-field>
  <mat-card-footer *ngIf="explanation" markdown [content]="explanation" [context]="context"></mat-card-footer>
</mat-card>
