import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseApiService} from './base-api.service';
import {AuthApiService} from "./auth-api.service";
import {ConfigService} from "./config.service";

@Injectable()
export class PublicService extends BaseApiService {

  constructor(public http: HttpClient, private auth: AuthApiService, private configService: ConfigService) {
    super();
  }

  public get url(): string {
    return this.configService.apiEndpoint + '/api/public';
  }

  publicEDInfo(path: string) {
    return this.get(`/ed/${path}/public-info`, path);
  }

}
