import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseApiService} from './base-api.service';
import {AuthApiService} from "./auth-api.service";
import {ConfigService} from "./config.service";

@Injectable()
export class IndustriesService extends BaseApiService {

  getOccupationCache = {};

  get edPath(): String {
    return this.auth.user.ed.path;
  };

  constructor(public http: HttpClient, private auth: AuthApiService, public configService: ConfigService) {
    super();
  }

  public get url(): string {
    return this.configService.apiEndpoint + `/api/ed/${this.edPath}/industries-and-occupations`;
  }

  all() {
    return this.get('/');
  }

  public getOccupation(occupationID) {
    return this.get(`/occupation/${occupationID}`);
  }

}
