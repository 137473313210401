import {AfterViewInit, Component, OnDestroy} from '@angular/core';
import {LoadingService} from "./core/loading.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy, AfterViewInit {
  public loading = false;

  onRequestStartSub;
  onRequestEndSub;

  constructor(private loadingService: LoadingService) {
  }

  ngAfterViewInit(): void {
    this.onRequestStartSub = this.loadingService.onRequestStart.subscribe(() => {
      setTimeout(() => {
        this.loading = true
      });

    });
    this.onRequestEndSub = this.loadingService.onRequestEnd.subscribe(() => {
      setTimeout(() => {
        this.loading = false
      });

    });
  }

  ngOnDestroy(): void {
    this.onRequestStartSub.unsubscribe();
    this.onRequestEndSub.unsubscribe();
  }


}
