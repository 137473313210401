import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseApiService} from './base-api.service';
import {SurveyDefinition} from "../model/common";
import {ConfigService} from "./config.service";

@Injectable()
export class SurveyDefinitionService extends BaseApiService {

  constructor(public http: HttpClient, public configService: ConfigService) {
    super();
  }

  public get url(): string {
    return this.configService.apiEndpoint + '/api/survey-definitions';
  }

  all(page: number) {
    return this.get('/templates', {page: page});
  }

  save(surveyDefinition) {
    return this.post('/', surveyDefinition);
  }

  delDefiniton(surveyDefinition: SurveyDefinition) {
    return this.delete(`/${surveyDefinition.id}`);
  }

  toggleIsLive(surveyDefinition: SurveyDefinition) {
    return this.post(`/${surveyDefinition.id}/toggle-live`, {});
  }
}
