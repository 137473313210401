import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseApiService} from './base-api.service';
import {SurveyResponse, User} from '../model/common';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SurveyService} from "./survey.service";
import {ConfigService} from "./config.service";

@Injectable()
export class UserService extends BaseApiService {

  constructor(public http: HttpClient, public configService: ConfigService) {
    super();
  }

  public get url(): string {
    return this.configService.apiEndpoint + '/api/users';
  }

  me() {
    return this.get('/me');
  }

  getUser(id: number) {
    return this.get(`/${id}`);
  }

  all(page: number, size: number = 50, filters: {} | null = null) {
    let params = {page: page, page_size: size};

    if (filters) {
      for (let [key, value] of Object.entries(filters)) {
        if (value) {
          params[key] = value;
        }
      }
    }

    return this.get('/', params);
  }

  updateProfile(user: User): Observable<any> {
    return this.post('/update-profile', user).pipe(
      map((r) => {
          return r;
        }
      )
    );
  }

  editUser(user) {
    return this.post('/' + (user['id'] > 0 ? user['id'] : ''), user);
  }

  deleteUser(id) {
    return this.delete(`/${id}`);
  }

  resetPassword(id) {
    return this.post(`/${id}/reset-password`, {});
  }

  surveyResponses(id) {
    return this.get(`/${id}/survey-responses`).pipe(map((r: SurveyResponse[]) => {
      return r.map(row => {
        row['status'] = SurveyService.getSurveyResponseStatus(<SurveyResponse>row);
        return row;
      });
    }));
  }

  removeUserFromSurvey(userId, surveyResponse) {
    return this.post(`/${userId}/remove-from-survey`, surveyResponse);
  }
}
