<mat-card class="mat-typography">
  <mat-card-title *ngIf="name" markdown [content]="name" [context]="context" [enumerator]="enumerator" [required]="!placeholder && block.required"></mat-card-title>
  <mat-card-content *ngIf="description" markdown [content]="description" [context]="context"></mat-card-content>
  <section class="checkboxes">
    <mat-checkbox #ref *ngFor="let checkbox of block.props.checkboxes" [checked]="block.isChecked(checkbox)"
                  (change)="block.toggle(ref, checkbox)">
      <span markdown [content]="checkbox.name || '...'" [context]="context" lineBreaks="false"></span>
    </mat-checkbox>
  </section>
  <mat-card-footer *ngIf="explanation" markdown [content]="explanation" [context]="context"></mat-card-footer>
</mat-card>
