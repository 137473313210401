<h2 mat-dialog-title>Edit Info</h2>
<mat-dialog-content class="mat-typography">
  <form class="example-form">
    
    <mat-form-field class="example-full-width">
      <input matInput placeholder="First Name" name="first_name" [(ngModel)] = "updatedUser.first_name">
    </mat-form-field>
    <div class="errors" *ngIf="errors">
      <div class="errors" *ngIf="errors.first_name">{{errors.first_name[0]}}</div>
    </div>

    <mat-form-field class="example-full-width">
      <input matInput placeholder="Last Name" name="last_name" [(ngModel)] = "updatedUser.last_name">
    </mat-form-field>
    <div class="errors" *ngIf="errors">
      <div class="errors" *ngIf="errors.last_name">{{errors.last_name[0]}}</div>
    </div>

    <mat-form-field class="example-full-width">
      <input matInput placeholder="Email Address" disabled [value]="user.email">
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input type="password" matInput placeholder="New Password" name="password" [(ngModel)] = "updatedUser.password">
    </mat-form-field>

  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button cdkFocusInitial (click)="updateProfile()">Update</button>
</mat-dialog-actions>