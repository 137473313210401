import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, Injectable, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import 'hammerjs';
import {ReactiveFormsModule} from '@angular/forms';

import {SharedModule} from './shared/shared.module';
import {CoreModule} from './core/core.module';

import {AppRoutingModule} from './app.routing';
import {AdminModule} from './admin/admin.module';
import {AppComponent} from './app.component';
import {EDModule} from './pages/ed/ed.module';


import {SignupComponent} from './pages/signup/signup.component';
import {SigninComponent} from './pages/signin/signin.component';

import {MAT_DATE_LOCALE, MatDialogModule} from "@angular/material";

import {AdminGuard, AuthGuard, EDPathGuard} from './app.guard';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthenticationInterceptor} from './core/authentication.interceptor';
import {HomeComponent} from './home/home.component';
import {NotFoundComponent} from "./pages/not-found/not-found.component";
import {ResetComponent} from "./pages/forgot-password/reset/reset.component";
import {WelcomeComponent} from "./pages/welcome/welcome.component";
import {HelpComponent} from "./pages/help/help.component";

import * as Sentry from "@sentry/browser";

Sentry.init({
  enabled: environment.production,
  environment: environment.production ? 'prod' : 'dev',
  release: environment.version,
  dsn: "https://46dfbece8e054513aaea699493b622af@sentry.io/1499311"
});


@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {
  }

  handleError(error) {
    const eventId = Sentry.captureException(error.originalError || error);

    console.error(error, error);

    // Sentry.showReportDialog({eventId});
  }
}


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SignupComponent,
    SigninComponent,
    HomeComponent,
    NotFoundComponent,
    ResetComponent,
    WelcomeComponent,
    HelpComponent,
  ],
  imports: [
    BrowserModule,
    MatDialogModule,
    BrowserAnimationsModule,
    SharedModule,
    CoreModule.forRoot(),
    AdminModule,
    AppRoutingModule,
    ReactiveFormsModule,
    EDModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    })
  ],
  entryComponents: [
    HelpComponent
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
    {provide: MAT_DATE_LOCALE, useValue: 'en-US'},
    AuthGuard,
    AdminGuard,
    EDPathGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
