
<div  fxLayout="column" fxLayoutAlign="center center" >

  <div fxFlex class="mat-typography text-center">
    <h1>
      404 Not Found
    </h1>

    <a routerLink="/">Go back</a>
  </div>


</div>

