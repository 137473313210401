import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import {User} from '../../model/common';
import {AuthApiService} from "../../core/auth-api.service";
import {UserService} from "../../core/user.service";
import {NotificationService} from "../../component/notification/index";

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {

  user: User;
  errors: {};
  updatedUser = {
    first_name: '',
    last_name: '',
    password: ''
  };

  constructor(public dialog: MatDialog,
              private auth: AuthApiService,
              private userService: UserService,
              private notification: NotificationService) {
    this.user = auth.user;
    this.updatedUser.first_name = this.user.first_name;
    this.updatedUser.last_name = this.user.last_name;
  }

  ngOnInit() {
  }

  updateProfile() {
    this.userService.updateProfile(this.updatedUser).subscribe((response) => {
      if (response.updated) {
        this.notification.success('Your profile was updated successfully', 'Success');
        this.dialog.closeAll();
        this.errors = [];
      } else {
        this.notification.error('Something went wrong. Please try again later', 'Oops');
      }
    }, (e) => {
      this.errors = e.error.errors;
      // console.log(this.errors);
    });
  }

}
