import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialogRef} from "@angular/material";
import {MessageComponent} from "../../../../../component/message";
import {Survey} from "../../../../../model/common";
import {SurveyService} from "../../../../../core/survey.service";

@Component({
  selector: 'app-edit-survey-details',
  templateUrl: './edit-survey-details.component.html',
  styleUrls: ['./edit-survey-details.component.scss']
})
export class EditSurveyDetailsComponent implements OnInit {

  @ViewChild('message') messageBox: MessageComponent;
  @ViewChild('saveBtn') saveBtn: HTMLButtonElement;

  @Input() survey: Survey;

  tmpSurvey;

  @Output() onSaved = new EventEmitter();

  constructor(public dialogRef: MatDialogRef<EditSurveyDetailsComponent>, private surveyService: SurveyService) {
  }

  ngOnInit() {
  }

  onFormChange($event) {
    this.saveBtn.disabled = !$event['valid'];
    this.tmpSurvey = $event['value'];
  }

  save() {
    this.messageBox.renderApiError();
    this.surveyService.updateInfo(this.survey.id, this.tmpSurvey).subscribe(r => {
        this.messageBox.renderSuccess();
        this.onSaved.emit();
      },
      e => {
        this.messageBox.renderApiError(e);
      });
  }

  cancel() {
    this.dialogRef.close();
  }
}
