import {EventEmitter, Injectable} from '@angular/core';
import {TakeSurveyComponent} from "../pages/ed/survey/take-survey/take-survey.component";
import {take} from "rxjs/operators";
import {SurveyDefinitionService} from "../core/survey-definition.service";
import {DialogService} from "../component/dialog";
import {Survey, User} from "../model/common";
import {SurveyService} from "../core/survey.service";
import {UserFormComponent} from "./user-form/user-form.component";
import {UserService} from "../core/user.service";
import {Observable} from "rxjs";
import {HelpComponent} from "../pages/help/help.component";

@Injectable()
export class UtilityService {

  constructor(private surveyDefinitionService: SurveyDefinitionService, private dialog: DialogService, private surveyService: SurveyService, private userService: UserService) {

  }


  public static isEmptyObject(obj: Object) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }


  public static chartColors = [
    {
      backgroundColor: [
        '#bf9d76', '#e99450', '#d89f59', '#f2dfa7', '#a5d7c6', '#7794b1', '#afafaf', '#707160', '#ba9383', '#d9d5c3'
      ]
    },
  ];

  public static ngxChartColors = {
    domain: ['#bf9d76', '#e99450', '#d89f59', '#f2dfa7', '#a5d7c6', '#7794b1', '#afafaf', '#707160', '#ba9383', '#d9d5c3']
  };

  previewSurvey(surveyID: number) {

    const dialogRef = this.dialog.open(TakeSurveyComponent, {
      panelClass: 'dialog-lg',
    });

    dialogRef.componentInstance.readOnly = true;
    dialogRef.componentInstance.isModal = true;

    this.surveyService.getSurvey(surveyID).subscribe(r => {

      let survey = <Survey>r;
      dialogRef.componentInstance.initFromSurveyResponse({
        survey: {
          title: survey.title,
          survey_definition: survey.survey_definition
        }
      });

    }, e => {
      dialogRef.componentInstance.message.renderApiError(e);
    });


    dialogRef.componentInstance.onClose.pipe(take(1)).subscribe(() => {
      dialogRef.close();
    });

  }

  editUser(user: User, userRoles?: any[]): Observable<any> {

    let result = new EventEmitter();

    const dialogRef = this.dialog.open(UserFormComponent, {
      disableClose: true,
      panelClass: 'dialog-md'
    });

    const component = dialogRef.componentInstance;

    if (userRoles) {
      component.userRoles = userRoles;
    }

    if (user && user.id) {
      this.userService.getUser(user.id).subscribe(
        r => {
          component.user = <User>r;
        },
        e => {
          component.messageBox.renderApiError(e);
        }
      );
    } else {
      component.user = <User>{};
    }

    component.onSave.subscribe(() => {
      result.next();
    });

    component.onClose.subscribe(() => {
      result.next();
      dialogRef.close();
    });

    return result;
  }

  showHelpModal() {
    const dialogRef = this.dialog.open(HelpComponent, {});
  }

}
