<div class="mat-card mat-typography tripetto-matrix-dropdown">

  <div class="mat-card-title" *ngIf="name" markdown [content]="name" [context]="context"
       [enumerator]="enumerator" [required]="!placeholder && block.required"></div>
  <div class="mat-card-content" *ngIf="description" markdown [content]="description" [context]="context"></div>

  <div class="mat-card-content">
    <table class="mat-table w100">
      <thead>
      <tr class="mat-header-row">
        <th class="mat-header-cell">&nbsp;</th>
        <th class="mat-header-cell" *ngFor="let c of block.props.columns">{{c.name}}</th>
      </tr>
      </thead>
      <tbody>
      <tr class="mat-row" *ngFor="let row of block.props.rows">
        <th class="mat-header-cell mat-header-cell-column">{{row.name}}</th>
        <td class="mat-cell pl-4 pr-4" *ngFor="let col of block.props.columns">
          <mat-select #ref (valueChange)="block.change(ref, col, row)" [value]="block.getSelectValue(col, row)">
            <mat-option [value]="null"></mat-option>
            <mat-option *ngFor="let opt of block.props.dropdownOptions" [value]="opt.name">
              {{opt.name}}
            </mat-option>
          </mat-select>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="mat-card-footer" *ngIf="explanation" markdown [content]="explanation" [context]="context"></div>
</div>
