import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Survey} from "../../../../model/common";

@Component({
  selector: 'app-view-survey',
  templateUrl: './view-survey.component.html',
  styleUrls: ['./view-survey.component.scss']
})
export class ViewSurveyComponent implements OnInit {

  @Input() survey: Survey;
  @Input() isModal = true;
  @Output() onClose = new EventEmitter();
  @Output() onChange = new EventEmitter();


  constructor() { }

  ngOnInit() {
  }

  onSurveyChanged() {
    this.onChange.emit();
  }

  close() {
    this.onClose.emit();
  }
}
