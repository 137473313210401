import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MaterialComponentsModule} from './material.module';
import {MessageModule} from "../component/message";
import {NotificaitonModule} from "../component/notification";
import {PopoverModule} from "../component/popover";
import {MoneyAmountDirective} from "../core/money-amount.directive";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    MaterialComponentsModule,
    MessageModule,
    NotificaitonModule,
    PopoverModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    MaterialComponentsModule,
    MessageModule,
    NotificaitonModule,
    PopoverModule,
    MoneyAmountDirective
  ],
  declarations: [
    MoneyAmountDirective
  ]
})
export class SharedModule {
}
