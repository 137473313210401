import * as Tripetto from 'tripetto-collector';
import {NodeBlock} from 'tripetto-collector';
import {BlockComponentFactory} from '../../helpers/blocks/factory';
import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {MatSelect} from "@angular/material";

@Component({
  templateUrl: './matrix-dropdowns.html',
  styleUrls: ['./matrix-dropdowns.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MatrixDropdownsBlockComponent extends BlockComponentFactory<MatrixDropdownsBlock> {


}

@Tripetto.block({
  type: 'node',
  identifier: 'block-matrix-dropdowns',
  ref: MatrixDropdownsBlockComponent
})
export class MatrixDropdownsBlock extends NodeBlock {

  readonly dataSlot = Tripetto.assert(this.valueOf('data-slot'));
  readonly required = this.dataSlot.slot.required || false;

  _state = null;

  get state() {
    if(this._state) {
      return this._state;
    }
    let s = (this.dataSlot.string || this.dataSlot.value) as string;
    this._state = s.length ? JSON.parse(s as string) : {};
    return this._state;
  };

  set state(value) {
    this._state = value;
    this.dataSlot.set(JSON.stringify(this._state));
  }

  getSelectValue(col, row) {
    return this.state && this.state[row.name] && this.state[row.name][col.name] ? this.state[row.name][col.name] : null;
  }

  change(ref: MatSelect, col, row) {
    let s = this.state;

    s[row.name] = s[row.name] || {};
    s[row.name][col.name] = s[row.name][col.name] || {};
    s[row.name][col.name] = ref.value;

    this.state = s;

    this.dataSlot.set(JSON.stringify(s));
  }


}
