<h2 mat-dialog-title class="mb-2">
  Update Survey Information
  <button mat-icon-button type="button" color="primary" (click)="cancel()">
    <mat-icon>close</mat-icon>
  </button>
</h2>

<mat-dialog-content class="mat-typography">
  <app-message #message [opened]="false" class="mat-typography">
    <button app-message-actions mat-icon-button (click)="message.close()">
      <mat-icon>cancel</mat-icon>
    </button>
  </app-message>
  <app-step-survey-details-form [survey]="survey" (onFormChange)="onFormChange($event)"></app-step-survey-details-form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button class="mat-primary" (click)="save()" [disabled]="true" #saveBtn><mat-icon>save</mat-icon> Save</button>
</mat-dialog-actions>
