<div class="toolbar-user-container">
  <button mat-button (click)="toggleDropdown()" class="toolbar-user-btn" [class.open]="isOpen">
    <span fxLayout="row" fxLayoutAlign="start center">
      <img class="avatar" [src]="'assets/images/avatars/noavatar.png'">
      <span class="name" fxHide fxShow.gt-sm>{{currentUser?.first_name}} {{currentUser?.last_name}}</span>
      <mat-icon class="icon" fxHide fxShow.gt-sm>keyboard_arrow_down</mat-icon>
    </span>
  </button>

  <div class="dropdown mat-elevation-z1" [class.open]="isOpen">
    <mat-nav-list class="list">
      <mat-list-item routerLink="/profile">
        <mat-icon class="icon">account_circle</mat-icon>
        <span class="title">Account</span>
      </mat-list-item>

      <mat-list-item [routerLink]="['/help']">
        <mat-icon class="icon">help</mat-icon>
        <span class="title">Help</span>
      </mat-list-item>

      <mat-divider></mat-divider>

      <mat-list-item (click)="logout()">
        <mat-icon class="icon">power_settings_new</mat-icon>
        <span class="title">Log out</span>
      </mat-list-item>
    </mat-nav-list>
  </div>
</div>
