import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '../shared/shared.module';

import {NavigationModule} from './navigation/navigation.module';
import {HeaderModule} from './header/header.module';
import {AdminComponent} from './admin.component';
import {BrandComponent} from './brand/brand.component';
import {UpdateService} from "../core/update.service";
import {DialogModule} from "../component/dialog";

@NgModule({
  imports: [
    RouterModule,
    TranslateModule.forRoot(),
    SharedModule,
    NavigationModule,
    HeaderModule,
    DialogModule
  ],
  declarations: [
    AdminComponent,
    BrandComponent
  ],
  providers: [
    UpdateService
  ]
})
export class AdminModule {
}
