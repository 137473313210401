<mat-card class="mat-typography block-text">
  <mat-card-title
    *ngIf="name"
    markdown
    [content]="name"
    [context]="context"
    [enumerator]="enumerator"
    [required]="!placeholder && block.required"
  ></mat-card-title>
  <mat-card-content *ngIf="description" markdown [content]="description" [context]="context"></mat-card-content>
  <mat-form-field class="form-field-full-width">
    <input
      #ref
      type="text"
      matInput
      [required]="block.required"
      [placeholder]="placeholder"
      [value]="block.textSlot.string"
      [(ngModel)]="block.textSlot.value"
      (blur)="block.onBlur(ref)"
    />
  </mat-form-field>
  <mat-card-footer *ngIf="explanation" markdown [content]="explanation" [context]="context"></mat-card-footer>
</mat-card>

<!--<mat-card>-->
  <!--<mat-form-field-->
    <!--[hideRequiredMarker]="!block.required"-->
    <!--[floatLabel]="false"-->
    <!--class="form-field-full-width"-->
  <!--&gt;-->
    <!--<mat-label *ngIf="name"-->
               <!--markdown-->
               <!--[content]="name"-->
               <!--[context]="context"-->
               <!--[enumerator]="enumerator"-->
    <!--&gt;</mat-label>-->
    <!--<input-->
      <!--#ref-->
      <!--type="text"-->
      <!--matInput-->
      <!--[required]="block.required"-->
      <!--[placeholder]="placeholder"-->
      <!--[value]="block.textSlot.string"-->
      <!--[(ngModel)]="block.textSlot.value"-->
      <!--(blur)="block.onBlur(ref)"-->
    <!--/>-->
    <!--<mat-hint *ngIf="explanation" align="start"  markdown [content]="explanation" [context]="context"></mat-hint>-->
  <!--</mat-form-field>-->

<!--</mat-card>-->
