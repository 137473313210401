<div class="dashboard-header">
  <div class="d-flex">
    <div class="flex-grow-1">
      <h1 class="dashboard-header__title">Survey Statistics</h1>
      <p class="dashboard-header__desc">Get insights about surveys.</p>
    </div>
  </div>
</div>
<app-message
  #message
  [opened]="false"
>
  <button app-message-actions mat-icon-button (click)="message.close()">
    <mat-icon>cancel</mat-icon>
  </button>
</app-message>
<mat-card>
  <mat-card-content>
    <div class="row align-items-center">
      <div class="col-md-6 col-sm-12">
        <mat-card-title>Surveys</mat-card-title>
        <mat-card-subtitle>Select from the list of surveys the survey you want to use</mat-card-subtitle>
      </div>
      <div class="col-md-6 col-sm-12">
        <mat-form-field class="w-100">
          <input #titleFilter matInput placeholder="Search Survey Title">
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
    </div>
    <div class="mat-typography">
      <table mat-table [dataSource]="data | filterTitle:titleFilter.value" class="w-100">

        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef> Survey title</th>
          <td mat-cell *matCellDef="let element">{{element['title'] }}</td>
        </ng-container>

        <ng-container matColumnDef="survey_takers_count">
          <th mat-header-cell *matHeaderCellDef> Total Survey takers</th>
          <td mat-cell *matCellDef="let element"> {{element.survey_takers_count}}</td>
        </ng-container>

        <ng-container matColumnDef="completion_rate">
          <th mat-header-cell *matHeaderCellDef> Completion rate</th>
          <td mat-cell
              *matCellDef="let element"> {{(element.submitted_count / element.survey_takers_count)| percent}}</td>
        </ng-container>

        <ng-container matColumnDef="view">
          <th mat-header-cell *matHeaderCellDef>View</th>
          <td mat-cell *matCellDef="let element">
            <button mat-button color="primary" [routerLink]="[EDPath+'/results/'+element.id]">
              <mat-icon>visibility</mat-icon>
              View Results
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="export">
          <th *matHeaderCellDef mat-header-cell>Export</th>
          <td *matCellDef="let element" mat-cell>
            <a *ngIf="isEDMember" class="mat-raised-button mat-button-base mat-primary" color="primary"
               href="{{DownloadUrl + EDPath + '/surveys/' + element.id + '/export-results'}}" mat-button target="_blank">
              <mat-icon>cloud_download</mat-icon>
              Export Results
            </a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </mat-card-content>
</mat-card>



