<h2 mat-dialog-title>ED Account
    <button mat-icon-button mat-dialog-close type="button" color="primary">
        <mat-icon>close</mat-icon>
    </button>
</h2>

<mat-dialog-content class="mat-typography">
    <form [formGroup]="edForm" (ngSubmit)="save()">

        <mat-form-field class="w-100 mb-4">
            <input type="text" placeholder="Name" formControlName="name" required matInput>
            <mat-error *ngIf="formErrors.name">{{formErrors.name}}</mat-error>
            <mat-error *ngIf="errors.name" class="mb-2">{{errors.name}}</mat-error>
        </mat-form-field>

        <mat-form-field class="w-100 mb-4">
            <input type="text" placeholder="Path" formControlName="path" required matInput>
            <mat-error *ngIf="formErrors.path" class="mb-2">{{formErrors.path}}</mat-error>
            <mat-error *ngIf="errors.path">{{errors.path}}</mat-error>
            <mat-hint>Hint: example.com</mat-hint>
        </mat-form-field>

        <mat-form-field class="w-100 mb-4">
            <input type="text" placeholder="County" formControlName="county" required matInput>
            <mat-error *ngIf="formErrors.path">{{formErrors.county}}</mat-error>
            <mat-error *ngIf="errors.path">{{errors.county}}</mat-error>
        </mat-form-field>

        <mat-form-field class="w-100 mb-4">
            <input type="text" placeholder="Contact Phone" formControlName="contact_phone" matInput>
            <mat-error *ngIf="formErrors.path">{{formErrors.contact_phone}}</mat-error>
            <mat-error *ngIf="errors.path">{{errors.contact_phone}}</mat-error>
        </mat-form-field>

        <mat-form-field class="w-100 mb-4">
            <input type="text" placeholder="Contact Email" formControlName="contact_email" matInput>
            <mat-error *ngIf="formErrors.path">{{formErrors.contact_email}}</mat-error>
            <mat-error *ngIf="errors.path">{{errors.contact_email}}</mat-error>
        </mat-form-field>

        <mat-form-field class="w-100 mb-4">
            <input type="text" placeholder="Business Name" formControlName="business_name" matInput>
            <mat-error *ngIf="formErrors.path">{{formErrors.business_name}}</mat-error>
            <mat-error *ngIf="errors.path">{{errors.business_name}}</mat-error>
        </mat-form-field>

        <button mat-raised-button
                color="primary"
                class="w-100"
                type="submit"
                [disabled]="!edForm.valid">Save
        </button>
    </form>
</mat-dialog-content>
