<app-message
  #message
  [opened]="false"
>
  <button app-message-actions mat-icon-button (click)="message.close()">
    <mat-icon>cancel</mat-icon>
  </button>
</app-message>
<div class="dashboard dashboard--user">
  <div class="row">
    <div class="col-md-12">
      <app-user-dashboard-survey
        *ngFor="let surveyResponse of surveyResponses"
        [surveyResponse]="surveyResponse"
      ></app-user-dashboard-survey>

      <app-user-dashboard-survey
        *ngFor="let survey of surveys"
        [survey]="survey"
      ></app-user-dashboard-survey>
    </div>
    <!--<div class="col-md-5">-->
      <!--<mat-card class="">-->
        <!--<mat-card-title>How to use Survey</mat-card-title>-->
        <!--<mat-card-subtitle>Welcome The Wage & Benefits Survey System!</mat-card-subtitle>-->
        <!--<iframe width="560" height="315" style="width: 100%;"-->
                <!--src="https://www.youtube.com/embed/C0DPdy98e4c"-->
                <!--frameborder="0"-->
                <!--allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"-->
                <!--allowfullscreen></iframe>-->
        <!--<mat-card-content>-->
          <!--<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi dolorem libero omnis porro qui quidem, quo-->
            <!--ratione! Consectetur consequatur deserunt mollitia quibusdam sequi? At, nisi possimus? Ex minima quis-->
            <!--unde!</p>-->
          <!--<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque illo perspiciatis quaerat quasi-->
            <!--veritatis vero!</p>-->
          <!--<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias aliquid laboriosam nisi! Accusamus animi-->
            <!--beatae deleniti iste iusto, laboriosam libero maxime minus, molestiae natus nostrum officia quas, reiciendis-->
            <!--rerum sit!</p>-->
        <!--</mat-card-content>-->
      <!--</mat-card>-->
    <!--</div>-->
  </div>
</div>
