import {Component, OnInit, ViewChild} from '@angular/core';
import {AuthApiService} from "../../../core/auth-api.service";
import {ActivatedRoute} from '@angular/router';
import {MessageComponent} from "../../../component/message";

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {

  @ViewChild('message') messageBox: MessageComponent;

  token: string;
  sub: any;

  email: string;
  password: string;
  cpassword: string;

  constructor(private auth: AuthApiService, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.token = params['token'];
    });
  }

  passwordReset() {
    this.messageBox.renderApiError();
    this.auth.passwordReset(this.email, this.password, this.cpassword, this.token)
      .subscribe(r => {
        this.messageBox.renderSuccess(r);
      }, (err) => {
        this.messageBox.renderApiError(err);
      });
  }


}
