import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseApiService} from './base-api.service';
import {ConfigService} from "./config.service";

@Injectable()
export class EventsLogService extends BaseApiService {

  constructor(public http: HttpClient, public configService: ConfigService) {
    super();
  }

  public get url(): string {
    return this.configService.apiEndpoint + '/api/events-log';
  }


  all(page: number) {
    return this.get('/', {page: page});
  }

}
