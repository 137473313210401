<h2 mat-dialog-title class="mb-3">
  Close survey and compile results
  <button mat-icon-button type="button" color="primary" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</h2>

<mat-dialog-content class="mat-typography">

  <app-message
      #message
      [opened]="false"
  >
    <button app-message-actions mat-icon-button (click)="message.close()">
      <mat-icon>cancel</mat-icon>
    </button>
  </app-message>

  <div class="row align-items-center mb-2">
    <div class="col">
      <p>
        The survey is past its close date. <br/>
        You can either close it or change the end date.
      </p>
    </div>
    <div class="col-md-3 ml-md-auto">
      <button color="primary" mat-stroked-button class="w-100" (click)="editSurvey()">
        <mat-icon>edit</mat-icon>
        Extend end date
      </button>
    </div>
  </div>

  <div class="mat-card mb-3">
    <div class="mat-card-title">
      <h2 class="text-primary">Step 1: Review survey participants</h2>
    </div>

    <div class="mat-card-content">
      <ul>
        <li>
          <strong>{{surveyTakersCount}}</strong> people were invited to take the survey.
        </li>
        <li>
          <strong>{{submittedCount}} </strong> submitted their responses and will be included in the final result
          calculation.
        </li>
        <li *ngIf="excludedCount">
          <strong>{{excludedCount}} </strong> survey responses were set to be excluded from the results calculation.
        </li>
      </ul>
      <p>
        All other survey takers of status "In progress" or "Invited" will not be included. <br/>
        If it is needed participants can be excluded from the survey results using the option "Exclude from results" from the 'Actions'
        menu.
      </p>
      <app-survey-responses-table #surveyTakers></app-survey-responses-table>
    </div>
  </div>

  <div class="mat-card mb-3">
    <div class="mat-card-title">
      <h2 class="text-primary">Step 2: Review survey results</h2>
    </div>

    <div class="mat-card-content">
      <p>
        Review the current survey results for data quality and to ensure that no identifying information is present.<br/>
        If needed, survey response can be edited or excluded entirely from the survey results.
      </p>
      <button color="primary" mat-stroked-button class="w-100" [routerLink]="[EDPath+'/results/'+survey.id]">
        <mat-icon>edit</mat-icon>
        Review survey results
      </button>
    </div>
  </div>

  <div class="mat-card mb-3">
    <div class="mat-card-title">
      <h2 class="text-primary">Step 3: Review survey settings</h2>
    </div>
    <div class="mat-card-content">
      <app-step-survey-details-form
        [hideHeader]="true"
        [survey]="survey"
        (onFormChange)="onSurveyFormChange($event)"
      ></app-step-survey-details-form>
    </div>
  </div>

  <div class="mat-card mb-3">
    <div class="mat-card-title">
      <h2 class="text-primary">Step 4: Final notes</h2>
    </div>
    <div class="mat-card-content">
      <p>
        Once this form is submitted, all {{submittedCount}} participants who completed their surveys will be notified by
        email.
      </p>
      <p>
        Results will be made available for them in Web and print-friendly form.
      </p>
    </div>
  </div>

</mat-dialog-content>
<mat-dialog-actions class="mt-2 mb-1">
  <button #submitBtn mat-raised-button color="accent" class="w-100" (click)="closeSurvey(submitBtn)">Close survey &
    compile results
  </button>
</mat-dialog-actions>
