<div class="navigation-container">
  <div class="navigation-scrollbar-container">
    <perfect-scrollbar>
      <ng-container *ngFor="let navigation of navigationModel">
        <nav-collapse *ngIf="navigation.type == 'collapse'" [item]="navigation"></nav-collapse>
        <nav-item *ngIf="navigation.type=='item'" [item]="navigation"></nav-item>
      </ng-container>
    </perfect-scrollbar>

    <div class="navigation-footer d-flex align-items-center">
      <a matRipple routerLink="/help" class="flex-grow-1">
        <mat-icon>help</mat-icon>
        Help
      </a>
      <span class="small pr-1">v.{{version}}</span>
    </div>
  </div>
</div>
