<h2 mat-dialog-title>
  <ng-container *ngIf="user.id;">
    <mat-icon>person</mat-icon>
    {{ user.first_name }} {{ user.last_name }}
  </ng-container>
  <ng-container *ngIf="!user.id;">
    <mat-icon>person_add</mat-icon>
    Add New User
  </ng-container>
  <button mat-icon-button type="button" color="primary" (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</h2>
<mat-dialog-content>
  <mat-tab-group (selectedTabChange)="onTabChange($event)">
    <mat-tab [label]='user.id ? "Edit user" : "Create user"'>
      <ng-template matTabContent>
        <app-message #message [opened]="false" class="mat-typography">
          <button app-message-actions mat-icon-button (click)="message.close()">
            <mat-icon>cancel</mat-icon>
          </button>
        </app-message>
        <div class="mat-typography tab-inner">
          <form class="user-form" [formGroup]="userForm" (ngSubmit)="save()"
                (keydown.enter)="$event.preventDefault()">
            <div class="form-row mt-4">
              <div class="col-md-6">
                <mat-form-field class="w-100">
                  <input formControlName="first_name" matInput placeholder="First Name">
                  <mat-error *ngIf="formErrors.first_name">{{formErrors.first_name}}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="w-100">
                  <input formControlName="last_name" matInput placeholder="Last Name">
                  <mat-error *ngIf="formErrors.last_name">{{formErrors.last_name}}</mat-error>
                </mat-form-field>
              </div>
            </div>

            <!-- <div class="form-row mt-4">
              <div class="col-md-6">
                <mat-form-field class="w-100">
                  <input type="password" formControlName="password" matInput placeholder="Password">
                  <mat-error *ngIf="formErrors.password">{{formErrors.password}}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="w-100">
                  <input type="password" formControlName="password_confirmation" matInput placeholder="Confirm Password">
                   <mat-error *ngIf="formErrors.password_confirmation">{{formErrors.password_confirmation}}</mat-error> 
                </mat-form-field>
              </div>
            </div> -->

            <div class="form-row">
              <div class="col-md-6">
                <mat-form-field class="w-100">
                  <input formControlName="email" matInput placeholder="E-Mail">
                  <mat-error *ngIf="formErrors.email">{{formErrors.email}}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field *ngIf="userRoles.length" class="w-100">
                  <mat-select placeholder="Role" formControlName="role_id" (valueChange)="updateUI()">
                    <mat-option *ngFor="let userRole of userRoles" [value]="userRole.value">
                      {{userRole.viewValue}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="formErrors.role_id">{{formErrors.role_id}}</mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="form-row">
              <div class="col-md-6">
                <mat-form-field class="w-100">
                  <mat-select placeholder="Timezone" formControlName="timezone">
                    <mat-option *ngFor="let timezone of timezones" [value]="timezone">
                      {{timezone}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="formErrors.timezone">{{formErrors.timezone}}</mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="form-row">
              <div class="col-md">
                <ng-container formGroupName="ed" *ngIf="EDs.length > 1">
                  <mat-form-field class="w-100">
                    <input #edName formControlName="name" matInput placeholder="ED Account"
                           [matAutocomplete]="auto">
                    <mat-icon *ngIf="!edName.disabled" matSuffix matTooltip="Select from the list">
                      help_outline
                    </mat-icon>
                    <mat-autocomplete #auto="matAutocomplete"
                                      (optionSelected)="onAutoComplete($event, 'ed')">
                      <mat-option *ngFor="let option of EDs | filterEd:edName.value"
                                  [value]="option">
                        {{option.name}} <span class="mat-small">({{option.path}})</span>
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="formErrors.ed">{{formErrors.ed}}</mat-error>

                  </mat-form-field>
                </ng-container>
              </div>
              <div class="col-md">
                <ng-container formGroupName="business" *ngIf="businesses.length || !user.id">
                  <mat-form-field class="w-100">
                    <input #businessName formControlName="name" matInput placeholder="Business"
                           [matAutocomplete]="autoBusiness"/>
                    <mat-icon *ngIf="!businessName.disabled" matSuffix
                              matTooltip="Select from the list or type in new one">help_outline
                    </mat-icon>
                    <mat-autocomplete #autoBusiness="matAutocomplete"
                                      (optionSelected)="onAutoComplete($event, 'business')"
                    >
                      <mat-option *ngFor="let option of businesses | filterEd:businessName.value"
                                  [value]="option">
                        {{option.name}}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="formErrors.business">{{formErrors.business}}</mat-error>
                  </mat-form-field>
                </ng-container>
              </div>
            </div>
            <ng-container *ngIf="!isEditingTheLoggedInUser()">
              <div class="form-row">
                <div class="col">
                  <mat-checkbox formControlName="suspended" [checked]="suspended"  *ngIf="user.id" color="warn">
                    <span class="text-danger">Suspend User</span>
                  </mat-checkbox>
                </div>
              </div>
              <div class="form-row">
                <div class="col">
                  <app-created-updated-section [model]="user"></app-created-updated-section>
                </div>
              </div>
            </ng-container>
            <div class="form-row">
              <div class="col text-right">
                <button *ngIf="user.id && !isEditingTheLoggedInUser()"
                        mat-raised-button
                        type="button"
                        class="mr-2"
                        (click)="resetPassword()">
                  <mat-icon>vpn_key</mat-icon>
                  Reset password
                </button>
                <button mat-raised-button
                        [color]="'primary'"
                        type="submit"
                        [disabled]="!userForm.valid">
                  <mat-icon>save</mat-icon>
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab  [label]='"Edit password"'>
      <ng-template matTabContent>
        <app-message #message [opened]="false" class="mat-typography">
          <button app-message-actions mat-icon-button (click)="message.close()">
            <mat-icon>cancel</mat-icon>
          </button>
        </app-message>
        <div class="mat-typography tab-inner">
          <form class="user-form" [formGroup]="passwordForm" (ngSubmit)="savePassword()"
                (keydown.enter)="$event.preventDefault()">

            <div class="form-row mt-4" *ngIf='user.id && isEditingTheLoggedInUser()'>
              <div class="col-md-6">
                <mat-form-field class="w-100">
                  <input type="password" formControlName="old_password" matInput placeholder="Current Password">
                  <mat-error *ngIf="formErrorsPassword.old_password">{{formErrorsPassword.old_password}}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
              </div>
            </div>

            <div class="form-row mt-4">
              <div class="col-md-6">
                <mat-form-field class="w-100">
                  <input type="password" formControlName="password" matInput placeholder="New Password">
                  <mat-error *ngIf="formErrorsPassword.password">{{formErrorsPassword.password}}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="w-100">
                  <input type="password" formControlName="password_confirmation" matInput placeholder="Confirm New Password">
                   <mat-error *ngIf="formErrorsPassword.password_confirmation">{{formErrorsPassword.password_confirmation}}</mat-error> 
                </mat-form-field>
              </div>
            </div>
            <div class="form-row">
              <div class="col text-right">
                <button mat-raised-button
                        [color]="'primary'"
                        type="submit"
                        [disabled]="!passwordForm.valid">
                  <mat-icon>save</mat-icon>
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab label="Surveys" *ngIf="!isEditingTheLoggedInUser() && user.id && user.role_id >= 40">
      <ng-template matTabContent>

        <div *ngIf="!isLoading && !userSurveysDataSource.data.length" class="text-center text-dark p-3">
          <h3>No data available</h3>
        </div>

        <div class="results-wrapper">

          <table class="w-100" mat-table [dataSource]="userSurveysDataSource"
                 [hidden]="!userSurveysDataSource.data.length">

            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef>Title</th>
              <td mat-cell *matCellDef="let element">
                <button mat-flat-button (click)="viewSurvey(element['survey'])">{{element['survey']['title']}}</button>
              </td>
            </ng-container>

            <ng-container matColumnDef="period">
              <th mat-header-cell *matHeaderCellDef>Period</th>
              <td mat-cell *matCellDef="let element"> {{element['survey']['start_date'] | formatDate | date}}
                - {{element['survey']['end_date'] | formatDate | date}}
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Status</th>
              <td mat-cell *matCellDef="let element">
                <i class="fas fa-circle"
                   [ngClass]="{'text-warning':element.status.code === 0,
                       'text-success':element.status.code === 2,
                       'text-primary':element.status.code === 1}"></i>
                {{element.status.label}}
              </td>
            </ng-container>

            <ng-container matColumnDef="progress">
              <th mat-header-cell *matHeaderCellDef>Progress</th>
              <td mat-cell *matCellDef="let element">
                <div class="pr-4" *ngIf="getSurveyProgress(element); let surveyProgress">
                  <div class="progress" *ngIf="isProgressBarEligible(element) else progressText">
                    <div class="progress-bar" role="progressbar" [ngStyle]="{width: surveyProgress+'%'}"><span
                      class="small d-block">{{surveyProgress}}%</span></div>
                  </div>
                  <ng-template #progressText>{{surveyProgress}}%</ng-template>
                </div>
                <div *ngIf="!getSurveyProgress(element)">N/A</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="updated_at">
              <th mat-header-cell *matHeaderCellDef>Updated At</th>
              <td mat-cell *matCellDef="let element"> {{element['updated_at'] | formatDate |  date:'short'}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          </table>
        </div>

      </ng-template>
    </mat-tab>

    <!--<mat-tab label="User Logs" *ngIf="user.id">-->
    <!--<ng-template matTabContent>-->
    <!--<div class="mat-typography tab-inner">-->
    <!--<app-events-log-table></app-events-log-table>-->
    <!--</div>-->
    <!--</ng-template>-->
    <!--</mat-tab>-->

    <!--<mat-tab label="Notifications" *ngIf="user.id">-->
    <!--<ng-template matTabContent>-->
    <!--<div class="mat-typography tab-inner">-->
    <!--Notifications to be displayed here....-->
    <!--</div>-->
    <!--</ng-template>-->
    <!--</mat-tab>-->

  </mat-tab-group>
</mat-dialog-content>




