<app-dialog>
  <app-dialog-title *ngIf="title">
    {{title}}
  </app-dialog-title>
  <app-dialog-content [innerHTML]="message">
  </app-dialog-content>
  <app-dialog-actions>
      <button mat-button color="accent" (click)="close()">{{closeButton}}</button>
  </app-dialog-actions>
</app-dialog>
