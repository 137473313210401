import {Injectable} from '@angular/core';
import {BaseApiService} from './base-api.service';
import {ConfigService} from "./config.service";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class ApiService extends BaseApiService {

  constructor(public http: HttpClient, public configService: ConfigService) {
    super();
    this.url = configService.apiEndpoint;
  }


}
