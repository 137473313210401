<div *ngIf="!isEmptyModel()" class="mat-typography mat-caption">
  <div *ngIf="model['created_at']" matTooltip="Date Created">
    <i class="fas fa-plus fa-fw"></i>  {{model['created_at'] | formatDate | date:'medium'}}
    <ng-container *ngIf="model['created_by']">by {{model['created_by']['first_name']}}
      {{model['created_by']['last_name']}}
    </ng-container>
  </div>
  <div *ngIf="model['updated_at']" matTooltip="Last Updated">
    <i class="fas fa-pen fa-fw"></i>  {{model['updated_at'] | formatDate | date:'medium'}}
    <ng-container *ngIf="model['updated_by']">by {{model['updated_by']['first_name']}}
      {{model['updated_by']['last_name']}}
    </ng-container>
  </div>
</div>
