<h2 mat-dialog-title class="mb-3" *ngIf="isModal">
  View survey
  <button mat-icon-button type="button" color="primary" (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</h2>
<app-dashboard-survey
  [survey]="survey"
  (changed)="onSurveyChanged()"
>
</app-dashboard-survey>
