import {FormGroup} from "@angular/forms";
import {BehaviorSubject} from "rxjs";

export class FormValidator {

  onError: BehaviorSubject<any> = new BehaviorSubject({});

  baseValidationMessages = {
    required: 'Field is required',
    email: 'Please enter valid email',
    minlength: 'Please enter more than',
    maxlength: 'Please enter less than'
  };

  formErrors = {};

  constructor(public form: FormGroup, public validationMessages) {
    Object.keys(this.form.controls).forEach(key => this.formErrors[key] = '');
  }

  onValueChanged(data?: any) {
    if (!this.form) {
      return;
    }

    for (const field in this.formErrors) {
      if (Object.prototype.hasOwnProperty.call(this.formErrors, field)) {
        this.formErrors[field] = '';
        const control = this.form.get(field);
        if (control && control.dirty && !control.valid) {

          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (Object.prototype.hasOwnProperty.call(control.errors, key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }

    this.onError.next(this.formErrors);
  }

}
