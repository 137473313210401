<div class="editor-header mat-elevation-z2 bg-white">
  <div class="container-fluid">
    <div class="row w-100 align-items-center">
      <div class="col-md-6">
        <h2 class="title text-truncate h5 text-" [title]="collector.name">
          <button mat-icon-button (click)="onCloseClicked()" class="mr-2" title="Go Back">
            <mat-icon>arrow_back</mat-icon>
          </button>
          <span (click)="editor.rename()" class="title__text" [class.text-muted]="!collector.name">{{ collector.name || 'Click to edit title...' }}</span>
        </h2>
      </div>
      <div class="col-md-2">
        <mat-form-field>
          <mat-select [(ngModel)]="previewMode" (valueChange)="previewModeChange()">
            <mat-option [value]="false">Preview disabled</mat-option>
            <mat-option [value]="'preview'">Collector mode</mat-option>
            <mat-option [value]="'collect'">Preview all questions</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-4 text-right">
        <button mat-button color="primary" (click)="onSaveClicked()">
          <mat-icon>save</mat-icon>
          Save
        </button>
      </div>
    </div>
  </div>
</div>
<div class="tripetto-editor">
  <tripetto-editor #editor
                   (changed)="onEditorChanged($event)"
                   [ngClass]="{w100: previewMode === false}"
  ></tripetto-editor>

  <tripetto-collector #collector
                      [hidden]="previewMode === false"
                      (changed)="onCollectorChanged()"
  ></tripetto-collector>
</div>
