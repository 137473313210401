import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialogRef} from "@angular/material";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {User} from "../../../../../../model/common";
import {AuthApiService} from "../../../../../../core/auth-api.service";
import {UserService} from "../../../../../../core/user.service";
import {EdService} from "../../../../../../core/ed.service";
import {BusinessService} from "../../../../../../core/business.service";
import {MessageComponent} from "../../../../../../component/message";

@Component({
  selector: 'app-new-user-dialog',
  templateUrl: './new-user-dialog.component.html',
  styleUrls: ['./new-user-dialog.component.scss']
})
export class NewUserDialogComponent implements OnInit {

  @ViewChild('message') messageBox: MessageComponent;
  @Output() onComplete = new EventEmitter<User>();

  userForm: FormGroup;
  @Input() businesses = [];
  user: User;

  constructor(public dialogRef: MatDialogRef<NewUserDialogComponent>, private fb: FormBuilder,
              private authApi: AuthApiService,
              private userService: UserService,
              private edService: EdService,
              private businessService: BusinessService,
  ) {
  }

  ngOnInit() {
    this.fetchBusinesses();
    this.buildForm();
  }

  fetchBusinesses() {
    if (this.businesses.length) return;
    this.businessService.edPath = this.authApi.user.ed.path;
    this.businessService.all().subscribe(r => {
      this.businesses = r as any[];
    }, e => this.messageBox.renderApiError(e));
  }

  buildForm() {
    if (this.userForm) return;
    this.user = this.user || <User>{};

    this.userForm = this.fb.group({
      id: [this.user.id],
      first_name: [this.user.first_name, [Validators.required]],
      last_name: [this.user.last_name, [Validators.required]],
      email: [this.user.email, [Validators.required, Validators.email]],
      business: this.fb.group({
        id: [this.user.business && this.user.business.id],
        name: [this.user.business && this.user.business.name, [Validators.required]],
        ed_id: [this.user.business && this.user.business.ed_id]
      })
    });
  }


  cancelClick() {
    this.dialogRef.close();
  }

  save() {
    this.onComplete.emit(this.userForm.getRawValue());
  }

  onAutoComplete = ($event) => {
    setTimeout(() => {
      const val = $event['option']['value'];

      this.userForm.controls['business'].setValue({
        id: val['id'],
        name: val['name'],
        ed_id: val['ed_id']
      });

    }, 0);
  };

}
