<div class="w-100 d-flex justify-content-center">
  <table class="mat-table">
    <thead>
    <tr class="mat-header-row">
      <th class="mat-header-cell">&nbsp;</th>
      <th class="mat-header-cell">Checked</th>
    </tr>
    </thead>
    <tbody>
    <tr class="mat-row">
      <th class="mat-header-cell">{{question.name}}</th>
      <td class="mat-cell">
        <div>{{question.results.answered_count | filterZero}}</div>
      </td>
    </tr>
    </tbody>
  </table>

</div>
