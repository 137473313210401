import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';

import {ConfigService} from './config.service';
import {ApiService} from './api.service';
import {AuthApiService} from './auth-api.service';
import {UserService} from "./user.service";
import {EdService} from "./ed.service";
import {LoadingService} from "./loading.service";
import {EventsLogService} from "./events-log.service";
import {SurveyDefinitionService} from "./survey-definition.service";
import {BusinessService} from "./business.service";
import {SurveyService} from "./survey.service";
import {IndustriesService} from "./industries.service";
import {AdminService} from "./admin.service";
import {PublicService} from "./public.service";
import {UserSettingsService} from "./user.settings.service";

@NgModule({
  providers: [
    ApiService,
    AuthApiService,
    ConfigService,
    UserService,
    UserSettingsService,
    EdService,
    LoadingService,
    EventsLogService,
    SurveyDefinitionService,
    BusinessService,
    SurveyService,
    IndustriesService,
    AdminService,
    PublicService
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'ServicesModule is already loaded. Import it in the AppModule only');
    }
  }

  public static forRoot(): ModuleWithProviders {
    return {ngModule: CoreModule};
  }
}
