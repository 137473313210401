<div class="mat-typography">
  <div class="select-industry-header">
    <div class="row align-items-center mb-2">
      <div class="col-md-8">
        <strong class="text-uppercase text-primary">Occupation groups</strong><br>
        <strong>If there is a need to add a certain Occupation group or Occupation, please click add new Occupation
          group button first
          and add relevant occupations inside each expansion panel.</strong>
      </div>
      <div class="col-md-3 ml-md-auto">
        <button mat-stroked-button
                color="primary"
                class="w-100"
                (click)="addIndustriesContainer.hidden = !addIndustriesContainer.hidden">
          <mat-icon>{{addIndustriesContainer.hidden ? 'visibility' : 'visibility_off'}}</mat-icon>
          Add new group
        </button>
      </div>
    </div>
  </div>
  <div #addIndustriesContainer [hidden]="true" class="add-industry-row">
    <form (ngSubmit)="onIndustryAdd(industryInput)" (keydown.enter)="$event.preventDefault()">
      <div class="form-row align-items-center">
        <div class="col-md-6">
          <mat-form-field class="w-100">
            <input type="text"
                   placeholder="Occupation group title"
                   matInput required
                   (keydown.enter)="onIndustryAdd(industryInput)"
                   #industryInput>
          </mat-form-field>
        </div>
        <div class="col-md-2 ml-md-auto">
          <button mat-flat-button
                  color="accent"
                  class="w-100"
                  type="submit"
                  [disabled]="!industryInput.value"
          >
            <mat-icon>playlist_add</mat-icon>
            Add To List
          </button>
        </div>
      </div>
    </form>
  </div>


  <div class="mt-3" *ngIf="commonOccupations.length">
    <strong class="text-primary">Common occupations</strong>
    <mat-selection-list
      #commonOccupationsList
      (selectionChange)="commonOccupationsSelectionChange(commonOccupationsList)"
      class="occupations-list occupations-list__common"
    >
      <mat-list-option
        *ngFor="let occupation of commonOccupations"
        [value]="occupation"
        [selected]="occupationSelected(occupation)"
        [checkboxPosition]="'before'"
      >
        <div class="d-flex align-items-center">
          {{occupation.title}}
          <button class="ml-auto" mat-stroked-button matSuffix [color]="'primary'"
                  [popoverTriggerFor]="descriptionPopover">
            Description
          </button>
          <stbui-popover #descriptionPopover (onOpen)="onDescpopoverOpen(occupation, descContainer)">
            <div class="mat-card mat-elevation-z5" style="width: 350px">
              <div class="mat-card-content" #descContainer></div>
            </div>
          </stbui-popover>
        </div>
      </mat-list-option>
    </mat-selection-list>
  </div>

  <div class="mt-3" *ngIf="commonOccupations.length">
    <button
      mat-stroked-button
      color="primary"
      (click)="allOccupations.hidden = !allOccupations.hidden"
      style="width: 100%"
    >{{allOccupations.hidden ? 'Show' : 'Hide' }} all SoC occupations
    </button>
  </div>

  <div #allOccupations [hidden]="commonOccupations.length">
    <div class="form-row">
      <div class="col-md-6">
        <div class="mt-3">
          <button mat-button color="accent" (click)="selectAllOccupations()" [hidden]="isAllOccupationsChecked()">
            <mat-icon>check</mat-icon>
            Select all occupations
          </button>
          <button mat-button color="warn" (click)="deselectAllOccupations()" [hidden]="!isAllOccupationsChecked()">
            <mat-icon>check</mat-icon>
            Deselect all occupations
          </button>
        </div>
      </div>
      <div class="col-md-6">
        <mat-form-field class="w-100 mt-3">
          <input type="text" placeholder="Search occupations"
            [(ngModel)]="searchOccupations"
            (ngModelChange)="onSearchOccupationsChanged()"
            matInput>
        </mat-form-field>
      </div>
    </div>

    <div class="industries-list">
      <mat-accordion class="industries-list" [multi]="true">
        <mat-expansion-panel *ngFor="let industry of industries" #industryPanel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{industry.title}}
            </mat-panel-title>
            <mat-panel-description>
              Selected Occupations: {{getSelectedOccupationsCount(occupationsList)}}
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div>
            <div class="d-flex justify-content-between">
              <mat-checkbox
                #check
                (click)="toggleAllOccupations($event, check, occupationsList)"
                [checked]="isChecked(occupationsList)"
                [indeterminate]="isIndeterminate(occupationsList)"
              >
                Select All
              </mat-checkbox>

              <button mat-button mat-stroked-button color="warn"
                      (click)="removeIndustry($event, industry, occupationsList)"
                      *ngIf="!industry.id">Remove group
              </button>
            </div>

            <mat-selection-list #occupationsList (selectionChange)="selectionChange()" class="occupations-list">
              <mat-list-option
                #occupationOption
                *ngFor="let occupation of industry.occupations"
                [value]="occupation"
                [selected]="occupationSelected(occupation)"
                [checkboxPosition]="'before'"
              >
                <div class="d-flex align-items-center {{occupation['searchHighlight'] ? 'occupation-search-highlight':''}}">
                  {{occupation.title}}
                  <button *ngIf="industryPanel.expanded" class="ml-auto" mat-stroked-button matSuffix [color]="'primary'"
                          [popoverTriggerFor]="descriptionPopover">
                    Description
                  </button>
                  <stbui-popover #descriptionPopover (onOpen)="onDescpopoverOpen(occupation, descContainer)">
                    <div class="mat-card mat-elevation-z5" style="width: 350px">
                      <div class="mat-card-content" #descContainer></div>
                    </div>
                  </stbui-popover>
                  <button class="ml-auto" mat-icon-button matSuffix [color]="'warn'" [hidden]="occupation.id"
                          (click)="removeOccupation($event, industry, occupation, occupationsList, occupationOption)">
                    <mat-icon>remove_circle_outline</mat-icon>
                  </button>
                </div>
              </mat-list-option>
            </mat-selection-list>
            <mat-action-row>
              <form (ngSubmit)="onOccupationAdd(industry, occupation, description, occupationsList)"
                    (keydown.enter)="$event.preventDefault()" class="occupations-list-form">
                <div class="form-row align-items-center">
                  <div class="col-md-8">
                    <mat-form-field class="w-100">
                      <input type="text"
                             placeholder="Add Occupation"
                             (keydown.enter)="onOccupationAdd(industry, occupation, description, occupationsList)"
                             matInput #occupation>
                    </mat-form-field>
                  </div>
                  <div class="col-md-4 ml-md-auto">
                    <button mat-stroked-button
                            class="w-100"
                            color="accent"
                            [disabled]="!occupation.value || !description.value"
                            type="submit">
                      <mat-icon>playlist_add</mat-icon>
                      Add To List
                    </button>
                  </div>
                </div>
                <div class="form-row align-items-center">
                  <div class="col-md-12">
                    <mat-form-field class="w-100">
                      <input type="text"
                             placeholder="Add Description"
                             (keydown.enter)="onOccupationAdd(industry, occupation, description, occupationsList)"
                             matInput #description>
                    </mat-form-field>
                  </div>
                </div>
              </form>
            </mat-action-row>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>


</div>
