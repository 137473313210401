<app-dialog>
  <app-dialog-title *ngIf="title">
    {{title}}
  </app-dialog-title>
  <app-dialog-content [innerHTML]="message">
  </app-dialog-content>
  <app-dialog-actions>
    <button mat-button #closeBtn (keydown.arrowright)="acceptBtn.focus()" (click)="cancel()">{{cancelButton}}</button>
    <button mat-button #acceptBtn (keydown.arrowleft)="closeBtn.focus()" (click)="accept()" color="accent">{{acceptButton}}</button>
  </app-dialog-actions>
</app-dialog>
