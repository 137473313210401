import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {Industry, Occupation, SurveySectionResponse} from "../../../../../model/common";
import {MatCheckbox, MatCheckboxChange} from "@angular/material";
import {IndustriesService} from "../../../../../core/industries.service";

@Component({
  selector: 'app-select-industries-and-occupations',
  templateUrl: './select-industries-and-occupations.component.html',
  styleUrls: ['./select-industries-and-occupations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectIndustriesAndOccupationsComponent implements OnInit {

  @Input() surveySection: SurveySectionResponse;
  @Input() mandatoryOccupationIDs = [];
  mandatoryOccupationIDsAssoc = {};

  constructor(
    private changeDetector: ChangeDetectorRef,
    private industriesService: IndustriesService,
  ) {}

  ngOnInit() {
    this.mandatoryOccupationIDs = this.mandatoryOccupationIDs || [];
    this.mandatoryOccupationIDs.forEach(id => this.mandatoryOccupationIDsAssoc[id] = id);
  }

  industrySelectedOccupationsCount(industry: Industry): number {
    let count = 0;

    if (!industry || !industry.occupations || !industry.occupations.length) {
      return count;
    }

    for (let occupation of industry.occupations) {
      if (!occupation.selected) continue;
      count++;
    }

    return count;
  }

  onDescpopoverOpen(occupation, descContainer) {
    return this.industriesService.getOccupation(occupation.id).subscribe(r => {
      descContainer.innerHTML = r['description'];
    });
  }

  isOccupationRowChecked(occupation: Occupation) {
    return occupation.selected;
  }

  onOccupationRowClick($event: MatCheckboxChange, occupationCheck: MatCheckbox, occupation) {
    occupation.selected = $event.checked;
    this.changeDetector.detectChanges();
  }

  isMandatoryOccupation(occupation: Occupation) {
    return !!this.mandatoryOccupationIDsAssoc[occupation.id];
  }

}
