import {Component, OnInit, ViewChild} from '@angular/core';
import {Survey, SurveyResponse} from "../../../../model/common";
import {MatDialogRef} from "@angular/material/dialog";
import {EditSurveyDetailsComponent} from "../../surveys/modals/edit-survey-details/edit-survey-details.component";
import {DialogService} from "../../../../component/dialog";
import {SurveyResponsesTableComponent} from "../../../../components/survey-responses-table/survey-responses-table.component";
import {MessageComponent} from "../../../../component/message";
import {SurveyService} from "../../../../core/survey.service";
import {finalize} from "rxjs/operators";
import {AuthApiService} from "../../../../core/auth-api.service";

@Component({
  selector: 'app-compile-survey',
  templateUrl: './compile-survey.component.html',
  styleUrls: ['./compile-survey.component.scss']
})
export class CompileSurveyComponent implements OnInit {

  @ViewChild('message') message: MessageComponent;
  @ViewChild('surveyTakers') surveyTakers: SurveyResponsesTableComponent;

  EDPath = this.auth.getEDPath();

  survey: Survey;
  tmpSurvey: Survey;

  surveyResponses: SurveyResponse[] = null;

  surveyTakersCount = 0;
  submittedCount = 0;
  excludedCount = 0;

  constructor(
    public dialogRef: MatDialogRef<CompileSurveyComponent>,
    private dialog: DialogService,
    private surveyService: SurveyService,
    private auth: AuthApiService
  ) {

  }

  ngOnInit() {
    this.surveyTakers.onSurveyResponses.subscribe((responses: SurveyResponse[]) => {
      this.surveyResponses = responses;
      this.surveyTakersCount = 0;
      this.submittedCount = 0;
      this.excludedCount = 0;

      this.surveyResponses.forEach((response) => {
        this.surveyTakersCount++;
        if (response.submitted && !response.exclude_from_results) {
          this.submittedCount++;
        }
        if (response.exclude_from_results) {
          this.excludedCount++;
        }
      });
    });

    this.surveyTakers.survey = this.survey;
  }


  editSurvey() {

    const dialogRef = this.dialog.open(EditSurveyDetailsComponent, {
      disableClose: true,
      panelClass: 'dialog-md'
    });

    dialogRef.componentInstance.survey = this.survey;
    dialogRef.componentInstance.onSaved.subscribe(() => {
      dialogRef.close();
      this.dialogRef.close();
    });
  }

  onSurveyFormChange($event) {
    this.tmpSurvey = $event['value'];
  }

  closeSurvey(submitBtn) {
    submitBtn.disabled = true;

    this.surveyService.closeSurvey(this.survey.id, this.tmpSurvey).subscribe(r => {
        this.message.renderSuccess();
        this.dialogRef.close();
      },
      e => {
        this.message.renderApiError(e);
        submitBtn.disabled = false;
      });
  }

}
