import {Injectable} from '@angular/core';
import {SwUpdate} from "@angular/service-worker";
import {interval} from "rxjs";
import {DialogService} from "../component/dialog";

@Injectable()
export class UpdateService {

  private dialogRef;

  constructor(public updates: SwUpdate, private dialog: DialogService) {
    if (updates.isEnabled) {
      interval(60*1000).subscribe(() => updates.checkForUpdate()
        .then(() => console.log('Checking for updates')));
    }
  }

  public checkForUpdates(): void {
    this.updates.available.subscribe(event => this.promptUser());
  }

  private promptUser(): void {

    console.log('New version available!');

    if (this.dialogRef) {
      return;
    }

    this.dialogRef = this.dialog.confirm({
      title: 'Application update',
      message: 'Updated version of SensibleSurveys is available. Page reload is required to apply the update. Would you like to reload it now?',
      cancelButton: 'No, I\'ll reload it later',
      acceptButton: 'Yes, reload now'
    }).afterClosed().subscribe((r => {
      this.dialogRef = null;
      if (!r) return;
      document.location.reload();
    }));

  }
}
