import * as Tripetto from 'tripetto-collector';
import {condition, ConditionBlock, NodeBlock, tripetto} from 'tripetto-collector';
import {BlockComponentFactory} from '../../helpers/blocks/factory';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnChanges, OnInit,
  SimpleChanges
} from '@angular/core';
import {DropdownOption} from "../../../editor/blocks/matrix-radiobuttons/matrix-radiobuttons";


@Component({
  templateUrl: './matrix-numbers.html',
  styleUrls: ['./matrix-numbers.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MatrixNumbersBlockComponent extends BlockComponentFactory<MatrixNumbersBlock> {



}

@Tripetto.block({
  type: 'node',
  identifier: 'block-matrix-numbers',
  ref: MatrixNumbersBlockComponent
})
export class MatrixNumbersBlock extends NodeBlock {

  readonly dataSlot = Tripetto.assert(this.valueOf('data-slot'));
  readonly required = this.dataSlot.slot.required || false;

  readonly suffix = this.valueOf('suffix');
  readonly prefix = this.valueOf('prefix');

  _state = null;

  get state() {
    if(this._state) {
      return this._state;
    }
    let s = (this.dataSlot.string || this.dataSlot.value) as string;
    this._state = s.length ? JSON.parse(s as string) : {};
    return this._state;
  };

  set state(value) {
    this._state = value;

    for (let rowKey in this._state) {
      let rowState = false;
      // noinspection JSUnfilteredForInLoop
      for (let columnKey in this._state[rowKey]) {
        // noinspection JSUnfilteredForInLoop

        let val = this._state[rowKey][columnKey];
        rowState = rowState || (val && val.toString().length);
      }
      if (!rowState) {
        delete this._state[rowKey];
      }
    }

    let slotValue = Object.keys(this._state).length ? JSON.stringify(this._state) : '';
    this.dataSlot.set(slotValue);
  }

  change(ref, col, row) {
    let s = this.state;

    s[row.name] = s[row.name] || {};
    s[row.name][col.name] = s[row.name][col.name] || {};
    s[row.name][col.name] = ref.value;

    this.state = s;
  }

  value(col, row) {
    return this.state && this.state[row.name] && this.state[row.name][col.name] ? this.state[row.name][col.name] : null;
  }

  onFocus(ref, col, row) {
  }

  onBlur(ref, col, row) {
  }
}
