<div class="signin-container" fxLayout="row">
  <div class="signin-cover" fxFlex="100" fxFlex.gt-xs="50">
    <div fxLayout="column" fxLayoutAlign="center center" style="height: 100%;">
      <app-ed-logo [logoPath]="edLogo"></app-ed-logo>
    </div>
  </div>
  <div class="signin-content mat-elevation-z24" fxFlex="100" fxFlex.gt-xs="50">
    <app-message
      #message
      [opened]="false">
      <button app-message-actions mat-icon-button (click)="message.close()">
        <mat-icon>cancel</mat-icon>
      </button>
    </app-message>

    <app-message *ngIf="0 && successMsg.status!=''"
                 #message
                 [icon]="'person'"
                 [color]="'primary'"
                 [label]="successMsg.status"
                 [sublabel]="successMsg.message">
      <button app-message-actions mat-icon-button (click)="message.close()">
        <mat-icon>cancel</mat-icon>
      </button>
    </app-message>

    <div *ngIf="!forgotPassword">
      <div class="mat-card-title" *ngIf="edInfo">Welcome to the {{this.edInfo.name}} survey system!</div>
      <div class="mat-card-title mat-display-1 mb-2" *ngIf="!edInfo">Login</div>

      <form [formGroup]="userForm" (ngSubmit)="login()" class="mt-4">
        <mat-form-field class="display-block">
          <input type="email" placeholder="E-mail" formControlName="email" required matInput autocomplete="username email">
          <mat-error *ngIf="formErrors.email">{{formErrors.email}}</mat-error>
        </mat-form-field>

        <mat-form-field class="display-block">
          <input type="password" placeholder="Password" formControlName="password" required matInput autocomplete="current-password">
          <mat-error *ngIf="formErrors.password">{{formErrors.password}}</mat-error>
        </mat-form-field>

        <button mat-raised-button
                color="primary"
                class="w-100 mt-2"
                type="submit"
                [disabled]="!userForm.valid">Log in</button>

        <div class="mt-4 text-center">
          <a class="link link--dotted" (click)="activatePasswordReset()">Forgot Your Password ?</a>
        </div>
      </form>
    </div>

    <div *ngIf="forgotPassword">
      <h1 class="mat-display-1 mb-2">Forgot Your Password?</h1>
      <h2 class="lead">Enter your email address and we will send you the reset link.</h2>
      <form (ngSubmit)="sendPasswordResetEmail()">
        <mat-form-field class="display-block">
          <input [(ngModel)]="passwordResetEmail" email type="email" name="password-reset-email"
                 placeholder="Please enter your email address" required matInput>
          <mat-error *ngIf="formErrors.email">{{formErrors.email}}</mat-error>
        </mat-form-field>

        <div class="mt-2">
          <button mat-raised-button color="primary" class="w-100" type="submit"><mat-icon>email</mat-icon> Send Reset Link</button>
        </div>

        <div class="mt-4 text-center">
          Go back to <a class="link link--dotted text-primary" (click)="forgotPassword = false">Sign In</a>.
        </div>
      </form>
    </div>

  </div>

  <div class="version small text-muted">Version: {{version()}}</div>
</div>


