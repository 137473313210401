import {Component, Input, OnInit} from '@angular/core';
import {SurveyResultsQuestion} from "../../../../../../model/common";

@Component({
  selector: 'app-block-tripetto-radiobuttons-result',
  templateUrl: './block-tripetto-radiobuttons-result.component.html',
  styleUrls: ['./block-tripetto-radiobuttons-result.component.scss']
})
export class BlockTripettoRadiobuttonsResultComponent implements OnInit {

  @Input() question: SurveyResultsQuestion;

  constructor() {
  }

  ngOnInit() {
  }

}
