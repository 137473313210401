<div class="mat-card mat-typography">
  <div class="mat-card-title"
    *ngIf="name"
    markdown
    [content]="name"
    [context]="context"
    [enumerator]="enumerator"
    [required]="block.required"
  ></div>
  <div class="mat-card-content" *ngIf="description" markdown [content]="description" [context]="context"></div>
  <mat-radio-group class="radiobuttons">
    <mat-radio-button
      *ngFor="let button of block.props.buttons"
      [value]="button.id"
      [checked]="block.isSelected(button)"
      (change)="block.select(button)"
    >
      {{ button.name || "..." }}
    </mat-radio-button>
  </mat-radio-group>
  <div class="mat-card-footer" *ngIf="explanation" markdown [content]="explanation" [context]="context"></div>
</div>
