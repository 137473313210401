<mat-card>
  <mat-card-header>
    <mat-card-title>
      Survey sections
    </mat-card-title>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content>
    <mat-nav-list *ngIf="surveyResults">
      <ng-container *ngFor="let section of surveyResults.sections; let i = index">
        <a mat-list-item
           *ngIf="!section.is_compensation_survey"
           (click)="sectionClick(section)"
        >{{i+1}}. {{section.name}}</a>
      </ng-container>
    </mat-nav-list>
  </mat-card-content>
</mat-card>
