import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ED} from "../../model/common";
import {EdService} from "../../core/ed.service";
import {MatDialog} from '@angular/material';
import {NotificationService} from "../../component/notification/index";

@Component({
  selector: 'app-ed-form',
  templateUrl: './edform.component.html',
  styleUrls: ['./edform.component.scss']
})
export class EDFormComponent implements OnInit {

  @Input() ed: ED;
  @Output() edSaved = new EventEmitter();

  edForm: FormGroup;

  formErrors = {
    name: '',
    path: ''
  };

  formData: any = {};

  errors: any = {};

  validationMessages = {
    name: {
      required: 'Please enter your email address',
    },
    path: {
      required: 'Please enter path',
      pattern: 'Password must contain numbers and letters',
      minlength: 'Please enter more than 6 characters',
      maxlength: 'Please enter less than 32 characters'
    },
    county: {
      required: 'Please enter county',
    }
  };

  constructor(private fb: FormBuilder,
              private edService: EdService,
              public dialog: MatDialog,
              private notification: NotificationService) {
  }


  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.edForm = this.fb.group({
      name: [this.ed ? this.ed.name : '', [Validators.required]],
      path: [this.ed ? this.ed.path : '', [Validators.required]],
      county: [this.ed ? this.ed.county : '', [Validators.required]],
      contact_phone: [this.ed ? this.ed.contact_phone : '', [Validators.pattern('[-0-9() ]*')]],
      contact_email: [this.ed ? this.ed.contact_email : '', [Validators.email]],
      business_name: [this.ed ? this.ed.business_name : '', []],
    });

    this.edForm.valueChanges.subscribe(data => this.onValueChanged(data));
    this.onValueChanged();
  }

  onValueChanged(data?: any) {
    if (!this.edForm) {
      return;
    }

    const form = this.edForm;
    for (const field in this.formErrors) {
      if (Object.prototype.hasOwnProperty.call(this.formErrors, field)) {
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (Object.prototype.hasOwnProperty.call(control.errors, key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  close() {

  }

  save() {
    this.formData = this.edForm.getRawValue();
    this.ed ? this.formData.id = this.ed.id : '';
    this.edService.save(this.formData).subscribe(
      (response) => {
        // console.log(response);
        this.errors = {};
        this.dialog.closeAll();
        this.ed ?
          this.notification.success('ED updated', 'Success') :
          this.notification.success('New ED created', 'Success')
      }, (err) => {
        this.errors = err.error.errors;
        // err.error.errors.path ? this.formErrors.path = err.error.errors.path[0]: '';
        // err.error.errors.name ? this.formErrors.name = err.error.errors.name[0]: '';
        // console.log(this.errors);
      });
    // console.log(this.edForm.getRawValue());
    // this.edSaved.emit(this.ed);
  }

}
