/** Dependencies */
import {
  affects,
  Collection,
  ConditionBlock,
  conditions,
  definition,
  editor,
  Forms,
  name,
  NodeBlock,
  pgettext,
  Slots,
  slots,
  tripetto
} from 'tripetto';
import * as ICON from "../../../../../assets/icon/assets_condition.svg";

export class DropdownOption extends Collection.Item<Checkboxes> {
  @name
  @definition
  name = "";

  @editor
  defineEditor(): void {
    this.editor.option({
      label: pgettext("checkboxes", "Label"),
      form: {
        title: pgettext("checkboxes", "Checkbox label"),
        controls: [
          new Forms.Text(
            "singleline",
            Forms.Text.bind(this, "name", "")
          )
            .autoFocus()
            .autoSelect()
        ]
      },
      locked: true
    });
  }
}

@tripetto({
  type: 'node',
  identifier: 'block-checkboxes',
  label: 'Checkboxes',
  icon:
  // tslint:disable-next-line:max-line-length
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PHBhdGggZD0iTTIgNmEuNTAyLjUwMiAwIDAgMS0uMzU0LS4xNDZsLTEuNS0xLjVhLjUuNSAwIDAgMSAuNzA3LS43MDdsMS4xNDYgMS4xNDZMNi4xNDUuNjQ3YS41LjUgMCAwIDEgLjcwNy43MDdsLTQuNSA0LjVBLjQ5OC40OTggMCAwIDEgMS45OTggNnptMTcuNS0xaC0xMmEuNS41IDAgMCAxIDAtMWgxMmEuNS41IDAgMCAxIDAgMXpNMiAxM2EuNTAyLjUwMiAwIDAgMS0uMzU0LS4xNDZsLTEuNS0xLjVhLjUuNSAwIDAgMSAuNzA3LS43MDdsMS4xNDYgMS4xNDYgNC4xNDYtNC4xNDZhLjUuNSAwIDAgMSAuNzA3LjcwN2wtNC41IDQuNWEuNDk4LjQ5OCAwIDAgMS0uMzU0LjE0NnptMTcuNS0xaC0xMmEuNS41IDAgMCAxIDAtMWgxMmEuNS41IDAgMCAxIDAgMXpNMiAyMGEuNTAyLjUwMiAwIDAgMS0uMzU0LS4xNDZsLTEuNS0xLjVhLjUuNSAwIDAgMSAuNzA3LS43MDdsMS4xNDYgMS4xNDYgNC4xNDYtNC4xNDZhLjUuNSAwIDAgMSAuNzA3LjcwN2wtNC41IDQuNWEuNDk4LjQ5OCAwIDAgMS0uMzU0LjE0NnptMTcuNS0xaC0xMmEuNS41IDAgMCAxIDAtMWgxMmEuNS41IDAgMCAxIDAgMXoiIGNsYXNzPSJ0cmlwZXR0by1maWxsIi8+PHN0eWxlIHR5cGU9InRleHQvY3NzIj4udHJpcGV0dG8tZmlsbHtmaWxsOnJnYigxNjgsMTk4LDIyMyk7fS50cmlwZXR0by1zdHJva2V7c3Ryb2tlOnJnYigxNjgsMTk4LDIyMyk7fTwvc3R5bGU+PC9zdmc+'
})
export class Checkboxes extends NodeBlock {
  dataSlot!: Slots.String;

  @definition
  @affects("#label")
  @affects("#collection", "checkboxes")
  alias?: string;

  @definition
  checkboxes = Collection.of(DropdownOption, this as Checkboxes);

  @slots
  defineSlot(): void {
    // Define your data slots here...

    this.dataSlot = this.slots.static({
      type: Slots.String,
      reference: 'data-slot',
      label: 'Data slot'
    });

  }

  @editor
  defineEditor(): void {
    // Define controls to edit your properties here...

    this.editor.name();
    this.editor.description();
    // this.editor.placeholder();
    this.editor.explanation();

    this.editor.collection({
      collection: this.checkboxes,
      title: 'Checkboxes',
      placeholder: "Unnamed checkbox",
      editable: true,
      sorting: "manual"
    });

    this.editor.groups.options();
    this.editor.required(this.dataSlot);
    // this.editor.visibility();
  }

  @conditions
  defineConditions(): void {
    this.conditions.template({
      condition: CheckboxesCondition,
      props: {
        slot: this.dataSlot
      }
    });

  }
}


@tripetto({
  type: "condition",
  identifier: 'block-checkboxes',
  context: Checkboxes,
  icon: ICON,
  label: () => pgettext("text", "Checked checkbox")
})
export class CheckboxesCondition extends ConditionBlock {

  @affects("#condition")
  @definition("checkboxes")
  checkbox: DropdownOption | undefined;

  @definition
  conditionName: string | undefined;


  // Return an empty label, since the node name is in the block name already.
  get label(): string {
    return this.name;
  }

  get name(): string {

    let name = null;

    if (this.block && this.checkbox) {

      const slot = this.node.slot("data-slot");

      if (slot) {
        name = `\`${this.node.name}\` has \`${this.checkbox.name}\` checked`;
      } else {
        name = `No option selected for @${slot.id}`;
      }
      this.conditionName = name;
    }

    return name;
  }

  get block(): Checkboxes | undefined {
    return (
      (this.node &&
        this.node.block instanceof Checkboxes &&
        this.node.block) ||
      undefined
    );
  }

  @editor
  defineEditor(): void {

    if (this.node && this.block) {

      const checkboxes: Forms.IDropdownOption<DropdownOption>[] = [];

      this.block.checkboxes.each((checkbox: DropdownOption) => {
        if (checkbox.name && checkbox.name.length) {
          checkboxes.push({
            label: checkbox.name,
            value: checkbox
          });
        }
      });

      if (checkboxes.length > 0) {
        this.editor.form({
          title: "Checkbox name",
          controls: [
            new Forms.Dropdown<DropdownOption>(
              checkboxes,
              Forms.Dropdown.bind(this, "checkbox", undefined)
            )
          ]
        });
      }
    }
  }
}
